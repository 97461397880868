import React, { useState, useEffect } from "react";
import http from "../../../resources/http";
import { ASSET_LIST, RISK_AT_MAPPING_LIST } from "../../../api/excelUpload";
import { THREAT_VECTORS_LIST } from "../../../api/threatCatalog";
import LoadingComponent from "../../../shared/LoadingDropDownSelection/LoadingComponent";
import Modal from "../../../shared/Modal";
import Form from "../../../shared/Form";
import swal from "sweetalert";

const ATAddEditHandler = ({
	isOpen,
	mode,
	onClose,
	selectedItemId,
	selectedItem,
	fetchData,
}) => {
	const [assetDropdownOptions, setAssetsDropdownOptions] = useState([]);
	const [threatsDropdownOptions, setThreatsDropdownOptions] = useState([]);
	const [fetchLoading, setFetchLoading] = useState(false);

	async function fetchFormOptionData() {
		try {
			const listOfAssets = await http.get(ASSET_LIST);
			const listOfThreatVectors = await http.get(THREAT_VECTORS_LIST);

			const assetsOptionsData = listOfAssets.data?.map((item) => item.name);
			setAssetsDropdownOptions(assetsOptionsData);
			const threatOptions = listOfThreatVectors.data?.map((item) => item.name);
			setThreatsDropdownOptions(threatOptions);
		} catch (error) {
			console.log("error occurred while fetching form dropdown options.");
		}
	}
	useEffect(() => {
		if (isOpen) {
			fetchFormOptionData();
		}
	}, [isOpen]);

	const initialState = {
		asset: [],
		threat: "",
	};
	const [formData, setFormData] = useState({ initialState });
	const [errors, setErrors] = useState({});
	const validations = {
		asset: (value) => (value?.length > 0 ? "" : "Select at least one asset"),
		threat: (value) => (value?.length > 0 ? "" : "Select a threat."),
	};

	const handleChange = (key, value) => {
		setFormData((prevState) => ({ ...prevState, [key]: value }));

		if (validations[key]) {
			const error = validations[key](value);
			setErrors((prevState) => ({ ...prevState, [key]: error }));
		}
	};

	const formFieldsConfig = {
		asset: {
			label: "Asset",
			type: "multi-select",
			options: assetDropdownOptions,
			value: formData.asset || [],
			onChange: (selectedValues) => handleChange("asset", selectedValues),
			error: errors.asset,
		},
		threat: {
			label: "Threat",
			type: "select",
			placeholder: "Select...",
			options: threatsDropdownOptions,
			value: formData.threat || "",
			onChange: (e) => handleChange("threat", e.target.value),
			error: errors.threat,
		},
	};

	const resetForm = () => {
		setFormData(initialState);
		setErrors({});
	};

	useEffect(() => {
		if (mode === "edit" && selectedItem) {
			setFormData(selectedItem);
		} else {
			resetForm();
		}
	}, [mode, selectedItem]);

	const handleSubmit = () => {
		let newErrors = {};
		Object.entries(formFieldsConfig).forEach(([key, config]) => {
			if (validations[key]) newErrors[key] = validations[key](formData[key]);
		});
		setErrors(newErrors);
		if (Object.values(newErrors).every((error) => !error)) {
			console.log("Submitting form data:", formData);
			if (mode === "edit") {
				http
					.patch(`${RISK_AT_MAPPING_LIST}/${selectedItemId}`, {
						asset: formData.asset,
						threat: formData.threat,
					})
					.then((res) => {
						fetchData();
						onClose();
						swal("Success!", "It has been updated successfully", "success");
						resetForm();
					})
					.catch((error) => {
						setFetchLoading(false);
						if (error && error.response && error.response.data) {
							for (const [key, errorMessage] of Object.entries(
								error.response.data
							)) {
								if (formFieldsConfig[key]) {
									newErrors[key] = errorMessage[0];
								}
								setErrors(newErrors);
							}
						} else {
							swal("Error", "Something went wrong, please try again", "error");
						}
					});
			} else {
				http
					.post(`${RISK_AT_MAPPING_LIST}`, {
						asset: formData.asset,
						threat: formData.threat,
					})
					.then((res) => {
						fetchData();
						onClose();
						swal("Success!", "It has been updated successfully", "success");
						resetForm();
					})
					.catch((error) => {
						setFetchLoading(false);
						if (error && error.response && error.response.data) {
							for (const [key, errorMessage] of Object.entries(
								error.response.data
							)) {
								if (formFieldsConfig[key]) {
									newErrors[key] = errorMessage[0];
								}
								setErrors(newErrors);
							}
						} else {
							swal("Error", "Something went wrong, please try again", "error");
						}
					});
			}
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="lg">
			<Modal.Header>
				<Modal.Title>
					{mode === "edit"
						? "Update Asset and Threat"
						: "Add new Asset and Threat"}
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Form>
					{Object.entries(formFieldsConfig || {}).map(([key, fieldConfig]) => (
						<Form.Group key={key}>
							<Form.Label>{fieldConfig.label}</Form.Label>
							<Form.Control {...fieldConfig} />
							{errors[key] && (
								<Form.Control.Feedback type="invalid">
									{errors[key]}
								</Form.Control.Feedback>
							)}
						</Form.Group>
					))}
				</Form>
			</Modal.Body>

			<Modal.Footer>
				{mode === "edit" ? (
					<>
						<button
							className="btn-cancel"
							onClick={() => {
								// resetForm();
								onClose();
							}}
						>
							Cancel
						</button>
						<button className="btn-add-new" onClick={handleSubmit}>
							Save Changes
						</button>
					</>
				) : (
					<>
						<button
							className="btn-cancel"
							onClick={() => {
								// resetForm();
								onClose();
							}}
						>
							Cancel
						</button>
						<button className="btn-add-new" onClick={handleSubmit}>
							Add
						</button>
					</>
				)}
			</Modal.Footer>
		</Modal>
	);
};

export default ATAddEditHandler;
