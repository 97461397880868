import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { useApi } from '../hooks/useApis';
import MoreButton from '../layout/components/MoreButton';
import { baseURL } from '../resources/apiClient';
import http from '../resources/http';
import {
  formatListWithComma,
  getControlCatalogIds,
  removeDuplicates,
} from '../utils/helpers';

import { CLIENT_CONTROLS_CATALOG_ENDPOINT } from '../api/threatCataloModels';
import LoadingComponent from '../shared/LoadingDropDownSelection/LoadingComponent';
import SearchInput from '../shared/SearchInput/SearchInput';
import TableLoadingAnimation from '../shared/TableLoading/TableLoadingAnimation';
import ControlCatalogAddRecord from './ControlCatalogAddRecord';
import ControlCatalogEditRecord from './ControlCatalogEditRecord';
import ScatterChart from './../Charts/ControlAnalysis/ScatteredChart';
import { useHandleTheme } from './../hooks/useHandleTheme';
import VulnerabilityHeatMap from './../Charts/ControlAnalysis/VulnerabilityHeatMap';
const ControlAnalysisClientAdmin = () => {
  const [sensFilter, setRadioFilter] = useState('High');
  const [searchValue, setSearchValue] = useState('');
  const [showHide, setShowHide] = useState(false);
  const [addOrEditModal, setAddOrEditModal] = useState('');
  const [openTab, setOpenTab] = useState(1);

  const [showEdit, setShowEdit] = useState(false);
  const toggleEdit = () => setShowEdit(!showEdit);

  //CONFIGURATIONS DATA
  const [controlDomainConfigData, setControlDomainConfigData] = useState([]);
  const [controlTypeConfigData, setControlTypeConfigData] = useState([]);
  const [controlApplicabilityConfigData, setControlApplicabilityConfigData] =
    useState([]);
  const [threatVectors, setThreatVectors] = useState([]);
  const [industryUseCaseConfigData, setIndustryUseCaseConfigData] = useState(
    []
  );
  console.log(controlTypeConfigData, 'controlApplicabilityConfigData');
  

  //adding model handler
  const [showHideAdd, setShowHideAdd] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const handleModalShowHideAdd = () => setShowHideAdd(!showHideAdd);
  const { data, isLoading, fetchData, isError } = useApi(
    `${baseURL}/${CLIENT_CONTROLS_CATALOG_ENDPOINT}`
  );
  console.log(data, 'data');
  const { darkMode } = useHandleTheme();

  // const { data, isLoading, isError } = useApi(
  // 	`${baseURL}/threat-catalog/control-analysis?businessProcessId=${processId}`
  //   ); // Replace with your API endpoint
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState('');
  const [allBusinessProcess, setAllBusinessProcess] = useState([]);
  const [form, setForm] = useState({}); //form
  const [errors, setErrors] = useState({}); //errors
  const [dataEdit, setDataEdit] = useState([]);

  console.log(getControlCatalogIds(data), 'contol send');
  const controls = getControlCatalogIds(data);
  const [feedback, setFeedBack] = useState();

  const [domainNameList, setDomainNameList] = useState([]);
  const handleInputChange = (newValue) => {
    setSearchValue(newValue);
    setPageNumber(0); // Reset to the first page when search value changes
  };
  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field])
      //if there is an error add the error to errors object
      setErrors({
        ...errors,
        [field]: null,
      });
  };
  const validateForm = () => {
    const {
      ControlName = '',
      ControlApplicability = '',
      controlDescription = '',
      controlType = '',
      primaryThreat = '',
      useCase = '',
      businessProcess = '',
    } = form; //declare the variables and assign the values from the form object
    const newErrors = {};
    if (!ControlName || ControlName === '')
      newErrors.ControlName = 'Control Name is required.';
    if (!ControlApplicability || ControlApplicability === '')
      newErrors.ControlApplicability = 'Control Applicability is required.';
    if (!controlDescription || controlDescription === '')
      newErrors.controlDescription = 'Control Description is required.';
    if (!controlType || controlType === '')
      newErrors.controlType = 'Control Type is required.';
    if (!primaryThreat || primaryThreat === '')
      newErrors.primaryThreat = 'Primary Threat is required.';
    if (!useCase || useCase === '')
      newErrors.useCase = 'Control Use Case is required.';
    if (!businessProcess || businessProcess === '')
      newErrors.businessProcess = 'Business Process is required.';
    return newErrors;
  };
  const fetchDatas = async () => {
    try {
      const allBusinessProcessRes = await http.get(
        `${baseURL}/business_process/get-my-business-process`
      );
      setAllBusinessProcess(allBusinessProcessRes.data);

      const controlDomainResponse = await http.get(
        `${baseURL}/configurations/control-domain`
      );
      setControlDomainConfigData(controlDomainResponse.data);

      const controlTypeResponse = await http.get(
        `${baseURL}/excel-upload/control-type`
      );
      setControlTypeConfigData(controlTypeResponse.data);

      const controlApplicabilityResponse = await http.get(
        `${baseURL}/excel-upload/control-applicability`
      );
      setControlApplicabilityConfigData(controlApplicabilityResponse.data);

      const controlUseCaseResponse = await http.get(
        `${baseURL}/configurations/industry-usecase`
      );
      setIndustryUseCaseConfigData(controlUseCaseResponse.data);

      const threatVectorResponse = await http.get(
        `${baseURL}/threat-catalog/threat-vectors`
      );
      setThreatVectors(threatVectorResponse.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDatas();
  }, []);

  const uniqueThreats = removeDuplicates(threatVectors);
  console.log(uniqueThreats, 'uniqueThreats');
  // Handle dropdown selection
  const handleDropdownSelect = (eventKey) => {
    setSelectedItem(eventKey);
  };

  // Update the filtering and display logic
  const filteredData = data?.control_catalogs?.filter(
    (row) =>
      row?.control_type?.match(new RegExp(searchValue, 'i')) ||
      row?.control_applicability?.match(new RegExp(searchValue, 'i')) ||
      row?.usecase?.match(new RegExp(searchValue, 'i')) ||
      row?.control_name?.match(new RegExp(searchValue, 'i')) ||
      row?.primary_threat?.match(new RegExp(searchValue, 'i'))
  );
  const [description, setDescription] = useState('');
  const handleModalShowHides = () => {
    setShowHide(!showHide);
  };
  let localStorageData = JSON.parse(localStorage.getItem('cyber-minds'));
  const processId = localStorageData?.pid;
  const clientId = localStorageData?.user?.client;

  console.log(data, 'data');
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(filteredData?.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const [checkboxState, setCheckboxState] = useState({}); // Use an object instead of an array
  const [checkboxStateImplemented, setImplementedCheckboxState] = useState({});

  useEffect(() => {
    if (data) {
      const applicabilityState = {};
      const implementationState = {};

      // Update the state based on control id
      data?.control_catalogs?.forEach((control) => {
        applicabilityState[control.id] = control.is_applicable;
        implementationState[control.id] = control.is_implemented;
      });

      setCheckboxState(applicabilityState);
      setImplementedCheckboxState(implementationState);
    }
  }, [data]);
  console.log(checkboxState, 'checkboxes');

  const refreshCalculate = async () => {
    // Display a success message
    setUpdateLoading(true);

    try {
      // Fetch the data from the API and wait for the response
      fetchData();

      // Display a success message or update the UI as needed
    } catch (error) {
      console.error('Error fetching data or setting checkbox state: ', error);
      // Handle the error, e.g., show an error message to the user
    } finally {
      // After everything is done, clear the loading indicator
      setUpdateLoading(false);
    }
  };

  // Function to send a PATCH request
  const updateControlProperty = async (controlId, property, updatedValue) => {
    console.log(controlId, property, updatedValue, 'check');
    try {
      await http.patch(
        `${baseURL}/threat-catalog/client-controls-catalog/${controlId}`,
        { [property]: updatedValue }
      );
    } catch (error) {
      throw error; // Handle the error as needed
    }
  };
  // Event handler for checkbox changes
  const handleCheckboxChange = async (controlId, property) => {
    if (filteredData) {
      // Find the index of the control with the given id
      const index = filteredData?.findIndex(
        (control) => control?.id === controlId
      );

      if (index !== -1) {
        // Clone the state object to avoid direct mutation
        const updatedState = { ...checkboxState };

        // Update the checkbox state for the specific control
        updatedState[controlId] = !updatedState[controlId];

        // Update the state with the new checkbox state
        setCheckboxState(updatedState);

        const updatedValue = updatedState[controlId];

        try {
          await updateControlProperty(controlId, `${property}`, updatedValue);
          // If successful, you can display a success message or update the UI as needed
        } catch (error) {
          console.error('Error updating control property: ', error);
          // Handle the error, e.g., show an error message to the user
        }
      }
    }
  };

  // Event handler for checkbox changes
  const handleCheckboxChangeImplemnted = async (
    controlId,
    property,
    isImplemented
  ) => {
    // Check if checkboxState is defined
    if (filteredData) {
      // Find the index of the control with the given id
      const index = filteredData?.findIndex(
        (control) => control?.id === controlId
      );

      if (index !== -1) {
        // Clone the state object to avoid direct mutation
        const updatedState = { ...checkboxStateImplemented };

        // Update the checkbox state for the specific control
        updatedState[controlId] = isImplemented
          ? !updatedState[controlId]
          : isImplemented;

        // Update the state with the new checkbox state
        setImplementedCheckboxState(updatedState);

        const updatedValue = updatedState[controlId];

        try {
          await updateControlProperty(controlId, `${property}`, updatedValue);
          // If successful, you can display a success message or update the UI as needed
        } catch (error) {
          console.error('Error updating control property: ', error);
          // Handle the error, e.g., show an error message to the user
        }
      }
    }
  };

  const DisplayData = filteredData

    ?.slice(pagesVisited, pagesVisited + usersPerPage)

    ?.map((controls, index) => {
      return (
        <tr>
          <td>{controls?.control_name ? controls?.control_name : 'N/A'}</td>

          <td>{controls?.primary_threat ? controls?.primary_threat : 'N/A'}</td>
          <td>
            {controls?.secondary_threat?.length > 0
              ? formatListWithComma(controls?.secondary_threat)
              : 'N/A'}
          </td>

          <td>
            <input
              id={controls.id}
              type="checkbox"
              checked={checkboxState[controls.id]}
              onChange={() => {
                const newApplicableState = !checkboxState[controls.id];
                handleCheckboxChange(controls.id, 'is_applicable');

                if (newApplicableState === false) {
                  // Set "is_implemented" to false if "is_applicable" is unchecked
                  handleCheckboxChangeImplemnted(
                    controls.id,
                    'is_implemented',
                    false
                  );
                }
              }}
            />
          </td>
          <td>
            <input
              id={controls.id}
              type="checkbox"
              checked={checkboxStateImplemented[controls.id]}
              disabled={!checkboxState[controls.id]}
              onChange={() => {
                handleCheckboxChangeImplemnted(
                  controls.id,
                  'is_implemented',
                  true
                );
              }}
            />
          </td>
          <td>
            {controls?.control_applicability
              ? controls?.control_applicability
              : 'N/A'}
          </td>
          <td className="p-0 ">
            {controls?.control_description ? (
              <div className="w-48">
                <MoreButton
                  description={controls?.control_description}
                  stringLength={25}
                  setText={setDescription}
                  modalFunc={handleModalShowHides}
                />
              </div>
            ) : (
              'N/A'
            )}
          </td>
          <td>{controls?.control_type ? controls?.control_type : 'N/A'}</td>

          <td>{controls?.usecase ? controls?.usecase : 'N/A'}</td>
          <td>
            <td className="text-white font-semibold ">
              <p
                className="p-2 text-center rounded-xl w-36"
                style={{
                  backgroundColor:
                    controls.associated_threat_vector?.threat_severity?.severity
                      ?.High === 'low' && sensFilter === 'High'
                      ? 'green'
                      : controls.associated_threat_vector?.threat_severity
                          ?.severity?.High === 'very_low' &&
                        sensFilter === 'High'
                      ? '#90ee90'
                      : controls.associated_threat_vector?.threat_severity
                          ?.severity?.High === 'moderate' &&
                        sensFilter === 'High'
                      ? 'yellow'
                      : controls.associated_threat_vector?.threat_severity
                          ?.severity?.High === 'high' && sensFilter === 'High'
                      ? 'orange'
                      : controls.associated_threat_vector?.threat_severity
                          ?.severity?.High === 'critical' &&
                        sensFilter === 'High'
                      ? 'maroon'
                      : controls.associated_threat_vector?.threat_severity
                          ?.severity?.Medium === 'low' &&
                        sensFilter === 'Medium'
                      ? 'green'
                      : controls.associated_threat_vector?.threat_severity
                          ?.severity?.Medium === 'very_Low' &&
                        sensFilter === 'Medium'
                      ? '#90ee90'
                      : controls.associated_threat_vector?.threat_severity
                          ?.severity?.Medium === 'moderate' &&
                        sensFilter === 'Medium'
                      ? 'yellow'
                      : controls.associated_threat_vector?.threat_severity
                          ?.severity?.Medium === 'high' &&
                        sensFilter === 'Medium'
                      ? 'orange'
                      : controls.associated_threat_vector?.threat_severity
                          ?.severity?.Medium === 'critical' &&
                        sensFilter === 'Medium'
                      ? 'maroon'
                      : controls.associated_threat_vector?.threat_severity
                          ?.severity?.Low === 'low' && sensFilter === 'Low'
                      ? 'green'
                      : controls.associated_threat_vector?.threat_severity
                          ?.severity?.Low === 'very_low' && sensFilter === 'Low'
                      ? '#90ee90'
                      : controls.associated_threat_vector?.threat_severity
                          ?.severity?.Low === 'moderate' && sensFilter === 'Low'
                      ? 'yellow'
                      : controls.associated_threat_vector?.threat_severity
                          ?.severity?.Low === 'high' && sensFilter === 'Low'
                      ? 'orange'
                      : controls.associated_threat_vector?.threat_severity
                          ?.severity?.Low === 'critical' && sensFilter === 'Low'
                      ? 'maroon'
                      : sensFilter === 'extra high'
                      ? 'maroon'
                      : 'gray',
                  color: 'black',
                }}
              >
                {' '}
              </p>
            </td>
          </td>
        </tr>
      );
    });

  return (
    <div>
      <div className="p-10 ">
        {updateLoading ? <LoadingComponent /> : null}
        <div className={openTab === 1 ? 'block' : 'hidden'}>
          <div className="table-title">
            <span className="">Control Analysis</span>
            <div className="flex justify-center items-center space-x-6">
              <Button
                className="btn-add-new"
                onClick={() => {
                  refreshCalculate();
                }}
              >
                Recalculate
              </Button>
              <SearchInput
                searchValue={searchValue}
                setSearchValue={handleInputChange}
              />
            </div>
          </div>
          <div>
            <div className="overflow-auto">
              <div className="overflow-x-auto">
                <table className="tables ">
                  <thead className="sticky-header">
                    <tr>
                      <th scope="col">Control Name</th>
                      <th scope="col">Primary Threat</th>
                      <th scope="col">Secondary Threat</th>
                      <th className="text-center" scope="col">
                        Applicability
                      </th>
                      <th className="text-center" scope="col">
                        Implemented?
                      </th>
                      <th scope="col">Control Applicability</th>
                      <th scope="col">Control Description</th>
                      <th scope="col">Control Type</th>
                      <th scope="col">Control Use Case</th>
                      <th scope="col">Threat Severity</th>
                    </tr>
                  </thead>
                  {!isLoading && <tbody>{DisplayData}</tbody>}
                </table>
                {isLoading ? <TableLoadingAnimation /> : null}
              </div>
            </div>
            <div>
              {filteredData?.length > 5 ? (
                <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  activeClassName={'paginationActive'}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={'paginationBttns'}
                  previousLinkClassName={'previousBttn'}
                  nextLinkClassName={'nextBttn'}
                  disabledClassName={'paginationDisabled'}
                />
              ) : null}
            </div>
          </div>
        </div>
        {/* adding model */}
        <Modal show={showHideAdd} size="lg">
          <Modal.Header onClick={() => handleModalShowHideAdd()}>
            <Modal.Title>Add New Control Analysis Record</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ControlCatalogAddRecord
              url="/threat-catalog/client-controls-catalog"
              handleModalShowHideAdd={handleModalShowHideAdd}
              fetchCatalogs={fetchData}
            />
          </Modal.Body>
        </Modal>

        <Modal show={showEdit} size="lg">
          <Modal.Header onClick={() => toggleEdit()}>
            <Modal.Title>Edit New Control Analysis Record</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ControlCatalogEditRecord
              url="/threat-catalog/client-controls-catalog"
              handleModalShowHideAdd={toggleEdit}
              fetchCatalogs={fetchData}
              dataEdit={dataEdit}
            />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default ControlAnalysisClientAdmin;
