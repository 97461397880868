import React, { useEffect, useState } from "react";
import CustomTable from "../../shared/CustomTable.js";
import http from "../../resources/http.js";
import {
	GET_MY_BUSINESS_PROCESS,
	GET_BUSINESS_IMPACT_BY_CLIENT,
} from "../../api/businessProcess.js";
import {
	BUSINESS_IMPACT_LIST,
	BUSINESS_IMPACT_BY_PK,
	UPLOAD_BIA_EXCEL_BY_SUPERUSER,
} from "../../api/excelUpload.js";
import { downloadExcelData } from "../../utils/ExportExcelUtility.js";
import SearchInput from "../../shared/SearchInput/SearchInput.js";
import SearchHandler from "../../shared/SearchHandler.js";
import LoadingComponent from "../../shared/LoadingDropDownSelection/LoadingComponent.js";
import AddIcon from "@mui/icons-material/Add";
// import FilterListIcon from "@mui/icons-material/FilterList";
import swal from "sweetalert";
import ModalHandler from "./ModalHandler.js";
import ModalFileUpload from "../../shared/ModalFileUpload.js";
import { toast } from "react-hot-toast";

const ClientBusinessImpactAnalysis = () => {
	const [fetchLoading, setFetchLoading] = useState(false);
	const [error, setError] = useState(null);
	const [isNullOptions, setIsNullOptions] = useState("");
	const [isNullData, setIsNullData] = useState(false);
	const [selectedClientId, setSelectedClientId] = useState(null);
	const [selectedBusinessProcessId, setSelectedBusinessProcessId] =
		useState(null);

	const [clientBusinessProcessList, setClientBusinessProcessList] = useState(
		[]
	);
	const [BIAData, setBIAData] = useState([]);

	let localStorageData = JSON.parse(localStorage.getItem("cyber-minds"));
	let clientID = localStorageData?.user?.client;

	const UploadURL = "/excel-upload/upload-bia-excel/";
	const url_create = BUSINESS_IMPACT_LIST;
	const url_get = BUSINESS_IMPACT_LIST;
	const url_by_pk = BUSINESS_IMPACT_BY_PK;

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [mode, setMode] = useState("add"); // 'add' or 'edit'
	const [selectedItem, setSelectedItem] = useState(null);
	const [id, setId] = useState();

	// for file upload
	const [isModalUploadOpen, setIsModalUploadOpen] = useState(false);

	// search
	const [searchValue, setSearchValue] = useState("");
	const fieldsToFilterBy = [
		"regulations",
		"cpe",
		"version",
		"product",
		"vendors",
		"asset_name",
		"business_assets",
		"vendors",
		"asset_name",
		"hierarchy",
		"asset_categorization",
		"asset_type",
		"asset_risk",
	];
	const debounceDelay = 400;

	const filteredData = SearchHandler(
		BIAData,
		searchValue,
		fieldsToFilterBy,
		debounceDelay
	);

	const handleAddClick = () => {
		if (selectedBusinessProcessId && selectedClientId) {
			setSelectedItem(null);
			setMode("add");
			setIsModalOpen(true);
		} else {
			toast("Please select business process to add a data");
		}
	};

	//data
	useEffect(() => {
		// business process for option
		const fetchBusinessProcessOption = async () => {
			setFetchLoading(true);
			try {
				const response = await http.get(`${GET_MY_BUSINESS_PROCESS}`);
				if (!response.data || response.data.length === 0) {
					setIsNullOptions("No business process at the moment");
					setClientBusinessProcessList([]);
				} else {
					const simplifiedBPOptions = response.data.map(({ id, name }) => ({
						id,
						name,
					}));
					setClientBusinessProcessList(simplifiedBPOptions);
					// isNullOptionsMsg("");
				}
			} catch (error) {
				console.log(error);
			} finally {
				setFetchLoading(false);
			}
		};

		fetchBusinessProcessOption();
	}, []);

	useEffect(() => {
		setSelectedClientId(clientID);
		fetchData();
	}, [selectedBusinessProcessId]);

	async function fetchData() {
		try {
			if (!selectedBusinessProcessId) {
				return;
			}
			const selectedBusinessProcessImpact = await http.get(
				`${GET_BUSINESS_IMPACT_BY_CLIENT}${selectedClientId}/${selectedBusinessProcessId}`
			);
			if (selectedBusinessProcessImpact.data.length === 0) {
				setFetchLoading(false);
				setIsNullData(true);
				return;
			}
			setIsNullData(false);
			setFetchLoading(false);
			setBIAData(selectedBusinessProcessImpact.data);
		} catch (error) {
			setError("error occurred while fetching");
			setFetchLoading(false);
		}
	}

	// Handle dropdown selection
	const handleSelectionChange = (event) => {
		setSelectedBusinessProcessId(event.target.value);
	};

	//for search
	const handleInputChange = (newValue) => {
		setSearchValue(newValue);
	};

	// Handle the edit click from the table
	const handleEdit = (id, item) => {
		setSelectedItem(item);
		setId(id);
		setMode("edit");
		setIsModalOpen(true);
	};

	const handleDelete = async (itemId) => {
		setFetchLoading(true);
		try {
			await http.delete(`${url_by_pk}${itemId}`);
			// fetchData();
			setFetchLoading(false);
			swal("Success", "It has been deleted successfully", "success");
		} catch (error) {
			// console.error("Failed to delete item:", error);
			setFetchLoading(false);
		}
	};

	return (
		<div className="main-container">
			<div className="table-title ">
				<div className="flex justify-between items-center space-x-4">
					<span>Business Impact Analysis </span>
					<div className="relative flex justify-center items-center p-1 space-x-2 rounded-md">
						<div className="flex justify-end items-end w-56">
							<select
								className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
								id="threats"
								onChange={handleSelectionChange}
								value={selectedBusinessProcessId}
							>
								<option>Select Business Process</option>
								{clientBusinessProcessList?.map((item) => (
									<option key={item.id} value={item.id}>
										{item.name}
									</option>
								))}
							</select>
						</div>
					</div>{" "}
				</div>

				<div className="flex justify-between items-center space-x-4">
					<SearchInput
						searchValue={searchValue}
						setSearchValue={handleInputChange}
					/>

					<button className="btn_file_download">
						<svg
							onClick={() =>
								downloadExcelData(BIAData, "Business Impact Analysis")
							}
							xmlns="http://www.w3.org/2000/svg"
							height={28}
							width={28}
							fillRule="evenodd"
							fill="white"
							clipRule="evenodd"
							imageRendering="optimizeQuality"
							shapeRendering="geometricPrecision"
							textRendering="geometricPrecision"
							viewBox="0 0 17639 17639"
						>
							<path
								fill="#1e7145"
								d="M9596 3634v10265l-6103-1058V4692l6103-1058zm290 1185h4053c224 0 267 44 267 267v7360c0 223-43 267-267 267H9886v-694h1547v-1066H9886v-320h1547V9566H9886v-320h1547V8179H9886v-320h1547V6793H9886v-320h1547V5406H9886v-587zM2083 15593h13474l2-13476H2081l2 13476z"
							/>
							<path
								fill="#1e7145"
								d="m7129 6861-556 1272-432-1195-695 31 690 1741-777 1703 683 51 548-1225 510 1296 786 43-852-1875 815-1894zM11753 12019h1760v-1066h-1760zM11753 10633h1760V9566h-1760zM11753 9246h1760V8179h-1760zM11753 6473h1760V5406h-1760zM11753 7859h1760V6793h-1760z"
							/>
						</svg>
					</button>
					<button onClick={() => handleAddClick()} className="btn-add-new">
						<AddIcon />
					</button>
				</div>
			</div>
			{fetchLoading ? (
				<LoadingComponent />
			) : !selectedBusinessProcessId ||
			  selectedBusinessProcessId === "Select Business Process" ? (
				<div className="alert-no-data" role="alert">
					Select business process
				</div>
			) : BIAData ? (
				<CustomTable
					filteredData={BIAData}
					onEditClick={handleEdit}
					onDeleteClick={handleDelete}
					passedExcludeColumns={[
						"business_process",
						"client",
						"geolocation",
						"domain",
					]}
				/>
			) : isNullData ? (
				<div className="alert-no-data" role="alert">
					There is no data for selected business process
				</div>
			) : null}

			{/* add and edit modal */}
			<ModalHandler
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				mode={mode}
				size="xl"
				header=" Business Impact analysis"
				selectedItemId={id}
				selectedItem={selectedItem}
				url_create={url_create}
				url_get={url_get}
				url_by_pk={url_by_pk}
				fetchData={fetchData}
				selectedClientId={selectedClientId}
				selectedBusinessProcessId={selectedBusinessProcessId}
			/>
			{/* for uploading new file */}
			<ModalFileUpload
				UploadURL={UploadURL}
				fetchData={fetchData}
				isOpen={isModalUploadOpen}
				onClose={() => setIsModalUploadOpen(false)}
				selectedClientId={selectedClientId}
				selectedBusinessProcessId={selectedBusinessProcessId}
			/>
		</div>
	);
};

export default ClientBusinessImpactAnalysis;
