import React from "react";
import swal from "sweetalert";

const showInactivityModal = (continueCallback) => {
  // Check if the user is logged in before showing the modal
  if (localStorage.getItem("cyber-minds")) {
    const logoutTimeout = setTimeout(() => {
      // Trigger logout function
      localStorage.removeItem("cyber-minds");
      localStorage.removeItem("theme");
      localStorage.removeItem("lastVisitDateTime");
      window.location.reload();
    }, 100000); // Log the user out

    swal({
      title: "Inactivity Detected",
      text: "You have been inactive for too long. Do you want to continue?",
      icon: "warning",
      buttons: {
        logout: {
          text: "Logout",
          value: true,
          className: "logout-btn", // Add custom class for styling
        },
        continue: {
          text: "Continue",
          value: false,
          className: "continue-btn", // Add custom class for styling
        },
      },
    }).then((result) => {
      clearTimeout(logoutTimeout); // Clear the logout timeout
      if (result !== null) {
        if (result === true) {
          // Trigger logout function
          localStorage.removeItem("cyber-minds");
          localStorage.removeItem("theme");
          localStorage.removeItem("lastVisitDateTime");
          window.location.reload();
        } else {
          // Trigger continue function
          if (continueCallback) {
            continueCallback();
            // Get the current date and time
            const currentDateTime = new Date();

            // Convert the date and time to a string (you can format it as needed)
            const formattedDateTime = currentDateTime.toISOString();

            // Store the formatted date and time in localStorage
            localStorage.setItem("lastVisitDateTime", formattedDateTime);
          }
        }
      }
    });

    // Customize sweetalert button styles using CSS
    const style = document.createElement("style");
    style.innerHTML = `
      .logout-btn {
        background-color: gray !important;
        color: white !important;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.625rem /*p-1=0.25rem=4px,  p-2.5 0.625rem 10px */;
        border-width: 1px; 
        border-radius: 0.625rem/* 6px */;  
        border-color: rgb(203 213 225);
      }
      .continue-btn {
        background-color: #ce9f2c !important;
        color: white !important;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.625rem /*p-1=0.25rem=4px,  p-2.5 0.625rem 10px */;
        border-width: 1px; 
        border-radius: 0.625rem/* 6px */;
        border-color: #cea02cd2 ;
        
      }
    `;
    document.head.appendChild(style);
  }
};

export default showInactivityModal;
