import { useEffect, useRef, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import swal from 'sweetalert';
import apiClient, { baseURL } from '../../resources/apiClient';
import http from '../../resources/http';
import MultipleSelectCheckmarks from '../../shared/MultiSelect/MultiSelect';
import { roles, rolesStaff } from './../../data/data';

const Signup = ({ handleModalShowHide, fetchData }) => {
  const [selectedUserType, setSelectedUserType] = useState([]);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [company_name, setCompany] = useState('');
  const [user_type, setRole] = useState();
  const [data, setData] = useState([]);
  const [datas, setDatas] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectErrors, setSelectErrors] = useState('Please select user type');
  const [enabled, setEnabled] = useState(false);
  const form = useRef();
  const resetInputs = () => {
    setUsername('');
    setEmail('');
    setPassword('');
    setRole(['']);
    setCompany('');
  };
  let localStorageData = JSON.parse(localStorage.getItem('cyber-minds'));
  const isUserCMSAdmin = localStorageData?.user?.is_cyberminds_admin;
  const usersRole = isUserCMSAdmin ? roles : rolesStaff;
  const [forrm, setForrm] = useState({}); //form
  const [errors, setErrors] = useState({}); //errors
  const [usernameList, setUsernameList] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [userList, setUserList] = useState([]);
  const handleUserTypeOptionsChange = (selected) => {
    setUserList(selected);
  };
  const isCyberMindAdminSelected = userList.includes('CybermindAdmin');
  const setField = (field, value) => {
    setForrm({
      ...forrm,
      [field]: value,
    });

    if (!!errors[field])
      //if there is an error add the error to errors object
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validateForm = () => {
    const {
      userTypeSelect = '',
      companySelect = '',
      userEmail = '',
      userUsername = '',
    } = forrm; //declare the variables and assign the values from the form object
    const newErrors = {};
    if (emailList.includes(forrm?.userEmail?.trim()))
      newErrors.userEmail = `This email ${forrm.userEmail} already exists.`;
    if (usernameList.includes(forrm?.userUsername?.trim()))
      newErrors.userUsername = `This username ${forrm.userUsername} already exists.`;
    // if (!userTypeSelect || userTypeSelect === "")
    //   newErrors.userTypeSelect = "User type is require.";

    if (!validateEmail(userEmail))
      //very simple email format validation
      newErrors.userEmail = 'Please enter a valid email address.';
    if (!userEmail || userEmail === '')
      newErrors.userEmail = 'Email is required.';
    if (!userUsername || userUsername === '')
      newErrors.userUsername = 'Username is required.';
    return newErrors;
  };

  useEffect(() => {
    http
      .get(`${baseURL}/account/get-clients`)
      .then((response) => {
        setDatas(response.data);
      })
      .then(
        (response) => {},
        (err) => {
          console.log(err);
        }
      );
  }, []);
  useEffect(() => {
    http
      .get(`${baseURL}/account/get-client-types`)
      .then((response) => {
        setData(response.data);
      })
      .then(
        (response) => {},
        (err) => {
          swal(err);
        }
      );
  }, []);
  useEffect(() => {
    http
      .get(`${baseURL}/account/get-clients`)
      .then((response) => {
        setClients(response.data);
      })
      .then(
        (response) => {},
        (err) => {
          console.log(err);
        }
      );
  }, []);
  useEffect(() => {
    http
      .get(`${baseURL}/account/get-client-users`)
      .then((response) => {
        const names = response.data.map((names) => names.username);
        const emails = response.data.map((names) => names.email);
        setUsernameList(names);
        setEmailList(emails);
      })
      .then(
        (response) => {},
        (err) => {
          console.log(err);
        }
      );
  }, []);
  console.log(userList, 'userList');
  const submit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else if (userList?.length === 0) {
      setSelectErrors('Please select user type');
    } else {
      await apiClient.auth
        .signup({
          email,
          username,
          user_type: userList,
          client: company_name,
          //Password id is basically set in the backend that's why we send as test since it's required
          password: 'test',
        })
        .then(
          (result) => {
            // sendEmail(e);
            fetchData();
            handleModalShowHide();
            swal(
              'Success',
              'An email with your login information has been sent to your registered email address. Please check your inbox and follow the instructions to access your account.!',
              'success'
            );
            resetInputs();
          },
          (error) => {
            const fields = Object.entries(error.response.data).filter(
              ([key, value]) =>
                (key === 'username' || key === 'email') && value !== undefined
            );

            if (fields.length === 2) {
              swal(
                'Error',
                `Email: ${fields[0][1]}, Username: ${fields[1][1]}`,
                'error'
              );
            } else if (fields.length === 1) {
              swal('Error', `${fields[0][0]}: ${fields[0][1]}`, 'error');
            }
          }
        );
    }
    setEnabled(true);
  };

  return (
    <div>
      <Modal.Body className="modal-body-medium">
        <Form.Group class="">
          <Form.Label
            for="countries"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
          >
            Select User Type
          </Form.Label>
          <MultipleSelectCheckmarks
            data={usersRole}
            onChange={handleUserTypeOptionsChange}
            tag="Select User Type"
          />

          {enabled && userList?.length < 1 ? (
            <p className="text-red-600">Please select user type</p>
          ) : null}
        </Form.Group>

        {!isCyberMindAdminSelected ? (
          <Form.Group class="mb-6">
            <Form.Label
              for="countries"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
            >
              Select Company
            </Form.Label>
            <Form.Control
              as="select"
              class="form-select bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={company_name}
              onChange={(e) => {
                setCompany(e.target.value);
                setField('companySelect', e.target.value);
              }}
              isInvalid={!!errors.companySelect}
            >
              <option value="" selected="selected">
                Select Company
              </option>
              {clients?.map((x, y) => (
                <option value={x.id}>{x.name}</option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.companySelect}
            </Form.Control.Feedback>
          </Form.Group>
        ) : null}
        <Form.Group class="mb-6">
          <Form.Label
            for="email"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Email address
          </Form.Label>
          <Form.Control
            type="email"
            id="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setField('userEmail', e.target.value);
            }}
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="john.doe@company.com"
            isInvalid={!!errors.userEmail}
            required
            name="email"
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.userEmail}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group class="mb-6">
          <Form.Label
            for="password"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Username
          </Form.Label>
          <Form.Control
            type="text"
            id="text"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
              setField('userUsername', e.target.value);
            }}
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter a Username"
            isInvalid={!!errors.userUsername}
            required
            name="name"
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.userUsername}
          </Form.Control.Feedback>
        </Form.Group>
        <div className="sr-only">
          <Form.Group class=" mb-6">
            <Form.Label
              for="password"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Password
            </Form.Label>
            <Form.Control
              type="text"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="•••••••••"
              required
              name="password"
            ></Form.Control>
          </Form.Group>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          className="btn-cancel"
          onClick={() => {
            handleModalShowHide();
          }}
        >
          Cancel
        </Button>
        <Button
          className="btn-add-new"
          onClick={(e) => {
            submit(e);
          }}
          type="submit"
        >
          Add
        </Button>
      </Modal.Footer>
    </div>
  );
};

export default Signup;
