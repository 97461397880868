import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Switch from "react-switch";
import swal from "sweetalert";
import Signup from "../../../authentication/pages/Signup";
import http from "../../../resources/http";
import {
  CLIENTS,
  DELETE_USER,
  GET_CLIENT_USERS,
  UPDATE_USER,
} from "../../../routes/accounts";
import MultipleSelectCheckmarks from "../../../shared/MultiSelect/MultiSelect";
import { roles, rolesStaff } from "../../../data/data";
import { DeleteModal } from "../../../shared/DeleteModal";

const ManageUsers = () => {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [id, setId] = useState();
  const [val, setVal] = useState();
  const [username, setUsername] = useState();
  const [email, setEmail] = useState();
  const [company, setCompany] = useState(0);
  const [userType, setUsertype] = useState();
  const [status, setStatus] = useState();
  const [modal, setModal] = useState(false);
  const showModal = () => setModal((prevState) => !prevState);
  const [edit, setEditModal] = useState(false);
  const showEditModal = () => setEditModal((prevState) => !prevState);
  const [data, setData] = useState([]);
  const [clients, setClients] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [selectErrors, setSelectErrors] = useState("Please select user type");
  const [enabled, setEnabled] = useState(false);
  const [selectedUserType, setSelectedUserType] = useState([]);
  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const [isCyberMindAdmin, setIsCyberMindAdmin] = useState("");
  let localStorageData = JSON.parse(localStorage.getItem("cyber-minds"));
  const isUserCMSAdmin = localStorageData?.user?.is_cyberminds_admin;
  const usersRole = isUserCMSAdmin ? roles : rolesStaff;
  const pageCount = Math.ceil(data.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  console.log(usersRole, "usersRole");
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const handleDeleteConfirmation = () =>
    setDeleteConfirmation(!deleteConfirmation);

  const [deleteModal, setDeleteModal] = useState(false);
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);

  const [forrm, setForrm] = useState({}); //form
  const [errors, setErrors] = useState({}); //errors
  const setField = (field, value) => {
    setForrm({
      ...forrm,
      [field]: value,
    });

    if (!!errors[field])
      //if there is an error add the error to errors object
      setErrors({
        ...errors,
        [field]: null,
      });
  };
  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };
  const validateForm = () => {
    const {
      userTypeSelect = "",
      companySelect = "",
      userEmail = "",
      userUsername = "",
    } = forrm; //declare the variables and assign the values from the form object
    const newErrors = {};
    // if (!userTypeSelect || userTypeSelect === "")
    //   newErrors.userTypeSelect = "User type is require.";

    if (!validateEmail(userEmail))
      //very simple email format validation
      newErrors.userEmail = "Please enter a valid email address.";
    if (!userEmail || userEmail === "")
      newErrors.userEmail = "Email is required.";
    if (!userUsername || userUsername === "")
      newErrors.userUsername = "Username is required.";
    return newErrors;
  };
  console.log(data, "data");
  const fetchData = () => {
    http
      .get(`${GET_CLIENT_USERS}`)
      .then((response) => {
        setData(response.data);
      })
      .then(
        (response) => {},
        (err) => {
          console.log("No Data To Show");
        }
      )
      .catch((err) => {
        return false;
      });
    http
      .get(`${CLIENTS}`)
      .then((response) => {
        setClients(response.data);
      })
      .then(
        (response) => {},
        (err) => {
          console.log(err);
        }
      );
  };
  useEffect(() => {
    fetchData();
  }, []);

  const deletUser = () => {
    http.delete(`${DELETE_USER}${id}`).then(
      (response) => {
        toggleDeleteModal();
        swal("Success", "User has been deleted successfully ", "success");
        fetchData();
      },
      (err) => {
        swal(err);
      }
    );
  };
  const [userList, setUserList] = useState([]);
  console.log(userList, "selectedUserType");
  const isCyberMindAdminSelected = userList.includes("CybermindAdmin");
  console.log(isCyberMindAdminSelected, "isCyberMindAdminSelected");

  const handleUserTypeOptionsChange = (selected) => {
    setUserList(selected);
  };
  const DisplayData = data
    ?.slice(pagesVisited, pagesVisited + usersPerPage)
    ?.filter((items) => items.user_type?.[0] !== "CMCSupport")
    ?.map((users) => {
      return (
        <>
          <tr key={users?.id}>
            <td data-label="Username">{users.username}</td>
            <td className="" data-label="Email">
              {users.email}
            </td>
            <td data-label="Company">{users.name}</td>
            <td data-label="Company">{users?.user_type?.join(", ")}</td>
            <td
              data-label="Status"
              className="flex space-x-2 justify-center items-center"
            >
              <Switch
                checked={users.status === "active"}
                onChange={(checked) => {
                  const newStatus = checked ? "active" : "inactive";
                  setStatus(newStatus);
                  setFetchLoading(true);
                  http
                    .patch(`${UPDATE_USER}${users.id}`, {
                      status: newStatus,
                    })
                    .then(() => {
                      fetchData();
                      setFetchLoading(false);
                    })
                    .catch((err) => {
                      swal(err);
                    });
                }}
              />

              <p></p>
            </td>

            <td>
              <IconButton
                onClick={() => {
                  setVal(users.id);
                  setIsCyberMindAdmin(users.user_type?.[0]);
                  forrm.userUsername = users.username;
                  forrm.userEmail = users.email;
                  setUsertype(users.user_type);
                  forrm.companySelect = users.client;
                  setStatus(users.status);
                  setSelectedUserType(users.user_type);
                  showEditModal();
                }}
              >
                <EditIcon />
              </IconButton>

              <IconButton
                onClick={() => {
                  setId(users.id);
                  toggleDeleteModal();
                }}
              >
                <DeleteIcon />
              </IconButton>
            </td>
          </tr>
        </>
      );
    });
  return (
    <div className="p-10 ">
      {deleteModal ? (
        <DeleteModal handleDelete={deletUser} handleModal={toggleDeleteModal} />
      ) : null}
      <div className="table-title ">
        <span className="font-bold ">Users</span>
        <div className="flex justify-between items-center space-x-4">
          <button className="btn_file_download">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height={28}
              width={28}
              fill-rule="evenodd"
              fill="white"
              clip-rule="evenodd"
              image-rendering="optimizeQuality"
              shape-rendering="geometricPrecision"
              text-rendering="geometricPrecision"
              viewBox="0 0 17639 17639"
            >
              <path
                fill="#1e7145"
                d="M9596 3634v10265l-6103-1058V4692l6103-1058zm290 1185h4053c224 0 267 44 267 267v7360c0 223-43 267-267 267H9886v-694h1547v-1066H9886v-320h1547V9566H9886v-320h1547V8179H9886v-320h1547V6793H9886v-320h1547V5406H9886v-587zM2083 15593h13474l2-13476H2081l2 13476z"
              />
              <path
                fill="#1e7145"
                d="m7129 6861-556 1272-432-1195-695 31 690 1741-777 1703 683 51 548-1225 510 1296 786 43-852-1875 815-1894zM11753 12019h1760v-1066h-1760zM11753 10633h1760V9566h-1760zM11753 9246h1760V8179h-1760zM11753 6473h1760V5406h-1760zM11753 7859h1760V6793h-1760z"
              />
            </svg>
          </button>
          <div className="btn-add-new">
            <button onClick={() => showModal()}>
              <AddIcon className="" />
            </button>
          </div>
        </div>
      </div>
      <table class="tables">
        <thead className="sticky-header">
          <tr>
            <th scope="col">Username</th>
            <th scope="col">Email</th>
            <th scope="col">Company</th>
            <th scope="col">User Type</th>
            <th scope="col">Status</th>
            <th scope="col">Manage</th>
          </tr>
        </thead>
        <tbody>{DisplayData}</tbody>
      </table>

      <div className="">
        {data.length > 5 && (
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            activeClassName={"paginationActive"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
          />
        )}
      </div>

      {/* adding modal */}
      <Modal show={modal}>
        <Modal.Header
          onClick={() => {
            showModal();
            fetchData();
          }}
        >
          <Modal.Title>Add New User</Modal.Title>
        </Modal.Header>
        <Signup handleModalShowHide={showModal} fetchData={fetchData} />
      </Modal>
      {/* editing modal */}
      {edit ? (
        <Modal show={true}>
          <Modal.Header onClick={() => showEditModal()}>
            <Modal.Title>Update User</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body-medium">
            <Form.Group class="mb-6">
              <Form.Label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Username
              </Form.Label>
              <Form.Control
                type="email"
                id="email"
                value={forrm.userUsername}
                onChange={(e) => {
                  setUsername(e.target.value);
                  setField("userUsername", e.target.value);
                }}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Username"
                required
                isInvalid={!!errors.userUsername}
                name="email"
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.userUsername}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group class="mb-6">
              <Form.Label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Email
              </Form.Label>
              <Form.Control
                type="email"
                id="email"
                value={forrm.userEmail}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setField("userEmail", e.target.value);
                }}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="john.doe@company.com"
                isInvalid={!!errors.userEmail}
                required
                name="email"
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.userEmail}
              </Form.Control.Feedback>
            </Form.Group>
            {isCyberMindAdmin !== "CybermindAdmin" ? (
              <Form.Group class="mb-6">
                <Form.Label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Company Name
                </Form.Label>
                <Form.Control
                  as="select"
                  id="email"
                  value={forrm.companySelect}
                  onChange={(e) => {
                    setCompany(e.target.value);
                    setField("companySelect", e.target.value);
                  }}
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Company Name"
                  required
                  isInvalid={!!errors.companySelect}
                  name="email"
                >
                  <option value="" selected="selected">
                    {forrm.companySelect?.length > 0
                      ? forrm.companySelect
                      : "Select Company"}
                  </option>
                  {clients?.map((x, y) => (
                    <option value={x.id}>{x.name}</option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.companySelect}
                </Form.Control.Feedback>
              </Form.Group>
            ) : null}
            <Form.Group class="">
              <Form.Label
                for="countries"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
              >
                Select User Type
              </Form.Label>

              {/* <MultiSelect
                options={options}
                value={selectedUserType}
                onChange={setSelectedUserType}
              /> */}
              <MultipleSelectCheckmarks
                data={usersRole}
                onChange={handleUserTypeOptionsChange}
                tag="Select User Type"
                initialSelectedValues={selectedUserType}
              />
              {enabled && selectedUserType?.length < 1 ? (
                <p className="text-red-600">Please select user type</p>
              ) : null}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => showEditModal()} className="btn-cancel">
              Cancel
            </Button>
            <Button
              className="btn-add-new"
              onClick={() => {
                const formErrors = validateForm();
                if (Object.keys(formErrors).length > 0) {
                  setErrors(formErrors);
                } else if (selectedUserType?.length === 0) {
                  setSelectErrors("Please select user type");
                } else {
                  http
                    .patch(`${UPDATE_USER}${val}`, {
                      status: status,
                      email: email,
                      username: username,
                      user_type: userList,
                      client: forrm.companySelect,
                    })
                    .then((response) => {
                      showEditModal();
                      swal(
                        "Success",
                        "User record Updated successfully",
                        "success"
                      );
                      fetchData();
                    })
                    .catch((err) => {
                      swal(err);
                    });
                }
                setEnabled(true);
              }}
            >
              Save changes
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </div>
  );
};

export default ManageUsers;
