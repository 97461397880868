import React from "react";
import { baseURL } from "../resources/apiClient";
import { useApi } from "../hooks/useApis";
import { toLowercase } from "../utils/helpers";
import { getBackgroundColor } from "../utils/getBackgroundColor";
import Chart from "react-apexcharts";
import LoadingSpinner from "../utils/LoadingSpinner";

const TopThreats = () => {
	const { data, isLoading, fetchData, isError } = useApi(
		`${baseURL}/threat-catalog/top-threats`
	);

	const transformedData = data?.map((item) => ({
		x: item?.threat,
		y: item.reputation,
		risk_profile: item.risk_profile,
	}));

	const uniqueData = transformedData?.reduce(
		(acc, current) => {
			const key = `${current.x}-${current.y}-${current.risk_profile}`;
			if (!acc.set.has(key)) {
				acc.set.add(key);
				acc.result.push(current);
			}
			return acc;
		},
		{ set: new Set(), result: [] }
	)?.result;

	// Convert the Set back to an array
	// const uniqueScopes = Array.from(allScopes);
	// Extract unique values for x, y, and risk_profile
	// Extract unique x values
	const uniqueX = [...new Set(uniqueData?.map((item) => item.x))];

	// Create uniqueY and uniqueRiskProfile based on uniqueX
	const uniqueY = uniqueX?.map((xVal) => {
		const item = uniqueData.find((item) => item.x === xVal);
		return item ? item.y : undefined;
	});

	const uniqueRiskProfile = uniqueX?.map((xVal) => {
		const item = uniqueData.find((item) => item.x === xVal);
		return item ? getBackgroundColor(item.risk_profile) : undefined;
	});
	const limitedX = uniqueX.map((item) => {
		if (item.length > 27) {
			return item.slice(0, 27); // Truncate to 27 characters if longer
		} else {
			return item; // Return unchanged if shorter than or equal to 27 characters
		}
	});

	if (isLoading) {
		return <LoadingSpinner />;
	}
	return (
		<div className="p-4">
			<Chart
				type="pie"
				width={500}
				height={350}
				series={uniqueY}
				options={{
					labels: limitedX,
					colors: [
						"var(--color-chart-one)",
						"var(--color-chart-two)",
						"var(--color-chart-three)",
						"var(--color-chart-four)",
						"var(--color-chart-five)",
					],
					stroke: {
						width: 0,
					},
					dataLabels: {
						style: {
							fontSize: "14px",
							fontFamily: "Helvetica, Arial, sans-serif",
							fontWeight: "bold",
						},
						enabled: false,
						formatter: function (val, opts) {
							/* Format labels here */
							return opts.w.config.series[opts.seriesIndex];
						},
					},
					//start
					responsive: [
						{
							breakpoint: 535,
							options: {
								chart: {
									width: 320,
								},
								legend: {
									offsetY: -13,
									offsetX: 0,
								},
							},
						},
					],
					//finish
					legend: {
						fontSize: "14px",
						fontWeight: "bold",
						itemMargin: "left",
						labels: {
							colors: "black",
						},
					},
				}}
			/>
		</div>
	);
};

export default TopThreats;
