export const UPLOAD_RISK_EXCEL_ENDPOINT = '/risk/upload-risk-excel';
export const RISK_SUMMARY_ENDPOINT = '/risk/risk-summary';
export const TESTING_ENDPOINT = '/risk/testing';
export const RISK_SENSITIVITY_LEVELS = '/risk/risk-sensitivity-levels';
export const RISK_SENSITIVITY_LEVEL_BY_PK_ENDPOINT =
  '/risk/risk-sensitivity-level/:pk';
export const RISK_SENSITIVITIES_ENDPOINT = '/risk/risk-sensitivities';
export const RISK_SENSITIVITY_BY_PK_ENDPOINT = '/risk/risk-sensitivity/:pk';
export const PROBABILITIES_ENDPOINT = '/risk/probabilities';
export const PROBABILITY_BY_PK_ENDPOINT = '/risk/probability/:pk';
