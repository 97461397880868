import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./authentication/pages/Login";
import Signup from "./authentication/pages/Signup";
import BusinessImpactAnalysis from "./Client/BusinessImpactAnalysis";
import Header from "./layout/components/Header";
import AssetDetails from "./layout/components/Threat Scope/MoreDetails/AssetDetails";
import RiskDetails from "./layout/components/Threat Scope/MoreDetails/RiskDetails";
import VendorsDetail from "./layout/components/Threat Scope/MoreDetails/VendorsDetail";
import VendorsDetailHeader from "./layout/components/Threat Scope/MoreDetails/VendorsDetailHeader";
// superAdmin
import ThreatVector from "./ClientAdmin/ThreatVector";
import IndustryStandard from "./SuperAdmin/Configuration/ConfigureControls/IndustryStandard";
import IndustryUseCase from "./SuperAdmin/Configuration/ConfigureControls/IndustryUseCase.js";
import Currency from "./SuperAdmin/Configuration/ConfigureCurrency/Currency";
import RiskSensetivity from "./SuperAdmin/Configuration/ConfigureRisk/RiskSensetivity";
import AddThreatVectorsSuperAdmin from "./SuperAdmin/Home/AddThreatVectorsSuperAdmin";
import ThreatVectorSuper from "./SuperAdmin/Home/ThreatVectorSuper";
import ThreatCatalogAdmin from "./SuperAdmin/ManageFile/ManageControlCatalog/ThreatCatalogAdmin";
import ViewTcMapping from "./SuperAdmin/ManageFile/ManageThreat/ViewTcMapping";
// import UploadFiles from "./SuperAdmin/manageFile/UploadFiles";
import ApplicationAccessibility from "./SuperAdmin/Configuration/ConfigureBusinessProcess/ApplicationAccessibility";
import BroadCastPopulationUser from "./SuperAdmin/Configuration/ConfigureBusinessProcess/BroadCastPopulationUser";
import BusinessCriticality from "./SuperAdmin/Configuration/ConfigureBusinessProcess/BusinessCriticality";
import ClassificationLevel from "./SuperAdmin/Configuration/ConfigureBusinessProcess/ClassificationLevel";
import HostingModel from "./SuperAdmin/Configuration/ConfigureBusinessProcess/HostingModel";
import SourceDestinationOfData from "./SuperAdmin/Configuration/ConfigureBusinessProcess/SourceDestinationOfData";
import UserInterfaceAccessibility from "./SuperAdmin/Configuration/ConfigureBusinessProcess/UserInterfaceAccessibility";
import UserPopulation from "./SuperAdmin/Configuration/ConfigureBusinessProcess/UserPopulation";
import ControlApplicability from "./SuperAdmin/Configuration/ConfigureControls/ControlApplicability";
import ControlImpact from "./SuperAdmin/Configuration/ConfigureControls/ControlImpact";
import ControlScope from "./SuperAdmin/Configuration/ConfigureControls/ControlScope";
import ControlType from "./SuperAdmin/Configuration/ConfigureControls/ControlType";
import CostCategory from "./SuperAdmin/Configuration/ConfigureCost/CostCategory";
import ExploitablePath from "./SuperAdmin/Configuration/ConfigureExploitablePath/ExploitablePath";
import RiskProbabilityMapping from "./SuperAdmin/Configuration/ConfigureRisk/RiskProbabilityMapping";
import Availability from "./SuperAdmin/Configuration/ConfigureThreatRepository/Availability";
import Confidentiality from "./SuperAdmin/Configuration/ConfigureThreatRepository/Confidentiality";
import Discoverability from "./SuperAdmin/Configuration/ConfigureThreatRepository/Discoverability";
import Exploitability from "./SuperAdmin/Configuration/ConfigureThreatRepository/Exploitability";
import ExploitablePathsTabsWrapper from "./SuperAdmin/Configuration/ConfigureThreatRepository/ExploitablePathsTabsWrapper";
import Integrity from "./SuperAdmin/Configuration/ConfigureThreatRepository/Integrity";
import Reproducibility from "./SuperAdmin/Configuration/ConfigureThreatRepository/Reproducibility";
import ThreatCategory from "./SuperAdmin/Configuration/ConfigureThreatRepository/ThreatCategory";
import AT from "./SuperAdmin/ManageFile/ManageAssetThreat/AT";
import ATmapping from "./SuperAdmin/ManageFile/ManageAssetThreat/ATmapping";
import ViewConsensus from "./SuperAdmin/ManageFile/ManageConsensus/ViewConsensus";
import ViewControlsCatalog from "./SuperAdmin/ManageFile/ManageControlCatalog/ViewControlsCatalog";
import ViewCosts from "./SuperAdmin/ManageFile/ManageCost/ViewCosts";
import IndustryDataSA from "./SuperAdmin/ManageFile/ManageIndustry/IndustryDataSA";
import IndustrySector from "./SuperAdmin/Configuration/ConfigureIndustrySector/IndustrySector";
import ViewNIST from "./SuperAdmin/ManageFile/ManageNIST/ViewNIST";
import ViewResponseDistribution from "./SuperAdmin/ManageFile/ManageResponseDistribution/ViewResponseDistribution";
import ManageClients from "./SuperAdmin/Accounts/AccountManageClients/ManageClients";
import ManageUsers from "./SuperAdmin/Accounts/AccountManageUsers/ManageUsers";
import Assets from "./SuperAdmin/Configuration/ConfigureAsset/Assets";
import BusinessImpact from "./SuperAdmin/Configuration/ConfigureBusinessProcess/BusinessImpact";
import ControlCategory from "./SuperAdmin/Configuration/ConfigureControls/ControlCategory";
import ControlDomain from "./SuperAdmin/Configuration/ConfigureControls/ControlDomain";
import InvestmentRequirements from "./SuperAdmin/Configuration/ConfigureInvestment/InvestmentRequirements";
import ThreatModeling from "./SuperAdmin/Configuration/ConfigureThreatModeling/ThreatModeling";
import ThreatModelingMatrix from "./SuperAdmin/Configuration/ConfigureThreatModeling/ThreatModelingMatrix";
import BusinessImpactAnalysisSA from "./SuperAdmin/ManageFile/ManageBusinessImpact/BusinessImpactAnalysisSA";
import ControlEffectivenessMatrix from "./SuperAdmin/ManageFile/ManageControlEffectiveness/ControlEffectivenessMatrix";

import ExploitablePaths from "./Client/ExploitablePaths";
// import ManageBusinessImpact from "./ClientAdmin/ManageBusinessProcessAdmin";
import VendorCritical from "./layout/components/Admin/Header/VendorCritical";
import CompanyProfile from "./layout/components/Threat Scope/CompanyProfile";
import CompanyProfileDetail from "./layout/components/Threat Scope/CompanyProfileDetail";
import AuthenticatedRoute from "./layout/routers/AuthenticatedRoute";
import ChangePassword from "./shared/ChangePassword";

import ExcutiveSummary from "./Client/ExcutiveSummary";
import RiskAnalysis from "./Client/RiskAnalysis";
import ThreatProfiling from "./Client/ThreatProfiling";
import ThreatsProfiling from "./Client/Analysis/ThreatsProfiling";

import ClientAdminHomePage from "./ClientAdmin/ClientAdminHomePage";

import ClientBusinessImpactAnalysis from "./Client/Configuration/ClientBusinessImpactAnalysis";

import useSessionChecker from "./authentication/InActivityChecker/useInactivity";
import AuthenticatorSetup from "./authentication/MultiFactorAuthentication/AuthenticatorSetup";
import MultiFactorAuthentication from "./authentication/MultiFactorAuthentication/MultiFactorAuthentication";
import MultiFactorSetup from "./authentication/MultiFactorAuthentication/MultiFactorSetup";
import Signout from "./authentication/pages/SignOut";
import ForgotPasswordReset from "./authentication/ResetPassword/ForgotPasswordReset";
import PasswordReset from "./authentication/ResetPassword/PasswordReset";
import ControAnalysis from "./Client/ControAnalysis";
import ControlImplementation from "./Client/Analysis/ControlImplementation";
import ControlAnalysisClientAdmin from "./ClientAdmin/ControlAnalysisClientAdmin";
import ManageBusinessProcessAdmin from "./ClientAdmin/ManageBusinessProcessAdmin";
import NewsComponent from "./layout/components/Threat Scope/NewsComponent";
import ExecutiveDashboard from "./Client/Home/ExecutiveDashboard.js";

import { useClearLocalStorageIfSessionExpired } from "./authentication/SessionExpire.js/sessionExpire";
import ClientNavbar from "./Client/Home/Navbar/ClientNavbar";
import ClientAdminNavbar from "./ClientAdmin/Home/Navbar/ClientAdminNavbar";
import SuperAdminNavBar from "./SuperAdmin/Home/Navbar/SuperAdminNavbar.js";
import ManageTickets from "./Support/ManageTickets";
import ManageUsersAccount from "./Support/ManageUsersAccount";
import SupportNavBar from "./Support/SupportNavBar";
import Support from "./components/Support/Support";
import ManageTicketsSupport from "./Support/ManageTicketsSupport";
import ManageReports from "./Support/ManageReports";

import { Toaster } from "react-hot-toast";
import ControlAnalysis from "./Client/Analysis/ControlAnalysis/ControlAnalysis.js";
import ControlAnalysisSummary from "./Client/Analysis/ControlAnalysis/ControlAnalysisSummary.js";
import InvestmentAnalysis from "./Client/Analysis/InvestmentAnalysis/InvestmentAnalysis.js";

function App(props) {
	const [route, setRoute] = useState(localStorage.getItem("last-route"));

	useEffect(() => {
		setRoute(window.location.pathname);
	}, []);
	useEffect(() => {
		window.process = {
			...window.process,
		};
	}, []);

	useSessionChecker();
	useClearLocalStorageIfSessionExpired();
	return (
		<Router>
			<Toaster className="pt-10" />
			<AuthenticatedRoute exact path="/" roles={["ClientUser"]}>
				<ClientNavbar />
				<ExecutiveDashboard />
			</AuthenticatedRoute>

			<Switch>
				<Route exact path="/client_support">
					<ClientNavbar />
					<Support />
				</Route>
				<Route exact path="/client_admin_support">
					<ClientAdminNavbar />
					<Support />
				</Route>
				<Route exact path="/signin">
					<Login />
				</Route>
				<Route exact path="/change_password">
					<ChangePassword />
				</Route>
				<Route exact path="/reset-password">
					<PasswordReset />
				</Route>
				<Route
					exact
					path="/forgot-password-change"
					component={ForgotPasswordReset}
				/>
				<Route exact path="/signin" component={Login} />
				<Route
					exact
					path="/multifactor-authentication-setup"
					component={MultiFactorSetup}
				/>
				<Route
					exact
					path="/authenticator-app-setup"
					component={AuthenticatorSetup}
				/>
				<Route
					exact
					path="/multifactor-authentication"
					component={MultiFactorAuthentication}
				/>
				<Route exact path="/signout" component={Signout} />
				{/* #Client Pages Start*/}
				{/* #Client Pages Start*/}
				<AuthenticatedRoute
					exact
					path="/c/analysis/controlanalysis/controlanalysis"
					roles={["ClientUser"]}
				>
					<ClientNavbar />
					<ControlAnalysis />
				</AuthenticatedRoute>

				<AuthenticatedRoute
					exact
					path="/c/analysis/controlanalysis/controlanalysissummary"
					roles={["ClientUser"]}
				>
					<ClientNavbar />
					<ControlAnalysisSummary />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/c/analysis/investment-analysis"
					roles={["ClientUser"]}
				>
					<ClientNavbar />
					<InvestmentAnalysis />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/c/executivesummary"
					roles={["ClientUser"]}
				>
					<ClientNavbar />
					<ExcutiveSummary />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/c/analysis/business-impact-analysis"
					roles={["ClientUser"]}
				>
					<ClientNavbar />
					<BusinessImpactAnalysis />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/exploitablePaths"
					roles={["ClientUser"]}
				>
					<ClientNavbar />
					<ExploitablePaths />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/company-profile"
					roles={["ClientUser"]}
				>
					<ClientNavbar />
					<CompanyProfile />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/c/configuration/business_process"
					roles={["ClientUser"]}
				>
					<ClientNavbar />
					<ManageBusinessProcessAdmin isPopUp={false} />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/c/configuration/business_impact_analysis"
					roles={["ClientUser"]}
				>
					<ClientNavbar />
					<ClientBusinessImpactAnalysis />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/company-profile-detail"
					roles={["ClientUser"]}
				>
					<ClientNavbar />
					<CompanyProfileDetail />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/c/analysis/riskanalysis"
					roles={["ClientUser"]}
				>
					<ClientNavbar />
					<RiskAnalysis />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/c/analysis/controlimplementation"
					roles={["ClientUser"]}
				>
					<ClientNavbar />
					<ControlImplementation />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/c/analysis/threatsprofiling"
					roles={["ClientUser"]}
				>
					<ClientNavbar />
					<ThreatsProfiling />
				</AuthenticatedRoute>
				{/* <AuthenticatedRoute
          exact
          path="/c/analysis/clientHomePage"
          roles={['ClientUser']}
        >
          <ClientNavbar />
          <ClientHomePage />
        </AuthenticatedRoute> */}
				<AuthenticatedRoute
					exact
					path="/c/analysis/threatprofiling"
					roles={["ClientUser"]}
				>
					<ClientNavbar />
					<ThreatProfiling />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/latest-cyber-security-news"
					roles={["ClientUser"]}
				>
					<ClientNavbar />
					<NewsComponent />
				</AuthenticatedRoute>
				<AuthenticatedRoute exact path="/risk_details" roles={["ClientUser"]}>
					<RiskDetails />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/vendor_critical_details"
					roles={["ClientUser"]}
				>
					<VendorsDetailHeader />
					<VendorCritical />
				</AuthenticatedRoute>
				<AuthenticatedRoute exact path="/asset_details" roles={["ClientUser"]}>
					<AssetDetails />
				</AuthenticatedRoute>
				<AuthenticatedRoute exact path="/vendor_details" roles={["ClientUser"]}>
					<VendorsDetailHeader />
					<VendorsDetail />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/change_my_password"
					roles={["ClientUser"]}
				>
					<ChangePassword />
				</AuthenticatedRoute>
				{/* #Client Pages End*/}
				{/* #Client Pages End*/}
				{/* #Admin Pages Start*/}
				<AuthenticatedRoute
					exact
					path="/ca/manage_file/adminhome"
					roles={["ClientAdmin"]}
				>
					<ClientAdminNavbar />
					<ClientAdminHomePage />
				</AuthenticatedRoute>
				<AuthenticatedRoute exact path="/admin" roles={["ClientAdmin"]}>
					<Header />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/ca/threat_modeling"
					roles={["ClientAdmin"]}
				>
					<ClientAdminNavbar />
					<ThreatModeling />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/ca/manage_file/control_analysis"
					roles={["ClientAdmin"]}
				>
					<ClientAdminNavbar />
					<ControlAnalysisClientAdmin />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/ca/manage_business_impact"
					roles={["ClientAdmin"]}
				>
					<ClientAdminNavbar />
					<ManageBusinessProcessAdmin isPopUp={false} />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/ca/threat_vectors"
					roles={["ClientAdmin"]}
				>
					<ClientAdminNavbar />
					<ThreatVector />
				</AuthenticatedRoute>
				{/* <AuthenticatedRoute
					exact
					path="/add_threat_vectors"
					roles={["ClientAdmin"]}
				>
					<ClientAdminNavbar />
					<AddThreatVectorsSuperAdmin />
				</AuthenticatedRoute> */}
				{/* #Admin Pages End*/}
				{/* CyberMindsAdmin start */}
				<AuthenticatedRoute exact path="/signup" roles={["CybermindAdmin"]}>
					<Signup />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/manage_users"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<ManageUsers />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/configurations/threat_modeling_matrix_configuration"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<ThreatModelingMatrix />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/manage_clients"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<ManageClients />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/manage_file/risk_tc_mapping"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<ViewTcMapping />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/manage_at_mapping"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<ATmapping />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/manage_file/AT_mapping"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<AT />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/threat_catalog"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<ThreatCatalogAdmin />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/configurations/manage_currency"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<Currency />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/configurations/manage_asset"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<Assets />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/configurations/cost_category"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<CostCategory />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/configurations/risk_sensitivity"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<RiskSensetivity />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/super_add_threat_vectors"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<AddThreatVectorsSuperAdmin />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/super_threat_vectors"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<ThreatVectorSuper />
				</AuthenticatedRoute>
				{/* super admin business_process  */}
				<AuthenticatedRoute
					exact
					path="/manage_file/business_impact_analysis"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<BusinessImpactAnalysisSA />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/configurations/business_process/criticality"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<BusinessCriticality />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/configurations/business_process/classification_level_of_data"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<ClassificationLevel />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/configurations/business_process/business_impacts"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<BusinessImpact />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/configurations/business_process/broadcast_population_of_user"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<BroadCastPopulationUser />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/configurations/business_process/user_population"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<UserPopulation />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/configurations/business_process/application_accessibility"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<ApplicationAccessibility />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/configurations/business_process/hosting_model"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<HostingModel />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/configurations/business_process/user_interface_accessibility"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<UserInterfaceAccessibility />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/configurations/business_process/origin_of_data"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<SourceDestinationOfData />
				</AuthenticatedRoute>
				<AuthenticatedRoute exact path="/manage_tickets" roles={["CMCSupport"]}>
					<SupportNavBar />
					<ManageTicketsSupport />
				</AuthenticatedRoute>
				<AuthenticatedRoute exact path="/manage_reports" roles={["CMCSupport"]}>
					<SupportNavBar />
					<ManageReports />
				</AuthenticatedRoute>
				<Route exact path="/cmc_admin_support" roles={["CybermindAdmin"]}>
					<SuperAdminNavBar />
					<Support />
				</Route>
				<AuthenticatedRoute
					exact
					path="/manage_users_account"
					roles={["CMCSupport"]}
				>
					<SupportNavBar />
					<ManageUsers />
				</AuthenticatedRoute>
				{/* <AuthenticatedRoute exact path="/" roles={["ClientUser"]}>
					<ClientNavbar />
					<BusinessImpactAnalysis />
				</AuthenticatedRoute> */}
				<AuthenticatedRoute
					exact
					path="/manage_file/industry_data"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<IndustryDataSA />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/manage_file/response_distribution_data"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<ViewResponseDistribution />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/manage_file/control_matrix"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<ControlEffectivenessMatrix />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/configurations/manage_investment_requirements"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<InvestmentRequirements />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/manage_file/upload_costs"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<ViewCosts />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/configurations/manage_sector"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<IndustrySector />
				</AuthenticatedRoute>
				{/* Configurations */}
				{/* configuration threat repository start*/}
				<AuthenticatedRoute
					exact
					path="/configurations/threat_repository/reproducibility"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<Reproducibility />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/configurations/threat_repository/threatcategory"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<ThreatCategory />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/configurations/threat_repository/discoverability"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<Discoverability />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/configurations/threat_repository/integrity"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<Integrity />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/configurations/threat_repository/exploitability"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<Exploitability />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/configurations/threat_repository/confidentiality"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<Confidentiality />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/configurations/threat_repository/availability"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<Availability />
				</AuthenticatedRoute>
				{/* configuration threat repository end*/}
				<AuthenticatedRoute
					exact
					path="/configurations/super-admin-manage-exploitable-paths"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<ExploitablePath />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/ca/manage_file/exploitability_paths"
					roles={["CybermindAdmin"]}
				>
					<ClientAdminNavbar />
					<ExploitablePathsTabsWrapper />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/configurations/controls/availability"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<ControlApplicability />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/configurations/controls/impact"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<ControlImpact />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/configurations/controls/scope"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<ControlScope />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/configurations/controls/domain"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<ControlDomain />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/configurations/controls/category"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<ControlCategory />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/configurations/controls/typeTest"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<ControlType />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/configurations/controls/type"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<ControlType />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/configurations/controls/industry_standard"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<IndustryStandard />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/configurations/controls/industry_usecase"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<IndustryUseCase />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/manage_file/consensus"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<ViewConsensus />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/manage_file/control_catalog"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<ViewControlsCatalog />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/manage_file/NIST"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<ViewNIST />
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path="/configurations/manage_risk_probability"
					roles={["CybermindAdmin"]}
				>
					<SuperAdminNavBar />
					<RiskProbabilityMapping />
				</AuthenticatedRoute>
			</Switch>
		</Router>
	);
}

export default App;
