import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import { BUSINESS_IMPACT_LIST } from "../api/excelUpload.js";
import { pid } from "../authentication/store/actions/index.js";
import { baseURL } from "../resources/apiClient.js";
import http from "../resources/http.js";
import LoadingComponent from "../shared/LoadingDropDownSelection/LoadingComponent.js";
import { downloadExcelData } from "../utils/ExportExcelUtility.js";
import FileUploadLoading from "../utils/FileUploadLoading.js";
import { DeleteModal } from "../shared/DeleteModal";

const ClientAdminHomePage = () => {
  const [file, setFile] = useState(null);
  const [action, setAction] = useState("");
  const [client, setClient] = useState();
  const [showHide, setShowHide] = useState(false);
  const handleModalShowHide = () => setShowHide(!showHide);
  const [company_name, setCompany] = useState();
  const [data, setData] = useState([]);
  const [business_process, setBusinessProcess] = useState(0);
  let localStorageData = JSON.parse(localStorage.getItem("cyber-minds"));
  let clientID = localStorageData?.user?.client;
  const [datas, setDatas] = useState([]);
  const [businessProcessID, setBusinessProcessID] = useState(0);
  // const processId = useSelector((state) => state.pid);

  const [processId, setProcessId] = useState();
  const dispatch = useDispatch();
  const [hide, setHide] = useState(false);
  const showHideButton = () => setHide(!hide);
  const [pageNumber, setPageNumber] = useState(0);

  //Edit Modals
  const [hierarchy, setSetHierarchy] = useState();
  const [bussinessAsset, setBusinessAsset] = useState();
  const [assetName, setAssetName] = useState();
  const [vendors, setVendors] = useState();
  const [product, setProduct] = useState();
  const [version, setVersion] = useState();
  const [cpe, setCpe] = useState();
  const [assetType, setAssetType] = useState();
  const [assetCategorization, setAssetCategorization] = useState();
  const [regulation, setRegulation] = useState();
  const [assetRisk, setAssetRisk] = useState();
  const [businessProcessId, setBusinessProcessId] = useState();
  const [id, setId] = useState();

  const [showHideMe, setSetShowHideMe] = useState(false);

  const handleModalShowHideMe = () => setSetShowHideMe(!showHideMe);

  const [showFileUploader, setShowFileUploader] = useState(false);
  const toggleFileUploader = () => setShowFileUploader(!showFileUploader);
  const [error, setError] = useState(null);

  const [businessProcessName, setBusinessProcessName] = useState([]);

  const [loading, setLoading] = useState(false);

  const usersPerPage = 8;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(datas?.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  //form data and error handler
  const [form, setForm] = useState({}); //form
  const [errors, setErrors] = useState({}); //errors

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field])
      //if there is an error add the error to errors object
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  //input validator
  const validateForm = () => {
    const {
      hierarchy = "",
      business_assets = "",
      asset_name = "",
      vendors = "",
      product = "",
      version = "",
      cpe = "",
      asset_type = "",
      asset_categorization = "",
      regulations = "",
      asset_risk = "",
    } = form; //declare the variables and assign the values from the form object
    const newErrors = {};
    if (!hierarchy || hierarchy === "")
      newErrors.hierarchy = "Hierarchy is require.";
    if (!business_assets || business_assets === "")
      newErrors.business_assets = "Business assets is require.";
    if (!asset_name || asset_name === "")
      newErrors.asset_name = "Asset name is require.";
    if (!vendors || vendors === "") newErrors.vendors = "Vendors is require.";
    if (!product || product === "") newErrors.product = "Product is require.";
    if (!version || version === "") newErrors.version = "Version is require.";
    if (!cpe || cpe === "") newErrors.cpe = "Cpe is require.";
    if (!asset_type || asset_type === "")
      newErrors.asset_type = "Asset type is require.";
    if (!asset_categorization || asset_categorization === "")
      newErrors.asset_categorization = "Asset categorization is require.";
    if (!regulations || regulations === "")
      newErrors.regulations = "Regulations is require.";
    if (!asset_risk || asset_risk === "")
      newErrors.asset_risk = "Asset rick is require.";
    return newErrors;
  };

  //form filed clearer
  const clearAll = () => {
    form.hierarchy = "";
    form.business_assets = "";
    form.asset_name = "";
    form.vendors = "";
    form.product = "";
    form.version = "";
    form.cpe = "";
    form.asset_type = "";
    form.asset_categorization = "";
    form.regulations = "";
    form.asset_risk = "";
  };

  const submit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      http
        .post(`${BUSINESS_IMPACT_LIST}`, {
          hierarchy: form.hierarchy,
          business_assets: form.business_assets,
          asset_name: form.asset_name,
          vendors: form.vendors,
          product: form.product,
          version: form.version,
          cpe: form.cpe,
          asset_type: form.asset_type,
          asset_categorization: form.asset_categorization,
          regulations: form.regulations,
          asset_risk: form.asset_risk,
          domain: "domain",
          business_process: form.selected_business_process,
          client: clientID,
        })
        .then(
          (response) => {
            handleModalShowHideMe();
            swal("Success", "It has been added successfully.", "success");
            fetchBIA();
          },
          (error) => {
            // Handle specific error message from backend
            console.error("Error:", error.response);
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              swal("Error", error.response.data.message, "error");
            } else {
              swal(
                "Error",
                "Something went wrong, please try again....",
                "error"
              );
            }
          }
        );
    }
  };
  const [fileChosen, setFileChosen] = useState("");

  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  const [fetchLoading, setFetchLoading] = useState(false);
  const [isNullData, setIsNullData] = useState(false);
  // Handle dropdown selection
  const [selectedBusinessProcessId, setSelectedBusinessProcessId] = useState(0);
  console.log(selectedBusinessProcessId, "bp");
  const fetchBIA = async () => {
    if (!selectedBusinessProcessId) {
      // Clear previous data if no business process ID is selected
      setDatas([]);
      return;
    }
    setFetchLoading(true);
    setIsNullData(false);
    try {
      const response = await http.get(
        `${baseURL}/business_process/get-business-impact/${selectedBusinessProcessId}`
      );
      const responseData = response.data;

      if (responseData.length > 0) {
        // Data is available, update the state with the new data
        setFetchLoading(false);
        setDatas(responseData);
      } else {
        // No data available, show a notification
        setDatas([]);
        setFetchLoading(false);
        setIsNullData(true);
      }
    } catch (err) {
      // Error occurred, show a notification
      setDatas([]);
      setFetchLoading(false);
      swal("No Data To Show");
    }
  };

  useEffect(() => fetchBIA(), [selectedBusinessProcessId]);
  const fetchData = async () => {
    // gets all available business process for given client
    http
      .get(`${baseURL}/business_process/get-my-business-process`)
      .then((response) => {
        setItems(response.data);
        setData(response.data);
      });

    // based on selected business process returns BIA
    http
      .get(
        `${baseURL}/business_process/business-process/${selectedBusinessProcessId}`
      )
      .then((response) => {
        setBusinessProcessName(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(datas, "this is the mapped data");
  // Handle dropdown selection
  const handleDropdownSelect = (eventKey) => {
    console.log(eventKey, "test");
    setSelectedItem(eventKey);
    fetchData(eventKey);
  };

  // Load initial data (if needed)
  useEffect(() => {
    fetchData();
  }, [selectedItem]);

  const handleFile = (e) => {
    // Get the selected file
    let selectedFile = e.target.files[0];

    if (selectedFile) {
      // Extract the file extension from the selected file's name
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

      // Check if the file extension is allowed
      if (fileExtension === "xlsx") {
        // Set the selected file in the component state
        setFile(selectedFile);
      } else {
        // If the selected file is not of the allowed type, show an error message
        swal("Error", "Please upload a valid .xlsx file", "error");
        // Clear the file input field
        e.target.value = null;
      }
    } else {
      toggleFileUploader();
    }
  };
  const handleUploadFile = (e) => {
    if (business_process > 0) {
      setLoading(true);
      let myfile = file;
      let formData = new FormData();
      formData.append("file", myfile);
      formData.append("Client", clientID);
      formData.append("erase", true);
      formData.append("business_process", business_process);
      formData.append("business impact analysis", "financial");
      http({
        url: `${baseURL}/excel-upload/upload-bia-excel`,
        method: "POST",
        mode: "cors",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data", // Set the Content-Type explicitly
        },
      }).then(
        (response) => {
          setLoading(false);
          toggleFileUploader();
          swal("Success!", "It has been uploaded successfully.", "success");
          fetchData();
        },
        (err) => {
          setLoading(false);
          toggleFileUploader();
          swal("error", "File Upload Failed", "error");
        }
      );
    } else {
      setError("Please Select business process");
    }
  };
  const handleUploadFileAppend = (e) => {
    if (business_process > 0) {
      setLoading(true);
      let myfile = file;
      let formData = new FormData();
      formData.append("file", myfile);
      formData.append("Client", clientID);
      formData.append("erase", false);
      formData.append("business_process", business_process);
      formData.append("business impact analysis", "financial");
      http({
        url: `${baseURL}/excel-upload/upload-bia-excel`,
        method: "POST",
        mode: "cors",
        data: formData,
      }).then(
        (response) => {
          setLoading(false);
          toggleFileUploader();
          swal("Success!", "It has been uploaded successfully.", "success");
          fetchData();
        },
        (err) => {
          setLoading(false);
          toggleFileUploader();
          swal("error", "File Upload Failed", "error");
        }
      );
    } else {
      setError("Please Select business process");
    }
  };
  const [deleteModal, setDeleteModal] = useState(false);
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);

  const handleDelete = async () => {
    try {
      const response = await http.delete(
        `${baseURL}/excel-upload/business-impact/${id}`
      );
      swal("Success", "It has been deleted successfully.", "success");
      fetchData();
      toggleDeleteModal();
    } catch (err) {
      swal("Error", "Something went wrong, please try again.", "error");
    }
  };

  const DisplayData = datas
    ?.slice(pagesVisited, pagesVisited + usersPerPage)
    ?.map((asset) => {
      return (
        <tr>
          <td>{asset.hierarchy}</td>
          <td>{asset.business_assets}</td>
          <td>{asset.asset_name}</td>
          <td>{asset.vendors}</td>
          <td>{asset.product}</td>
          <td>{asset.version}</td>
          <td>{asset.cpe}</td>
          <td>{asset.asset_type}</td>
          <td>{asset.asset_categorization}</td>
          <td>{asset.regulations}</td>
          <td>{asset.asset_risk}</td>
          <td className="flex">
            <IconButton
              onClick={() => {
                setAction("edit");
                setId(asset.id);
                form.hierarchy = asset.hierarchy;
                form.business_assets = asset.business_assets;
                form.asset_name = asset.asset_name;
                form.vendors = asset.vendors;
                form.product = asset.product;
                form.version = asset.version;
                form.cpe = asset.cpe;
                form.asset_type = asset.asset_type;
                form.asset_categorization = asset.asset_categorization;
                form.regulations = asset.regulations;
                form.asset_risk = asset.asset_risk;
                setSelectedBusinessProcessId(asset?.business_process?.id);
                handleModalShowHideMe();
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setId(asset.id);
                toggleDeleteModal();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </td>
        </tr>
      );
    });
  return (
    <>
      <div className="">
        {deleteModal ? (
          <DeleteModal
            handleDelete={handleDelete}
            handleModal={toggleDeleteModal}
          />
        ) : null}
        <div className=" p-10">
          <div className="table-title">
            <div className="flex justify-between items-center space-x-4">
              <span>Business Impact Analysis </span>
              <div className="relative flex justify-center items-center p-1 space-x-2 rounded-md">
                <div>
                  <div className="flex justify-end items-end w-56">
                    <select
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      id="threats"
                      onChange={(e) => {
                        setSelectedBusinessProcessId(e.target.value);
                      }}
                    >
                      <option>Select Business Process</option>
                      {items?.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>{" "}
            </div>
            <button
              onClick={() => toggleFileUploader()}
              className="flex justify-center items-center btn_file_upload"
            >
              <img
                alt="upload file"
                className="w-7 h-7"
                src="https://cdn0.iconfinder.com/data/icons/upload-download-files/128/file_xlsx_excel_document_upload-27-512.png"
              />
              Upload File
            </button>
            <div className="flex justify-center space-x-4 items-center">
              <div className="btn_file_download">
                <svg
                  onClick={() =>
                    downloadExcelData(datas, "Business Impact Analysis")
                  }
                  xmlns="http://www.w3.org/2000/svg"
                  height={28}
                  width={28}
                  fill-rule="evenodd"
                  fill="white"
                  clip-rule="evenodd"
                  image-rendering="optimizeQuality"
                  shape-rendering="geometricPrecision"
                  text-rendering="geometricPrecision"
                  viewBox="0 0 17639 17639"
                >
                  <path
                    fill="#1e7145"
                    d="M9596 3634v10265l-6103-1058V4692l6103-1058zm290 1185h4053c224 0 267 44 267 267v7360c0 223-43 267-267 267H9886v-694h1547v-1066H9886v-320h1547V9566H9886v-320h1547V8179H9886v-320h1547V6793H9886v-320h1547V5406H9886v-587zM2083 15593h13474l2-13476H2081l2 13476z"
                  />
                  <path
                    fill="#1e7145"
                    d="m7129 6861-556 1272-432-1195-695 31 690 1741-777 1703 683 51 548-1225 510 1296 786 43-852-1875 815-1894zM11753 12019h1760v-1066h-1760zM11753 10633h1760V9566h-1760zM11753 9246h1760V8179h-1760zM11753 6473h1760V5406h-1760zM11753 7859h1760V6793h-1760z"
                  />
                </svg>
              </div>
              <div className="btn-add-new">
                <button
                  onClick={() => {
                    setAction("add");
                    clearAll();
                    handleModalShowHideMe();
                  }}
                >
                  <AddIcon className="bold" />
                </button>
              </div>
            </div>
          </div>
          {fetchLoading ? (
            <LoadingComponent animation="border" role="status">
              <span className="">Loading...</span>
            </LoadingComponent>
          ) : (
            <>
              {isNullData ? (
                <div className="alert alert-warning" role="alert">
                  Business process {businessProcessName.name} doesn't have
                  business impact analysis data at the moment, consider
                  uploading new excel file or add single data.
                </div>
              ) : (
                <div className="overflow-x-auto">
                  <table class="tables">
                    <thead className="">
                      <tr>
                        <th className="">Hierarchy</th>
                        <th className="">Business Assets</th>
                        <th className="">Asset Name</th>
                        <th className="">Vendors</th>
                        <th className="">Product</th>
                        <th className="">Version</th>
                        <th className="">CPE</th>
                        <th className="">Asset Type</th>
                        <th className="">Asset Categorization</th>
                        <th className="">Regulations</th>
                        <th className="">Asset Risk</th>
                        <th className="">Manage</th>
                      </tr>
                    </thead>
                    <tbody>{DisplayData}</tbody>
                  </table>
                  <div className="">
                    {datas.length > 8 ? (
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        activeClassName={"paginationActive"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"paginationBttns"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"paginationDisabled"}
                      />
                    ) : null}
                  </div>
                  <div className="flex  p-4 justify-end items-center">
                    <div className="flex space-x-2">
                      <div className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6 text-green-200"
                          fill="#90ee90"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                          />
                        </svg>
                        <span className="">Very Low</span>
                      </div>
                      <div className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6 text-green-500"
                          fill="green"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                          />
                        </svg>
                        <span className="">Low</span>
                      </div>
                      <div className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6 text-yellow-100"
                          fill="yellow"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                          />
                        </svg>
                        <span className="">Moderate</span>
                      </div>
                      <div className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6 text-yellow-500"
                          fill="orange"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                          />
                        </svg>
                        <span className="">High</span>
                      </div>
                      <div className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6 text-red-800"
                          fill="maroon"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                          />
                        </svg>
                        <span className="">Critical</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        <Modal show={showHideMe} size="xl">
          <Modal.Header onClick={() => handleModalShowHideMe()}>
            <Modal.Title>
              {action === "add" ? "Add" : "Edit"} Business Impact Record
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
          // style={{
          // 	maxHeight: "calc(100vh - 210px)",
          // 	overflowY: "auto",
          // }}
          >
            <div className="modal-three-columns">
              <Form.Group class="mb-6">
                <Form.Label
                  for="text"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Select Business Process
                </Form.Label>
                <Form.Control
                  as="select"
                  value={form.selected_business_process}
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="hierarchy..."
                  isInvalid={!!errors.selected_business_process}
                  required
                  onChange={(e) => {
                    setField("selected_business_process", e.target.value);
                    // dispatch(pid(e.target.value));
                  }}
                >
                  <option value="">Select...</option>
                  {items?.map((x, y) => (
                    <option value={x.id}>{x.name}</option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.selected_business_process}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group class="mb-6">
                <Form.Label
                  for="text"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Hierarchy
                </Form.Label>
                <Form.Control
                  type="text"
                  id="email"
                  value={form.hierarchy}
                  onChange={(e) => setField("hierarchy", e.target.value)}
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="hierarchy..."
                  isInvalid={!!errors.hierarchy}
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.hierarchy}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group class="mb-6">
                <Form.Label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Business Asset
                </Form.Label>
                <Form.Control
                  type="text"
                  id="email"
                  value={form.business_assets}
                  onChange={(e) => setField("business_assets", e.target.value)}
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="business assets..."
                  isInvalid={!!errors.business_assets}
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.business_assets}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group class="mb-6">
                <Form.Label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Asset Name
                </Form.Label>
                <Form.Control
                  type="text"
                  id="email"
                  value={form.asset_name}
                  onChange={(e) => setField("asset_name", e.target.value)}
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="asset name..."
                  isInvalid={!!errors.asset_name}
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.asset_name}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group class="mb-6">
                <Form.Label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Vendors
                </Form.Label>
                <Form.Control
                  type="text"
                  id="email"
                  value={form.vendors}
                  onChange={(e) => setField("vendors", e.target.value)}
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="vendors..."
                  isInvalid={!!errors.vendors}
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.vendors}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group class="mb-6">
                <Form.Label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Product
                </Form.Label>
                <Form.Control
                  type="text"
                  id="email"
                  value={form.product}
                  onChange={(e) => setField("product", e.target.value)}
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="product..."
                  isInvalid={!!errors.product}
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.product}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group class="mb-6">
                <Form.Label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Version
                </Form.Label>
                <Form.Control
                  type="text"
                  id="email"
                  value={form.version}
                  onChange={(e) => setField("version", e.target.value)}
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="version..."
                  isInvalid={!!errors.version}
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.version}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group class="mb-6">
                <Form.Label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  CPE
                </Form.Label>
                <Form.Control
                  type="text"
                  id="email"
                  value={form.cpe}
                  onChange={(e) => setField("cpe", e.target.value)}
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="cpe..."
                  isInvalid={!!errors.cpe}
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.cpe}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group class="mb-6">
                <Form.Label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Asset Type
                </Form.Label>
                <Form.Control
                  type="text"
                  id="email"
                  value={form.asset_type}
                  onChange={(e) => setField("asset_type", e.target.value)}
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="asset type..."
                  isInvalid={!!errors.asset_type}
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.asset_type}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group class="mb-6">
                <Form.Label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Asset Categorization
                </Form.Label>
                <Form.Control
                  type="text"
                  id="email"
                  value={form.asset_categorization}
                  onChange={(e) =>
                    setField("asset_categorization", e.target.value)
                  }
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="asset categorization..."
                  isInvalid={!!errors.asset_categorization}
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.asset_categorization}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group class="mb-6">
                <Form.Label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Regulation
                </Form.Label>
                <Form.Control
                  type="text"
                  id="email"
                  value={form.regulations}
                  onChange={(e) => setField("regulations", e.target.value)}
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="regulations..."
                  isInvalid={!!errors.regulations}
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.regulations}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group class="mb-6">
                <Form.Label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Asset Risk
                </Form.Label>
                <Form.Control
                  type="text"
                  id="email"
                  value={form.asset_risk}
                  onChange={(e) => setField("asset_risk", e.target.value)}
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="asset risk..."
                  isInvalid={!!errors.asset_risk}
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.asset_risk}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </Modal.Body>
          {action === "edit" ? (
            <Modal.Footer>
              <Button
                className="btn-cancel"
                onClick={() => handleModalShowHideMe()}
              >
                Close
              </Button>
              <Button
                className="btn-add-new"
                onClick={() => {
                  http
                    .patch(`${baseURL}/excel-upload/business-impact/${id}`, {
                      asset_categorization: form.asset_categorization,
                      asset_name: form.asset_name,
                      asset_hierarchy: form.hierarchy,
                      asset_risk: form.asset_risk,
                      asset_type: form.asset_type,
                      business_assets: form.business_assets,
                      cpe: form.cpe,
                      domain: "domain",
                      product: form.product,
                      regulations: form.regulations,
                      vendors: form.vendors,
                      version: form.version,
                      business_process: selectedBusinessProcessId,
                    })
                    .then((response) => {
                      handleModalShowHideMe();
                      swal(
                        "Success!",
                        "Record edited Successfully!",
                        "success"
                      );
                      fetchBIA();
                      fetchData();
                    })
                    .catch((err) => {
                      swal(err);
                    });
                }}
              >
                Save Changes
              </Button>{" "}
            </Modal.Footer>
          ) : (
            <Modal.Footer>
              <Button
                className="btn-cancel"
                onClick={() => handleModalShowHideMe()}
              >
                Close
              </Button>
              <Button className="btn-add-new" onClick={(e) => submit(e)}>
                Add
              </Button>{" "}
            </Modal.Footer>
          )}
        </Modal>
        {showFileUploader ? (
          <div
            id="defaultModal"
            class="flex justify-center items-center fixed top-0 left-0 right-0 z-50  w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
          >
            <div class="relative w-full max-w-2xl max-h-full">
              <div class="relative bg-gray-200 rounded-lg shadow dark:bg-gray-700">
                <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                  <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                    Upload File
                  </h3>
                  <button
                    onClick={() => toggleFileUploader()}
                    type="button"
                    class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-hide="defaultModal"
                  >
                    <svg
                      class="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span class="sr-only">Close modal</span>
                  </button>
                </div>
                <div class="p-6 space-y-6">
                  <div class="search_categories w-96">
                    <div class="select">
                      <select
                        className="text-gray-200 bg-gray-400"
                        value={business_process}
                        onChange={(e) => {
                          setBusinessProcess(e.target.value);
                          dispatch(pid(e.target.value));
                        }}
                      >
                        <option value={0}>Select Business Process</option>
                        {data?.map((x, y) => (
                          <option value={x.id}>{x.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {business_process <= 0 ? (
                    <p className="text-red-500 mx-10">{error}</p>
                  ) : null}

                  <div>
                    <div class="flex items-center justify-center w-full">
                      <label
                        for="dropzone-file"
                        class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                      >
                        {loading ? <FileUploadLoading /> : null}
                        <div class="flex flex-col items-center justify-center pt-5 pb-6">
                          <svg
                            class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 16"
                          >
                            <path
                              stroke="currentColor"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                            />
                          </svg>
                          <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                            <span class="font-semibold">Click to upload</span>{" "}
                            or drag and drop
                          </p>
                          <p class="text-xs text-gray-500 dark:text-gray-400">
                            .XLSX (files only)
                          </p>
                        </div>
                        {!loading ? (
                          <input
                            id="dropzone-file"
                            type="file"
                            onChange={(e) => handleFile(e)}
                            class=""
                          />
                        ) : null}
                        <p className={`${file ? "sr-only" : "text-red-500"} `}>
                          {fileChosen}
                        </p>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="flex justify-end items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                  <button
                    onClick={(e) =>
                      file
                        ? handleUploadFileAppend(e)
                        : setFileChosen("Please Choose File")
                    }
                    data-modal-hide="defaultModal"
                    type="button"
                    class="border  bg  focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  >
                    Append File
                  </button>
                  <button
                    onClick={(e) =>
                      file
                        ? handleUploadFile(e)
                        : setFileChosen("Please Choose File")
                    }
                    data-modal-hide="defaultModal"
                    type="button"
                    class="text-gray-500 button hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  >
                    Replace File
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ClientAdminHomePage;
