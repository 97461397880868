import React from "react";
import Chart from "react-apexcharts";
import { capitalizeFirstLetter } from "./../../utils/helpers";
import { getApplicationRiskProfilColor } from "../../utils/getBackgroundColor"; // Assuming getBackgroundColor provides color generation

const AppLicationHigherClassificationLevel = ({ data, darkMode }) => {
	// Function to generate random colors with lower saturation and brightness
	const generateRandomColor = () => {
		const randomHue = Math.floor(Math.random() * 360); // Random hue value
		const randomSaturation = Math.floor(Math.random() * 50) + 50; // Random saturation value between 50 and 100
		const randomLightness = Math.floor(Math.random() * 30) + 50; // Random lightness value between 50 and 80
		return `hsl(${randomHue}, ${randomSaturation}%, ${randomLightness}%)`;
	};
	function countLists(data) {
		const keysArray = [];
		const countsArray = [];

		for (const key in data) {
			keysArray.push(key);
			countsArray.push(data[key].length);
		}

		return { keys: keysArray, counts: countsArray };
	}
	// Calculate counts, labels, and generate random colors
	const { counts, keys } = countLists(data?.classification_level);
	const labels = keys.map((label) => capitalizeFirstLetter(label));
	const colors = keys.map(() => generateRandomColor());
	console.log(countLists(data?.classification_level), "classifi");
	return (
		<div className="p-0 z-0">
			<Chart
				type="donut"
				width={400}
				height={250}
				series={counts}
				options={{
					labels: labels,
					colors: [
						"var(--color-chart-one)",
						"var(--color-chart-two)",
						"var(--color-chart-three)",
						"var(--color-chart-four)",
						"var(--color-chart-five)",
						"var(--color-chart-six)",
					],

					stroke: {
						width: 0,
					},

					dataLabels: {
						style: {
							textColor: "white",
							fontSize: "14px",
							fontFamily: "Helvetica, Arial, sans-serif",
							fontWeight: "bold",
						},
						enabled: true,
						formatter: function (val, opts) {
							/* Format labels here */
							return opts.w.config.series[opts.seriesIndex];
						},
					},
					//start
					responsive: [
						{
							breakpoint: 535,
							options: {
								chart: {
									width: 330,
								},
								legend: {
									offsetY: -13,
									offsetX: 0,
								},
								dataLabels: {
									style: {
										fontSize: "14px",
										fontWeight: "normal",
									},
								},
							},
						},
					],
					//finish
					legend: {
						fontSize: "14px",
						fontWeight: "bold",
						itemMargin: "left",
						labels: {
							colors: darkMode ? "#979ea5" : "black",
						},
					},
				}}
			/>
		</div>
	);
};

export default AppLicationHigherClassificationLevel;
