import React from "react";
import ThemeController from "../../../context/ThemeController";
import {
	MdOutlineAdminPanelSettings,
	MdOutlineLockReset,
} from "react-icons/md";
import { BiLogOutCircle } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import UserNameIcon from "../../../utils/UserNameIcon";

const ClientAdminNavbarItems = [
	{
		id: "threatRepository",
		name: "Threat Repository",
		link: "/ca/threat_vectors",
	},
	{
		id: "threatModeling",
		name: "Threat Modeling",
		link: "/ca/threat_modeling",
	},
	{
		id: "businessProcess",
		name: "Business Process",
		link: "/ca/manage_business_impact",
	},
	{
		id: "manageFile",
		name: "Manage File",
		link: "#",
		dropdown: [
			{
				id: "businessImpactAnalysis",
				name: "Business Impact Analysis",
				link: "/ca/manage_file/adminhome",
			},
			{
				id: "exploitablityPaths",
				name: "Exploitablity Paths",
				link: "/ca/manage_file/exploitability_paths",
			},
			{
				id: "controlAnalysis",
				name: "Control Analysis",
				link: "/ca/manage_file/control_analysis",
			},
		],
	},

	{
		id: "UserNameIconCA",
		name: <UserNameIcon />,

		link: "#",
		dropdown: [
			{
				name: (
					<>
						<MdOutlineAdminPanelSettings className="inline-block" /> Switch to
						Client
					</>
				),

				link: "/",
			},
			{
				name: (
					<>
						<ThemeController />
					</>
				),
				link: "#",
			},
			{
				name: (
					<>
						<CgProfile className="inline-block" /> Profile
					</>
				),
				link: "#",
			},
			{
				name: (
					<>
						<ContactSupportIcon className="inline-block" /> Support
					</>
				),
				link: "/client_support",
			},
			{
				name: (
					<>
						<MdOutlineLockReset className="inline-block" /> Reset Password
					</>
				),
				link: "/change_my_password",
			},
			{
				name: (
					<>
						<BiLogOutCircle className="inline-block" /> Logout
					</>
				),

				link: "/signout",
			},
		],
	},
];

export default ClientAdminNavbarItems;
