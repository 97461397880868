// threatCatalog.js

export const THREAT_CATALOG_ENDPOINT = "/threat-catalog/threat-catalog";
export const CATALOG_DETAIL_ENDPOINT = "/threat-catalog/catalog_detail";
export const CATALOG_DELETE_ENDPOINT = "/threat-catalog/catalog_delete";
export const CATALOG_LIST_ENDPOINT = "/threat-catalog/catalog-list";
export const THREAT_CATEGORIES_ENDPOINT = "/threat-catalog/threat-categories";
export const THREAT_CATEGORY_BY_PK_ENDPOINT =
  "/threat-catalog/threat-category/:pk";
export const DISCOVERABILITY_LEVELS_ENDPOINT =
  "/threat-catalog/discoverability-levels";
export const DISCOVERABILITY_LEVEL_BY_PK_ENDPOINT =
  "/threat-catalog/discoverability-level/:pk";
export const EXPLOITABILITY_LEVELS_ENDPOINT =
  "/threat-catalog/exploitability-levels";
export const EXPLOITABILITY_LEVEL_BY_PK_ENDPOINT =
  "/threat-catalog/exploitability-level/:pk";
export const REPRODUCIBILITIES_ENDPOINT = "/threat-catalog/reproducibilities";
export const REPRODUCIBILITY_BY_PK_ENDPOINT =
  "/threat-catalog/reproducibility/:pk";
export const CONFIDENTIALITY_LEVELS_ENDPOINT =
  "/threat-catalog/confidentiality-levels";
export const CONFIDENTIALITY_LEVEL_BY_PK_ENDPOINT =
  "/threat-catalog/confidentiality-level/:pk";
export const INTEGRITY_LEVELS_ENDPOINT = "/threat-catalog/integrity-levels";
export const INTEGRITY_LEVEL_BY_PK_ENDPOINT =
  "/threat-catalog/integrity-level/:pk";
export const AVAILABILITY_LEVELS_ENDPOINT =
  "/threat-catalog/availability-levels";
export const AVAILABILITY_LEVEL_BY_PK_ENDPOINT =
  "/threat-catalog/availability-level/:pk";
export const THREAT_VECTORS_ENDPOINT = "/threat-catalog/threat-vectors";
export const THREAT_VECTOR_BY_PK_ENDPOINT = "/threat-catalog/threat-vector/:pk";
export const CONTROL_ANALYSIS_ENDPOINT = "/threat-catalog/control-analysis";
export const CONTROL_ANALYSIS_BY_PK_ENDPOINT =
  "/threat-catalog/control-analysis/:pk";
export const CLIENT_CONTROLS_CATALOG_ENDPOINT =
  "threat-catalog/client-controls-catalog";
export const CLIENT_CONTROLS_CATALOG_BY_PK_ENDPOINT =
  "threat-catalog/client-controls-catalog";
