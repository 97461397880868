import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FilterListIcon from "@mui/icons-material/FilterList";
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import {
  THREAT_VECTORS_LIST,
  UPDATE_THREAT_VECTOR,
} from "../api/threatCatalog";
import { useApi } from "../hooks/useApis";
import MoreButton from "../layout/components/MoreButton";
import { baseURL } from "../resources/apiClient";
import http from "../resources/http";
import SearchInput from "../shared/SearchInput/SearchInput";
import ThreatRepositoryModal from "../shared/ThreatRepositoryModal/ThreatRepositoryModal";
import { DeleteModal } from "../shared/DeleteModal";
import { useSortableData } from "../utils/useSortableData";

const ThreatVector = () => {
  const [action, setAction] = useState("");
  const [id, setId] = useState();
  const [record, setRecord] = useState({});
  const [available, setAvailable] = useState([]);
  const [discover, setDiscover] = useState([]);
  const [exploit, setExploit] = useState([]);
  const [confident, setConfident] = useState([]);
  const [integrities, setIntegrities] = useState([]);
  const [threatVectors, setThreatVectors] = useState({}); //threat vectors
  const [reproduce, setReproduce] = useState([]);
  const [sensetivityLevels, setSensetivityLevels] = useState([]);

  const [showHide, setSetShowHide] = useState(false);
  const [showHideMe, setSetShowHideMe] = useState(false);
  const [showHideDelete, setSetShowHideDelete] = useState(false);
  const { data, isLoading, fetchData, isError } = useApi(
    `${baseURL}/threat-catalog/threat-vectors`
  ); // Replace with your API endpoint
  const [category, setCategory] = useState();
  const [searchValue, setSearchValue] = useState("");

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;

  const filteredData = data?.filter(
    (row) =>
      row?.name?.match(new RegExp(searchValue, "i")) ||
      row?.threat_senario?.match(new RegExp(searchValue, "i")) ||
      row?.description?.match(new RegExp(searchValue, "i"))
  );
  const pageCount = Math.ceil(filteredData?.length / usersPerPage);
  const [details, setDetails] = useState(false);
  const [showThreatScenario, setShowThreatScenario] = useState(false);

  const [errors, setErrors] = useState({}); //errors
  let numberFormat = new Intl.NumberFormat("en-US");

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const handleInputChange = (newValue) => {
    setSearchValue(newValue);
    setPageNumber(0); // Reset to the first page when search value changes
  };
  const fetchDatas = () => {
    http
      .get(`${baseURL}/threat-catalog/threat-categories`)
      .then((response) => {
        setCategory(response.data);
      })
      .then(
        (response) => {},
        (err) => {
          console.log(err);
        }
      );
    http
      .get(`${baseURL}/threat-catalog/discoverability-levels`)
      .then((response) => {
        setDiscover(response.data);
      })
      .then(
        (response) => {},
        (err) => {
          console.log(err);
        }
      );
    http
      .get(`${baseURL}/threat-catalog/exploitablity-levels`)
      .then((response) => {
        setExploit(response.data);
      })
      .then(
        (response) => {},
        (err) => {
          console.log(err);
        }
      );
    http
      .get(`${baseURL}/threat-catalog/reproducibilities`)
      .then((response) => {
        setReproduce(response.data);
      })
      .then(
        (response) => {},
        (err) => {
          console.log(err);
        }
      );
    http
      .get(`${baseURL}/threat-catalog/availability-levels`)
      .then((response) => {
        setAvailable(response.data);
      })
      .then(
        (response) => {},
        (err) => {
          console.log(err);
        }
      );
    http
      .get(`${baseURL}/threat-catalog/integrity-levels`)
      .then((response) => {
        setIntegrities(response.data);
      })
      .then(
        (response) => {},
        (err) => {
          console.log(err);
        }
      );
    http
      .get(`${baseURL}/threat-catalog/confidentiality-levels`)
      .then((response) => {
        setConfident(response.data);
      })
      .then(
        (response) => {},
        (err) => {
          console.log(err);
        }
      );
    http
      .get(`${baseURL}/risk/risk-sensitivity-levels`)
      .then((response) => {
        setSensetivityLevels(response.data);
      })
      .then(
        (response) => {},
        (err) => {
          console.log(err);
        }
      );
  };
  useEffect(() => {
    fetchDatas();
  }, []);

  const [sensFilter, setRadioFilter] = useState("High");
  const [scenarioText, setScenarioText] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);

  const [loading, setLoading] = useState(true);

  const togglePoUp = () => setShowPopUp(!showPopUp);
  const handleModalShowHide = () => setSetShowHide(!showHide);
  const handleModalShowHideMe = () => setSetShowHideMe(!showHideMe);
  const handleModalShowHideDelete = () => setSetShowHideDelete(!showHideDelete);
  const toggleThreatScenario = () => setShowThreatScenario(true);

  const setField = (field, value) => {
    setThreatVectors({
      ...threatVectors,
      [field]: value,
    });
    if (!!errors[field])
      //if there is an error add the error to errors object
      setErrors({
        ...errors,
        [field]: null,
      });
  };
  const validateThreatRepository = () => {
    //declare the variables and assign the values from the asset object
    const {
      name = "",
      threatCategory = "",
      discoverability = "",
      exploitability = "",
      reproducibility = "",
      confidentiality = "",
      availability = "",
      integrity = "",
      threatVectorIncident = "",
      costOfIncident = "",
      costOfBreach = "",
      lostOfBusinessCost = "",
      threatDescription = "",
      threatScenario = "",
    } = threatVectors;
    const newErrors = {};

    if (!name || name === "" || name === "Select...")
      newErrors.name = "Threat Vector is require.";

    if (
      !threatCategory ||
      threatCategory === "" ||
      threatCategory === "Select..."
    )
      newErrors.threatCategory = "Threat Category is require.";

    if (
      !discoverability ||
      discoverability === "" ||
      discoverability === "Select..."
    )
      newErrors.discoverability = "Discoverability is require";

    if (
      !exploitability ||
      exploitability === "" ||
      exploitability === "Select..."
    )
      newErrors.exploitability = "Exploitability is require.";

    if (
      !reproducibility ||
      reproducibility === "" ||
      reproducibility === "Select..."
    )
      newErrors.reproducibility = "Reproducibility is require.";

    if (
      !confidentiality ||
      confidentiality === "" ||
      confidentiality === "Select..."
    )
      newErrors.confidentiality = "Confidentiality is require.";

    if (!integrity || integrity === "" || integrity === "Select...")
      newErrors.integrity = "Integrity is require.";

    if (!availability || availability === "" || availability === "Select...")
      newErrors.availability = "Availability is require.";

    return newErrors;
  };
  const clearFields = () => {
    threatVectors.name = "";
    threatVectors.threatCategory = "";
    threatVectors.discoverability = "";
    threatVectors.exploitability = "";
    threatVectors.reproducibility = "";
    threatVectors.confidentiality = "";
    threatVectors.availability = "";
    threatVectors.integrity = "";
    threatVectors.threatVectorIncident = "";
    threatVectors.costOfIncident = "";
    threatVectors.costOfBreach = "";
    threatVectors.lostOfBusinessCost = "";
    threatVectors.threatDescription = "";
    threatVectors.threatScenario = "";
  };
  const submit = (e) => {
    e.preventDefault();
    const assetErrors = validateThreatRepository();
    if (Object.keys(assetErrors).length > 0) {
      setErrors(assetErrors);
    } else {
      http
        .post(`${baseURL}/threat-catalog/threat-vectors/`, {
          name: threatVectors.name,
          category: threatVectors.threatCategory,
          discoverability: threatVectors.discoverability,
          exploitablity: threatVectors.exploitability,
          reproducibility: threatVectors.reproducibility,
          confidentiality: threatVectors.confidentiality,
          integrity: threatVectors.integrity,
          availability: threatVectors.availability,
          event_frequency: threatVectors.threatVectorIncident
            ? threatVectors.threatVectorIncident
            : 0,
          cost_of_incident: threatVectors.costOfIncident
            ? threatVectors.costOfIncident
            : 0,
          cost_of_breach: threatVectors.costOfBreach
            ? threatVectors.costOfBreach
            : 0,
          percent_of_breach: threatVectors.percentOfBreach
            ? threatVectors.percentOfBreach
            : 0,
          lost_business_cost: threatVectors.lostOfBusinessCost
            ? threatVectors.lostOfBusinessCost
            : 0,
          description: threatVectors.threatDescription,
          threat_senario: threatVectors.threatScenario,
        })
        .then((res) => {
          handleModalShowHide();
          fetchData();
          swal("Success", "It has been added successfully", "success");
          clearFields();
        })
        .catch((err) => {
          swal("Error", "Something went wrong, please try again.", "error");
        });
    }
  };
  const update = (e) => {
    e.preventDefault();
    const assetErrors = validateThreatRepository();
    if (Object.keys(assetErrors).length > 0) {
      setErrors(assetErrors);
    } else {
      http
        .patch(`${baseURL}/threat-catalog/threat-vector/${id}`, {
          name: threatVectors.name,
          category: threatVectors.threatCategory,
          discoverability: threatVectors.discoverability,
          exploitablity: threatVectors.exploitability,
          reproducibility: threatVectors.reproducibility,
          confidentiality: threatVectors.confidentiality,
          integrity: threatVectors.integrity,
          availability: threatVectors.availability,
          event_frequency: threatVectors.threatVectorIncident
            ? threatVectors.threatVectorIncident
            : 0,
          cost_of_incident: threatVectors.costOfIncident
            ? threatVectors.costOfIncident
            : 0,
          cost_of_breach: threatVectors.costOfBreach
            ? threatVectors.costOfBreach
            : 0,
          percent_of_breach: threatVectors.percentOfBreach
            ? threatVectors.percentOfBreach
            : 0,
          lost_business_cost: threatVectors.lostOfBusinessCost
            ? threatVectors.lostOfBusinessCost
            : 0,
          description: threatVectors.threatDescription,
          threat_senario: threatVectors.threatScenario,
        })
        .then((res) => {
          fetchData();
          handleModalShowHide();
          swal("Success", "It has been updated successfully", "success");
        })
        .catch((err) => {
          swal("Error", "Something went wrong, please try again.", "error");
        });
    }
  };
  //sort table
  const { items, requestSort, sortConfig } = useSortableData(data);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }

    return sortConfig.key === name
      ? sortConfig.direction
      : sortConfig.direction;
  };
  const [deleteModal, setDeleteModal] = useState(false);
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);

  const handleDelete = async () => {
    try {
      const response = await http.delete(
        `${baseURL}/threat-catalog/threat-vector/${id}`
      );
      swal("Success", "It has been deleted successfully.", "success");
      fetchData();
      toggleDeleteModal();
    } catch (err) {
      swal("Error", "Something went wrong, please try again.", "error");
    }
  };

  const DisplayData = filteredData
    // ?.filter((row) => row?.client === null)
    ?.filter((row) => row?.name?.match(new RegExp(searchValue, "i")))
    ?.slice(pagesVisited, pagesVisited + usersPerPage)
    ?.map((vectors) => {
      return (
        <>
          <tr key={vectors.id}>
            <td className="">{vectors.name}</td>
            <td>
              {vectors.description ? (
                <div className="w-52">
                  <MoreButton
                    description={vectors.description}
                    stringLength={25}
                    setText={setScenarioText}
                    modalFunc={handleModalShowHideDelete}
                  />
                </div>
              ) : (
                "N/A"
              )}
            </td>
            <td>
              {vectors.threat_senario ? (
                <div className="w-52">
                  <MoreButton
                    description={vectors.threat_senario}
                    stringLength={25}
                    setText={setScenarioText}
                    modalFunc={handleModalShowHideDelete}
                  />
                </div>
              ) : (
                "N/A"
              )}
            </td>

            <td className="text-center">{vectors.event_frequency}</td>
            <td className="text-center">
              {numberFormat.format(vectors.cost_of_incident)}
            </td>
            <td className="text-center">
              {numberFormat.format(vectors.cost_of_breach)}
            </td>
            <td className="text-white font-semibold">
              <p
                className="p-3 text-center rounded-xl"
                style={{
                  backgroundColor:
                    vectors.threat_severity.severity.High === "low" &&
                    sensFilter === "High"
                      ? "green"
                      : vectors.threat_severity.severity.High === "very_low" &&
                        sensFilter === "High"
                      ? "#90ee90"
                      : vectors.threat_severity.severity.High === "moderate" &&
                        sensFilter === "High"
                      ? "yellow"
                      : vectors.threat_severity.severity.High === "high" &&
                        sensFilter === "High"
                      ? "orange"
                      : vectors.threat_severity.severity.High === "critical" &&
                        sensFilter === "High"
                      ? "maroon"
                      : vectors.threat_severity.severity.Medium === "low" &&
                        sensFilter === "Medium"
                      ? "green"
                      : vectors.threat_severity.severity.Medium ===
                          "very_low" && sensFilter === "Medium"
                      ? "#90ee90"
                      : vectors.threat_severity.severity.Medium ===
                          "moderate" && sensFilter === "Medium"
                      ? "yellow"
                      : vectors.threat_severity.severity.Medium === "high" &&
                        sensFilter === "Medium"
                      ? "orange"
                      : vectors.threat_severity.severity.Medium ===
                          "critical" && sensFilter === "Medium"
                      ? "maroon"
                      : vectors.threat_severity.severity.Low === "low" &&
                        sensFilter === "Low"
                      ? "green"
                      : vectors.threat_severity.severity.Low === "very_low" &&
                        sensFilter === "Low"
                      ? "#90ee90"
                      : vectors.threat_severity.severity.Low === "moderate" &&
                        sensFilter === "Low"
                      ? "yellow"
                      : vectors.threat_severity.severity.Low === "high" &&
                        sensFilter === "Low"
                      ? "orange"
                      : vectors.threat_severity.severity.Low === "critical" &&
                        sensFilter === "Low"
                      ? "maroon"
                      : sensFilter === "extra high"
                      ? "maroon"
                      : "gray",
                  color: "black",
                }}
              ></p>
            </td>
            <td className="text-center">
              <IconButton
                onClick={() => {
                  setId(vectors.id);
                  setAction("edit");
                  setErrors({});
                  handleModalShowHide();
                  setRecord(vectors);
                  threatVectors.name = vectors.name;
                  threatVectors.threatCategory = vectors.category;
                  threatVectors.discoverability = vectors.discoverability;
                  threatVectors.exploitability = vectors.exploitablity;
                  threatVectors.reproducibility = vectors.reproducibility;
                  threatVectors.confidentiality = vectors.confidentiality;
                  threatVectors.integrity = vectors.integrity;
                  threatVectors.availability = vectors.availability;
                  threatVectors.threatVectorIncident = vectors.event_frequency;
                  threatVectors.costOfIncident = vectors.cost_of_incident;
                  threatVectors.costOfBreach = vectors.cost_of_breach;
                  threatVectors.percentOfBreach = vectors.percent_of_breach;
                  threatVectors.lostOfBusinessCost = vectors.lost_business_cost;
                  threatVectors.threatDescription = vectors.description;
                  threatVectors.threatScenario = vectors.threat_senario;
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  setId(vectors.id);
                  toggleDeleteModal();
                }}
              >
                <DeleteIcon />
              </IconButton>
            </td>
          </tr>
        </>
      );
    });
  console.log(data, "DisplayData");

  return (
    <div className="p-10">
      {deleteModal ? (
        <DeleteModal
          handleDelete={handleDelete}
          handleModal={toggleDeleteModal}
        />
      ) : null}
      <div className="table-title flex justify-between">
        <span>Threat Repository</span>{" "}
        <div className="flex justify-center items-center space-x-4">
          <div className=" flex justify-center items-center ">
            <label className="form-check-label inline-block pr-2 ">
              Sensitivity:{" "}
            </label>
            <input
              className="form-check-input  inline-block appearance-none rounded-full h-4 w-4 border border-gray-300  checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200  align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              onClick={() => setRadioFilter(`Low`)}
            />
            <label
              className="form-check-label inline-block pr-2 "
              for="flexRadioDefault1"
            >
              Low
            </label>
            <input
              className="pl-2 form-check-input inline-block appearance-none rounded-full h-4 w-4 border border-gray-300  checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200  align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              onClick={() => setRadioFilter(`Medium`)}
            />
            <label
              className="form-check-label inline-block pr-2"
              for="flexRadioDefault1"
            >
              Medium
            </label>{" "}
            {sensFilter === "High" ? (
              <input
                checked
                className=" form-check-input inline-block appearance-none rounded-full h-4 w-4 border border-gray-300  checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200  align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                onClick={() => setRadioFilter(`High`)}
              />
            ) : (
              <input
                className=" form-check-input inline-block appearance-none rounded-full h-4 w-4 border border-gray-300  checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200  align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                onClick={() => setRadioFilter(`High`)}
              />
            )}
            <label
              className="form-check-label inline-block "
              for="flexRadioDefault1"
            >
              High
            </label>
          </div>
        </div>
        <div className="flex justify-between items-center space-x-4">
          <SearchInput
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
          <Dropdown className="">
            <Dropdown.Toggle className="btn dropdown">
              <FilterListIcon />
            </Dropdown.Toggle>

            <Dropdown.Menu className="mt-2 p-4 w-56 bg-gray-700 text-gray-300 ">
              <Dropdown.Item
                as="button"
                onClick={() => requestSort("name")}
                className={getClassNamesFor("name")}
              >
                Threat Vectors
              </Dropdown.Item>
              {/* <Dropdown.Item
                as="button"
                onClick={() => requestSort('event_frequency')}
                className={getClassNamesFor('event_frequency')}
              >
                Threat Frequency
              </Dropdown.Item> */}
              <Dropdown.Item
                as="button"
                onClick={() => requestSort("cost_of_incident")}
                className={getClassNamesFor("cost_of_incident")}
              >
                Cost of Incident
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                onClick={() => requestSort("cost_of_breach")}
                className={getClassNamesFor("cost_of_breach")}
              >
                Cost of Breach
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                onClick={() => requestSort("threat_rating")}
                className={getClassNamesFor("threat_rating")}
              >
                Threat Severity
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <div className="btn-add-new">
            <button
              onClick={() => {
                clearFields();
                setAction("add");
                handleModalShowHide();
              }}
            >
              <AddIcon className="bold" />
            </button>
          </div>
        </div>
      </div>
      <table className="tables">
        <thead className="sticky-header">
          <tr>
            <th className="text-center" scope="col">
              Threat Vector
            </th>
            <th className="text-center" scope="col">
              Threat Description
            </th>
            <th className="text-center" scope="col">
              Threat Scenario
            </th>
            <th className="text-center" scope="col">
              Annual Threat <br />
              Event Frequency (in %)
            </th>
            <th className="text-center" scope="col">
              Cost of Incident(USD)
            </th>
            <th className="text-center" scope="col">
              Cost of Breach(USD)
            </th>

            <th className="text-center" scope="col">
              Threat Severity and Rating
            </th>

            <th className="text-center" scope="col">
              Manage
            </th>
          </tr>
        </thead>
        <tbody className="">{DisplayData}</tbody>
      </table>
      {data?.length > 8 ? (
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          activeClassName={"paginationActive"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
        />
      ) : null}
      <div className="flex  p-4 justify-end items-center">
        <div className="flex space-x-2">
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 text-green-200"
              fill="#90ee90"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
              />
            </svg>
            <span className="">Very Low</span>
          </div>
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 text-green-500"
              fill="green"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
              />
            </svg>
            <span className="">Low</span>
          </div>
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 text-yellow-100"
              fill="yellow"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
              />
            </svg>
            <span className="">Moderate</span>
          </div>
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 text-yellow-500"
              fill="orange"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
              />
            </svg>
            <span className="">High</span>
          </div>
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 text-red-800"
              fill="maroon"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
              />
            </svg>
            <span className="">Critical</span>
          </div>
        </div>
      </div>
      {showHide && (
        <ThreatRepositoryModal
          action={action}
          handleModalShowHide={handleModalShowHide}
          payload={record}
          url={THREAT_VECTORS_LIST}
          modifyUrl={UPDATE_THREAT_VECTOR}
          fetchData={fetchData}
        />
      )}

      <Modal show={showHideDelete} className="">
        <Modal.Header onClick={() => handleModalShowHideDelete()}>
          <Modal.Title>Scenario Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>{scenarioText}</span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-cancel"
            onClick={() => handleModalShowHideDelete()}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ThreatVector;
