import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import swal from "sweetalert";
import http from "../../../resources/http";
import {
	INTEGRITY_LEVELS,
	INTEGRITY_LEVEL_BY_PK,
} from "../../../api/threatCatalog";
import CustomTable from "../../../shared/CustomTable";
import SearchHandler from "../../../shared/SearchHandler";
import SearchInput from "../../../shared/SearchInput/SearchInput";
import LoadingComponent from "../../../shared/LoadingDropDownSelection/LoadingComponent";
import ModalHandler from "./ModalHandler";

const Integrity = () => {
	const url_create = INTEGRITY_LEVELS;
	const url_by_pk = INTEGRITY_LEVEL_BY_PK;
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [mode, setMode] = useState("add"); // 'add' or 'edit'
	const [selectedItem, setSelectedItem] = useState(null);

	const [id, setId] = useState();
	const [data, setData] = useState([]);
	const [fetchLoading, setFetchLoading] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const fieldsToFilterBy = ["difficulty_level", "value", "description"];

	//data
	async function fetchData() {
		setFetchLoading(true);
		try {
			const response = await http.get(url_create);
			setData(response.data);
			setFetchLoading(false);
		} catch (error) {
			console.error("Error fetching data:", error);
			setFetchLoading(false);
		}
	}

	useEffect(() => {
		fetchData();
	}, []);

	const filteredData = SearchHandler(data, searchValue, fieldsToFilterBy);

	const handleInputChange = (newValue) => {
		setSearchValue(newValue);
	};
	// Handle the edit click from the table
	const handleEdit = (id, item) => {
		setSelectedItem(item);
		setId(id);
		setMode("edit");
		setIsModalOpen(true);
	};

	const handleDelete = async (id) => {
		setFetchLoading(true);
		try {
			await http.delete(`${url_by_pk}${id}`);
			fetchData();
			setFetchLoading(false);
			swal("Success", "It has been deleted successfully", "success");
		} catch (error) {
			swal("Failed", "Failed to delete item", "error");
			setFetchLoading(false);
			console.error("Failed to delete item:", error);
		}
	};
	return (
		<main>
			<div className="main-container">
				<div className="table-title ">
					<span className=" ">Integrity</span>
					<div className="flex justify-between items-center space-x-4">
						<SearchInput
							searchValue={searchValue}
							setSearchValue={handleInputChange}
						/>
						<button
							className="btn-add-new"
							onClick={() => {
								setSelectedItem(null);
								setMode("add");
								setIsModalOpen(true);
							}}
						>
							<AddIcon />
						</button>
					</div>
				</div>
				{/* table */}
				{fetchLoading ? (
					<LoadingComponent />
				) : searchValue.length > 0 && filteredData.length === 0 ? (
					<div className="alert-no-data">
						No data available for
						<span className="search-value">{searchValue}</span>
						at the moment.
					</div>
				) : (
					<CustomTable
						filteredData={filteredData}
						onEditClick={handleEdit}
						onDeleteClick={handleDelete}
					/>
				)}
				{/* add and edit modal */}
				<ModalHandler
					isOpen={isModalOpen}
					onClose={() => setIsModalOpen(false)}
					mode={mode}
					size="xl"
					selectedItemId={id}
					selectedItem={selectedItem}
					header="Integrity"
					url_create={url_create}
					url_by_pk={url_by_pk}
					fetchData={fetchData}
				/>
			</div>
		</main>
	);
};

export default Integrity;
