import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import swal from 'sweetalert';
import { RISK_SENSITIVITY_LEVELS } from '../../api/risk';
import {
  AVAILABILITY_LEVELS,
  CONFIDENTIALITY_LEVELS,
  DISCOVERABILITY_LEVELS,
  EXPLOITABILITY_LEVELS,
  INTEGRITY_LEVELS,
  REPRODUCIBILITIES,
  THREAT_CATEGORIES,
} from '../../api/threatCatalog';
import http from '../../resources/http';
const ThreatRepositoryModal = ({
  action,
  handleModalShowHide,
  payload,
  url,
  modifyUrl,
  fetchData,
}) => {
  const [available, setAvailable] = useState([]);
  const [discover, setDiscover] = useState([]);
  const [exploit, setExploit] = useState([]);
  const [confident, setConfident] = useState([]);
  const [integrities, setIntegrities] = useState([]);
  const [reproduce, setReproduce] = useState([]);
  const [threatVectors, setThreatVectors] = useState({});
  console.log(threatVectors, 'threatVectors');
  const [category, setCategory] = useState();

  const [charCount, setCharCount] = React.useState(0);
  const [scenarioCharCount, setScenarioCharCount] = React.useState(0);
  let charLimit = 3000;

  const [errors, setErrors] = useState({}); //errors
  //format numbers as comma separator

  const fetchDropdownData = () => {
    Promise.all([
      http.get(`${THREAT_CATEGORIES}`),
      http.get(`${DISCOVERABILITY_LEVELS}`),
      http.get(`${EXPLOITABILITY_LEVELS}`),
      http.get(`${REPRODUCIBILITIES}`),
      http.get(`${AVAILABILITY_LEVELS}`),
      http.get(`${INTEGRITY_LEVELS}`),
      http.get(`${CONFIDENTIALITY_LEVELS}`),
      http.get(`${RISK_SENSITIVITY_LEVELS}`),
    ])
      .then(
        ([
          categories,
          discover,
          exploit,
          reproduce,
          available,
          integrities,
          confident,
        ]) => {
          setCategory(categories.data);
          setDiscover(discover.data);
          setExploit(exploit.data);
          setReproduce(reproduce.data);
          setAvailable(available.data);
          setIntegrities(integrities.data);
          setConfident(confident.data);
        }
      )
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchDropdownData();
  }, []);
  console.log(action, 'action');
  const submit = (e) => {
    e.preventDefault();
    const assetErrors = validateThreatRepository();
    if (Object.keys(assetErrors).length > 0) {
      setErrors(assetErrors);
    } else {
      http
        .post(`${url}`, {
          name: threatVectors.name,
          category: threatVectors.threatCategory,
          discoverability: threatVectors.discoverability,
          exploitablity: threatVectors.exploitability,
          reproducibility: threatVectors.reproducibility,
          confidentiality: threatVectors.confidentiality,
          integrity: threatVectors.integrity,
          availability: threatVectors.availability,
          event_frequency: threatVectors.threatVectorIncident
            ? threatVectors.threatVectorIncident
            : 0,
          cost_of_incident: threatVectors.costOfIncident
            ? threatVectors.costOfIncident
            : 0,
          cost_of_breach: threatVectors.costOfBreach
            ? threatVectors.costOfBreach
            : 0,
          percent_of_breach: threatVectors.percentOfBreach
            ? threatVectors.percentOfBreach
            : 0,
          lost_business_cost: threatVectors.lostOfBusinessCost
            ? threatVectors.lostOfBusinessCost
            : 0,
          description: threatVectors.threatDescription,
          threat_senario: threatVectors.threatScenario,
        })
        .then((res) => {
          handleModalShowHide();
          fetchData();
          swal('Success', 'It has been added successfully', 'success');
          clearFields();
        })
        .catch((err) => {
          swal('Error', 'Something went wrong, please try again.', 'error');
        });
    }
  };
  const update = (e) => {
    e.preventDefault();
    const assetErrors = validateThreatRepository();
    if (Object.keys(assetErrors).length > 0) {
      setErrors(assetErrors);
    } else {
      http
        .patch(`${modifyUrl}${payload.id}`, {
          name: threatVectors.name,
          category: threatVectors.threatCategory,
          discoverability: threatVectors.discoverability,
          exploitablity: threatVectors.exploitability,
          reproducibility: threatVectors.reproducibility,
          confidentiality: threatVectors.confidentiality,
          integrity: threatVectors.integrity,
          availability: threatVectors.availability,
          event_frequency: threatVectors.threatVectorIncident
            ? threatVectors.threatVectorIncident
            : 0,
          cost_of_incident: threatVectors.costOfIncident
            ? threatVectors.costOfIncident
            : 0,
          cost_of_breach: threatVectors.costOfBreach
            ? threatVectors.costOfBreach
            : 0,
          percent_of_breach: threatVectors.percentOfBreach
            ? threatVectors.percentOfBreach
            : 0,
          lost_business_cost: threatVectors.lostOfBusinessCost
            ? threatVectors.lostOfBusinessCost
            : 0,
          description: threatVectors.threatDescription,
          threat_senario: threatVectors.threatScenario,
        })
        .then((res) => {
          handleModalShowHide();
          fetchData();
          swal('Success', 'It has been updated successfully', 'success');
        })
        .catch((err) => {
          swal('Error', 'Something went wrong, please try again.', 'error');
        });
    }
  };
  const setField = (field, value) => {
    setThreatVectors({
      ...threatVectors,
      [field]: value,
    });
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const validateThreatRepository = () => {
    const {
      name = '',
      threatCategory = '',
      discoverability = '',
      exploitability = '',
      reproducibility = '',
      confidentiality = '',
      availability = '',
      integrity = '',
      threatVectorIncident = '',
      costOfIncident = '',
      costOfBreach = '',
      lostOfBusinessCost = '',
      threatDescription = '',
      threatScenario = '',
    } = threatVectors;
    const newErrors = {};

    if (!name || name === '' || name === 'Select...')
      newErrors.name = 'Threat Vector is require.';

    if (
      !threatCategory ||
      threatCategory === '' ||
      threatCategory === 'Select...'
    )
      newErrors.threatCategory = 'Threat Category is require.';

    if (
      !discoverability ||
      discoverability === '' ||
      discoverability === 'Select...'
    )
      newErrors.discoverability = 'Discoverability is require';

    if (
      !exploitability ||
      exploitability === '' ||
      exploitability === 'Select...'
    )
      newErrors.exploitability = 'Exploitability is require.';

    if (
      !reproducibility ||
      reproducibility === '' ||
      reproducibility === 'Select...'
    )
      newErrors.reproducibility = 'Reproducibility is require.';

    if (
      !confidentiality ||
      confidentiality === '' ||
      confidentiality === 'Select...'
    )
      newErrors.confidentiality = 'Confidentiality is require.';

    if (!integrity || integrity === '' || integrity === 'Select...')
      newErrors.integrity = 'Integrity is require.';

    if (!availability || availability === '' || availability === 'Select...')
      newErrors.availability = 'Availability is require.';

    return newErrors;
  };
  const clearFields = () => {
    threatVectors.name = '';
    threatVectors.threatCategory = '';
    threatVectors.discoverability = '';
    threatVectors.exploitability = '';
    threatVectors.reproducibility = '';
    threatVectors.confidentiality = '';
    threatVectors.availability = '';
    threatVectors.integrity = '';
    threatVectors.threatVectorIncident = '';
    threatVectors.costOfIncident = '';
    threatVectors.costOfBreach = '';
    threatVectors.lostOfBusinessCost = '';
    threatVectors.threatDescription = '';
    threatVectors.threatScenario = '';
  };
  const initials = () => {
    if (action === 'edit') {
      threatVectors.name = payload?.name;
      threatVectors.threatCategory = payload?.category;
      threatVectors.discoverability = payload?.discoverability;
      threatVectors.exploitability = payload?.exploitablity;
      threatVectors.reproducibility = payload?.reproducibility;
      threatVectors.confidentiality = payload?.confidentiality;
      threatVectors.integrity = payload?.integrity;
      threatVectors.availability = payload?.availability;
      threatVectors.threatVectorIncident = payload?.event_frequency;
      threatVectors.costOfIncident = payload?.cost_of_incident;
      threatVectors.costOfBreach = payload?.cost_of_breach;
      threatVectors.percentOfBreach = payload?.percent_of_breach;
      threatVectors.lostOfBusinessCost = payload?.lost_business_cost;
      threatVectors.threatDescription = payload?.description;
      threatVectors.threatScenario = payload?.threat_senario;
    }
  };
  useEffect(() => {
    initials();
  }, []);

  //sort table

  return (
    <div className="p-10">
      {/* add new model */}
      <Modal show={true}>
        <Modal.Header className=" flex justify-center">
          <Modal.Title>
            {action === 'add' ? 'Add New ' : 'Edit'} Threat Vector
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-three-columns">
            <Form.Group class="mb-6">
              <Form.Label
                for="name"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Threat Vector Name
              </Form.Label>
              <Form.Control
                type="text"
                id="name"
                value={threatVectors.name}
                onChange={(e) => setField('name', e.target.value)}
                class="bg-gray-50 border border-gray-300 text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Text..."
                isInvalid={!!errors.name}
                name="name"
                autoFocus
                required
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group class="mb-6">
              <Form.Label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Threat Category
              </Form.Label>
              <Form.Control
                as="select"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={threatVectors.threatCategory}
                required
                onChange={(e) => setField('threatCategory', e.target.value)}
                isInvalid={!!errors.threatCategory}
              >
                <option>Select...</option>
                {category?.map((x, y) => (
                  <option value={x.id} key={y}>
                    {x.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.threatCategory}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group class="mb-6">
              <Form.Label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Discoverability
              </Form.Label>
              <Form.Control
                as="select"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={threatVectors.discoverability}
                required
                onChange={(e) => setField('discoverability', e.target.value)}
                isInvalid={!!errors.discoverability}
              >
                <option>Select...</option>
                {discover?.map((x, y) => (
                  <option value={x.id} key={y}>
                    {x.difficulty_level}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.discoverability}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group class="mb-6">
              <Form.Label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Exploitablity
              </Form.Label>
              <Form.Control
                as="select"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={threatVectors.exploitability}
                required
                onChange={(e) => setField('exploitability', e.target.value)}
                isInvalid={!!errors.exploitability}
              >
                <option>Select...</option>
                {exploit?.map((x, y) => (
                  <option value={x.id} key={y}>
                    {x.difficulty_level}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.exploitability}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group class="mb-6">
              <Form.Label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Reproducibility
              </Form.Label>
              <Form.Control
                as="select"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={threatVectors.reproducibility}
                required
                onChange={(e) => setField('reproducibility', e.target.value)}
                isInvalid={!!errors.reproducibility}
              >
                <option>Select...</option>
                {reproduce?.map((x, y) => (
                  <option value={x.id} key={y}>
                    {x.difficulty_level}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.reproducibility}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group class="mb-6">
              <Form.Label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Confidentiality
              </Form.Label>
              <Form.Control
                as="select"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={threatVectors.confidentiality}
                required
                onChange={(e) => setField('confidentiality', e.target.value)}
                isInvalid={!!errors.confidentiality}
              >
                <option>Select...</option>
                {confident?.map((x, y) => (
                  <option value={x.id} key={y}>
                    {x.difficulty_level}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.confidentiality}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group class="mb-6">
              <Form.Label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Integrity
              </Form.Label>
              <Form.Control
                as="select"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={threatVectors.integrity}
                required
                onChange={(e) => setField('integrity', e.target.value)}
                isInvalid={!!errors.integrity}
              >
                <option>Select...</option>
                {integrities?.map((x, y) => (
                  <option value={x.id} key={y}>
                    {x.difficulty_level}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.integrity}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group class="mb-6">
              <Form.Label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Availability
              </Form.Label>
              <Form.Control
                as="select"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={threatVectors.availability}
                required
                onChange={(e) => setField('availability', e.target.value)}
                isInvalid={!!errors.availability}
              >
                <option>Select...</option>
                {available?.map((x, y) => (
                  <option value={x.id} key={y}>
                    {x.difficulty_level}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.availability}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group class="mb-6">
              <Form.Label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Cost of Breach (USD in millions) (Optional)
              </Form.Label>
              <Form.Control
                value={threatVectors.costOfBreach}
                onChange={(e) => setField('costOfBreach', e.target.value)}
                type="number"
                min={0}
                name="text"
                id="text"
                class=" border  text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              ></Form.Control>
            </Form.Group>

            <Form.Group class="mb-6">
              <Form.Label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Percent of Breach % (Optional)
              </Form.Label>
              <Form.Control
                type="number"
                min={0}
                name="text"
                value={threatVectors.percentOfBreach}
                onChange={(e) => setField('percentOfBreach', e.target.value)}
                id="text"
                class="rounded-lg border  text-gray-900   focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              ></Form.Control>
            </Form.Group>

            <Form.Group class="mb-6">
              <Form.Label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Threat Event Frequency % (Optional)
              </Form.Label>
              <Form.Control
                value={threatVectors.threatVectorIncident}
                onChange={(e) =>
                  setField('threatVectorIncident', e.target.value)
                }
                type="number"
                min={0}
                name="text"
                id="text"
                class="rounded-lg border  text-gray-900   focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              ></Form.Control>
            </Form.Group>

            <Form.Group class="mb-6">
              <Form.Label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Cost Per Incident (USD in millions) (Optional)
              </Form.Label>
              <Form.Control
                value={threatVectors.costOfIncident}
                onChange={(e) => setField('costOfIncident', e.target.value)}
                type="number"
                min={0}
                name="text"
                id="text"
                class="rounded-lg border  text-gray-900  focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              ></Form.Control>
            </Form.Group>

            <Form.Group class="mb-6">
              <Form.Label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Lost Business Cost (USD in millions) (Optional)
              </Form.Label>
              <Form.Control
                min={0}
                type="number"
                name="password"
                size={2}
                id="password"
                value={threatVectors.lostOfBusinessCost}
                onChange={(e) => setField('lostOfBusinessCost', e.target.value)}
                class="rounded-lg border  text-gray-900  focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              ></Form.Control>
            </Form.Group>
            <Form.Group class="mb-6">
              <Form.Label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Threat Description
              </Form.Label>
              <Form.Control
                as="textarea"
                type="text"
                id="message"
                maxLength={charLimit}
                value={threatVectors.threatDescription}
                onChange={(e) => {
                  setField('threatDescription', e.target.value);
                  setCharCount(e.target.value.length);
                }}
                rows="4"
                class="block p-2.5 w-full  text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Text..."
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                {charCount > charLimit / 2 ? (
                  <div className="flex justify-end">
                    <p className="right-0 text-red-500 font-medium">{`${charCount}/${charLimit}`}</p>
                  </div>
                ) : (
                  ''
                )}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group class="mb-6">
              <Form.Label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Threat Scenario
              </Form.Label>
              <Form.Control
                as="textarea"
                type="text"
                id="message"
                maxLength={charLimit}
                value={threatVectors.threatScenario}
                onChange={(e) => {
                  setField('threatScenario', e.target.value);
                  setScenarioCharCount(e.target.value.length);
                }}
                rows="4"
                class="block p-2.5 w-full  text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 "
                placeholder="Text..."
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                {scenarioCharCount > charLimit / 2 ? (
                  <div className="flex justify-end">
                    <p className="right-0 text-red-500 font-medium">{`${scenarioCharCount}/${charLimit}`}</p>
                  </div>
                ) : (
                  ''
                )}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </Modal.Body>
        {action === 'add' ? (
          <Modal.Footer>
            <Button
              onClick={() => {
                handleModalShowHide();
                clearFields();
              }}
              className="btn-cancel"
            >
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                submit(e);
              }}
              className="btn-add-new"
            >
              Add
            </Button>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <Button
              className="btn-cancel"
              onClick={() => {
                handleModalShowHide();
              }}
            >
              Cancel
            </Button>
            <Button
              className="btn-add-new"
              onClick={(e) => {
                update(e);
              }}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        )}
      </Modal>

      {/* editing model */}
    </div>
  );
};

export default ThreatRepositoryModal;
