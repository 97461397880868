import React from "react";
import Chart from "react-apexcharts";
import { capitalizeFirstLetter } from "./../../utils/helpers";
import {
	countLabelsAndColors,
	getApplicationRiskProfilColor,
} from "../../utils/getBackgroundColor";

const RiskProfileDashboardDonut = ({ data, darkMode }) => {
	const severities = data?.map((item) => item?.application_risk_profile);
	// Mapping severity values to colors using the getBackgroundColor function
	const allData = severities?.map((severity) =>
		getApplicationRiskProfilColor(severity)
	);

	const chartData = countLabelsAndColors(allData);
	const labels = chartData?.map((item) => capitalizeFirstLetter(item?.label));
	const colors = chartData?.map((item) => item?.color);
	const count = chartData?.map((item) => item?.count);

	return (
		<div className="p-0 z-0">
			<Chart
				type="donut"
				width={400}
				height={250}
				series={count}
				options={{
					labels: labels,
					colors: [
						"var(--color-chart-one)",
						"var(--color-chart-two)",
						"var(--color-chart-three)",
						"var(--color-chart-four)",
						"var(--color-chart-five)",
						"var(--color-chart-six)",
					],

					stroke: {
						width: 0,
					},

					dataLabels: {
						style: {
							textColor: "white",
							fontSize: "14px",
							fontFamily: "Helvetica, Arial, sans-serif",
							fontWeight: "bold",
						},
						enabled: true,
						formatter: function (val, opts) {
							/* Format labels here */
							return opts.w.config.series[opts.seriesIndex];
						},
					},
					//start
					responsive: [
						{
							breakpoint: 535,
							options: {
								chart: {
									width: 330,
								},
								legend: {
									offsetY: -13,
									offsetX: 0,
								},
								dataLabels: {
									style: {
										fontSize: "14px",
										fontWeight: "normal",
									},
								},
							},
						},
					],
					//finish
					legend: {
						fontSize: "14px",
						fontWeight: "bold",
						itemMargin: "left",
						labels: {
							colors: darkMode ? "#979ea5" : "black",
						},
					},
				}}
			/>
		</div>
	);
};

export default RiskProfileDashboardDonut;
