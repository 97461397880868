import LanguageIcon from "@mui/icons-material/Language";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { useContext, useEffect, useState } from "react";
import apiClient from "../../resources/apiClient";
// import cronusLogo from "../../assets/imgs/cronusLogo.png";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import cronusBlueNew from "../../assets/imgs/cronusBlueNew.png";
import { ThemeContext } from "../../context/ThemeContext";
import { useHandleTheme } from "../../hooks/useHandleTheme";
import PleaseWait from "../../shared/PleaseWaitLoader/PleaseWait";
import { storeCurrentDateTimeInLocalStorage } from "../../utils/helpers";
import { set_token, set_user_data, set_user_status } from "../store/actions";
import { setUserName } from "../store/reducers/userNameSlice";
const Login = () => {
	const { theme, setTheme } = useContext(ThemeContext);
	const { handleDarkTheme, handleLightTheme, darkMode } = useHandleTheme();
	const dispatch = useDispatch();

	const toggleTheme = () => {
		setTheme("light");
		handleLightTheme();
	};
	useEffect(() => {
		toggleTheme();
	}, []);

	const registerUserDetails = (response) => {
		console.log(response.data, "data here");
		dispatch(set_token(response.data.access_token));
		dispatch(
			set_user_data({
				...response.data.user,
				totp: response.data.user.totp,
			})
		);
		// dispatch(setUserName(response.data.user.username));
		dispatch(
			set_user_status({
				approved: response.data.user.approved,
				loggedIn: true,
				access_token: response.data.access_token,
				refresh_token: response.data.refresh_token,
			})
		);
	};

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [status, setStatus] = useState("");
	const history = useHistory();
	const [viewPassword, setViewPassword] = useState(false);
	const toggleViewPassword = () => setViewPassword(!viewPassword);
	const [checked, setChecked] = useState(false);
	var currentTime = new Date();
	const [loading, setLoading] = useState(false);

	// returns the year (four digits)
	var year = currentTime.getFullYear();

	const handleChange = (event) => {
		setChecked(event.target.checked);
	};

	const submit = async (e) => {
		e.preventDefault();

		try {
			setLoading(true);
			const response = await apiClient.auth
				.login({
					username: email,
					password,
				})
				.then(
					(response) => {
						setLoading(false);
						storeCurrentDateTimeInLocalStorage();
						localStorage.setItem(
							"data",
							JSON.stringify({
								email: email,
								password: password,
								userId: response.data.user.id,
							})
						);
						localStorage.setItem("totp", response.data.user.totp);
						dispatch(setUserName(response.data.user.username));
						if (response.data.user.status === "active") {
							if (response.data.user.is_temporary_password === false) {
								history.push("/multifactor-authentication");
							} else {
								registerUserDetails(response);
								history.push("/change_password");
							}
						} else {
							setStatus("inactiveUser");
							setLoading(false);
							// swal(
							//   "Your credentials are not active at the moment. Please reach out to support"
							// );
						}
					},
					(err) => {
						setLoading(false);

						//   swal("Email and password is not valid");
						setStatus("invalidUser");
					}
				);
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	};
	// bg-second

	return (
		<div className=" bg   flex  justify-around items-center  h-screen overflow-y-hidden">
			<div className="h-full   text-white  text-4xl  flex  justify-center items-center">
				<div className="flex flex-col justify-between items-start h-4/5">
					<span className="font-bold ">
						Secure Your Enterprise with a <br></br>Data-Driven Cyber Strategy.
					</span>
					<span className="text-3xl">
						Protect Your Business with <br></br>Proven Cybersecurity Frameworks.
					</span>
					<div className="flex space-x-4">
						<button
							type="button"
							style={{ backgroundColor: "#ce9f2c" }}
							className="focus:outline-none text-white    focus:ring-4  font-medium rounded-lg text-xl  px-5 py-3 mr-2 mb-2 "
						>
							Get Started
						</button>
						<button
							style={{ backgroundColor: "#11c4f5" }}
							type="button"
							className="focus:outline-none text-white    focus:ring-4  font-medium rounded-lg text-xl  px-5 py-3 mr-2 mb-2 "
						>
							Learn More{" "}
						</button>
					</div>
					<div className="flex  justify-center items-center ">
						<span className="text-xl">Powered By</span>

						<img
							className=" w-44 object-contain"
							src="https://i.ibb.co/F3kB1p7/imgs/logo.png"
							alt="cyberminds-logo"
						/>
					</div>
					<div className="flex">
						<div className="flex flex-col space-y-4">
							<div className=" flex justify-center items-center space-x-5">
								<a
									className="decoration-none"
									target="_blank"
									href="https://www.youtube.com/channel/UCoe0mRdgqbOYXp6paXRiSVQ"
									rel="noReferrer"
								>
									{" "}
									<YouTubeIcon className="h-6 text-red" />
								</a>
								<a
									href="https://twitter.com/cybermindsc"
									target="_blank"
									rel="noReferrer"
								>
									{" "}
									<TwitterIcon className="h-6" />
								</a>
								<a
									href="https://www.linkedin.com/company/cyberminds-consulting/"
									target="_blank"
									rel="noReferrer"
								>
									{" "}
									<LinkedInIcon className="h-6" target="_blank" />
								</a>
								<a
									href="https://www.cybermindssolutions.com/"
									target="_blank"
									rel="noReferrer"
								>
									{" "}
									<LanguageIcon className="h-6" />
								</a>
							</div>
							<div>
								<span className=" flex justify-center items-center text-xs">
									©{year} by CyberMinds Consulting. All Rights Reserved.
								</span>
							</div>
						</div>{" "}
					</div>
				</div>
			</div>
			<div className="h-5/6 ">
				<form className="p-6 bg-white dark:bg-gray-700 flex flex-col rounded-2xl shadow-2xl shadow-cyan-500/50">
					<div className="flex justify-center items-center  ">
						<img
							className="h-44 w-44 rounded-full"
							src={cronusBlueNew}
							alt="Cronus logo"
						/>
					</div>
					<div className="mb-6">
						<label
							for="email"
							className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
						>
							Email
						</label>
						<div>
							<input
								type="email"
								id="email"
								value={email}
								className="flex items-center p-2.5 bg-gray-50 border text-gray-900 text-sm rounded-xl shadow-2xl shadow-cyan-500/50 focus:ring-slate-200 focus:border-slate-200  w-full
                  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-300-200 dark:focus:border-gray-300"
								onChange={(e) => setEmail(e.target.value)}
								placeholder="Email..."
								required
							/>
						</div>
					</div>
					<div className="mb-6">
						<label
							for="password"
							className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
						>
							Password
						</label>
						<div
							className="flex justify-between items-center bg-gray-50 border text-gray-900 text-sm rounded-xl shadow-2xl shadow-cyan-500/50 focus:ring-gray-200 focus:border-slate-200  w-full
                   dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-300-200 dark:focus:border-gray-300"
						>
							<input
								type={viewPassword ? "text" : "password"}
								id="password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								placeholder="•••••••••"
								required
								className="inline-block p-2.5 bg-gray-50 border text-gray-900 text-sm rounded-xl shadow-2xl shadow-cyan-500/50 focus:ring-slate-200 focus:border-slate-200  w-full
                   dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-300-200 dark:focus:border-gray-300"
							/>
							<span
								className="cursor-pointer inline-block p-1"
								onClick={() => toggleViewPassword()}
							>
								{viewPassword ? (
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth="1.5"
										stroke="currentColor"
										className="w-6 h-6"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
										/>
									</svg>
								) : (
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth="1.5"
										stroke="currentColor"
										className="w-6 h-6"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
										/>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
										/>
									</svg>
								)}
							</span>
						</div>

						<label
							onClick={() => history.push("/reset-password")}
							className="flex cursor-pointer justify-end items-end mb-2 mt-2 text-sm font-medium text-blue-500 dark:text-blue-300"
						>
							Forgot Password?
						</label>
					</div>
					{status === "inactiveUser" ? (
						<p className="text-red-500 text-sm">
							Your credentials are not active at the <br></br> moment. Please
							contact
							<a
								href="#"
								className="text-blue-600 hover:underline dark:text-blue-500"
							>
								support
							</a>{" "}
						</p>
					) : status === "invalidUser" ? (
						<p className="text-red-500 text-sm">
							Email and password are not valid.
						</p>
					) : null}

					<div className="flex items-start mb-6">
						<div className="flex items-center h-5">
							<input
								id="remember"
								type="checkbox"
								value=""
								className="w-4 h-4 rounded border border-slate-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-200dark:focus:ring-gray-300-200 dark:focus:border-gray-300"
								required
								checked={checked}
								onChange={handleChange}
							/>
						</div>
						<label
							for="remember"
							className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-400"
						>
							I agree with the{" "}
							<a
								href="#"
								className="text-blue-600 hover:underline dark:text-blue-500"
							>
								terms and conditions
							</a>
							.
						</label>
					</div>

					{checked ? (
						loading ? (
							<PleaseWait />
						) : (
							<button
								className="focus:outline-none text-white    focus:ring-4  font-medium rounded-lg text-sm  px-5 py-3 mr-2 mb-2 "
								type="submit"
								style={{ backgroundColor: "#ce9f2c" }}
								onClick={(e) => {
									submit(e);
								}}
							>
								Sign In
							</button>
						)
					) : (
						<button
							className="focus:outline-none text-white    focus:ring-4  font-medium rounded-lg text-sm  px-5 py-3 mr-2 mb-2 "
							type="submit"
							style={{ backgroundColor: "#ce9f2c" }}
						>
							Sign In
						</button>
					)}
				</form>
			</div>
		</div>
	);
};

export default Login;
