import React, { useState } from 'react';
import FileUploadLoading from '../../utils/FileUploadLoading';
import { Modal } from 'react-bootstrap';

const FileUploader = ({
  loading,
  file,
  handleFile,
  handleUploadFile,
  handleUploadFileErase,
  toggleFileUploader,
}) => {
  console.log(file, 'file');
  const [fileChosen, setFileChosen] = useState('');
  return (
    <div
      id="defaultModal"
      class="flex justify-center items-center fixed top-0 left-0 right-0 z-50  w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
    >
      <div class="relative w-full max-w-2xl max-h-full">
        <div class="relative bg-gray-200 rounded-lg shadow dark:bg-gray-700">
          <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
              Upload File
            </h3>
            <button
              onClick={() => toggleFileUploader()}
              type="button"
              class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="defaultModal"
            >
              <svg
                class="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
          </div>
          <div class="p-6 space-y-6">
            <div>
              <div class="flex items-center justify-center w-full">
                <label
                  for="dropzone-file"
                  class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                >
                  {loading ? <FileUploadLoading /> : null}
                  <div class="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                      <span class="font-semibold">Click to upload</span> .XLSX
                      (files only)
                    </p>
                    {/* <p class="text-xs text-gray-500 dark:text-gray-400">
                      .XLSX (files only)
                    </p> */}
                  </div>
                  {!loading ? (
                    <input
                      id="dropzone-file"
                      type="file"
                      onChange={(e) => handleFile(e)}
                      class=""
                      accept=".xlsx"
                    />
                  ) : null}
                  <p className={`${file ? 'sr-only' : 'text-red-500'} `}>
                    {fileChosen}
                  </p>
                </label>
              </div>

              {/* <input
                className="form-control w-64"
                type="file"
                name="file"
                onChange={(e) => handleFile(e)}
              /> */}
            </div>
          </div>
          <div class="flex justify-end items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
            <button
              // disabled={file ? false : true}
              onClick={(e) =>
                file ? handleUploadFile(e) : setFileChosen('Please Choose File')
              }
              data-modal-hide="defaultModal"
              type="button"
              class="border  bg  focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              Append File
            </button>
            <button
              onClick={(e) =>
                file
                  ? handleUploadFileErase(e)
                  : setFileChosen('Please Choose File')
              }
              data-modal-hide="defaultModal"
              type="button"
              class="text-gray-500 button hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
            >
              Replace File
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileUploader;
