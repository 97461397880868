import React from "react";
import { BiLogOutCircle } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import {
	MdOutlineAdminPanelSettings,
	MdOutlineLockReset,
} from "react-icons/md";
import { RiArrowDownSFill } from "react-icons/ri";
import ThemeController from "../../../context/ThemeController";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import UserNameIcon from "../../../utils/UserNameIcon";

const ClientNavbarItems = [
	// {
	// 	id: "executiveDashboard",
	// 	name: "Executive Dashboard",
	// 	link: "/c/executivesummary",
	// },
	{
		id: "clientHomePage",
		name: "Executive Dashboard",
		link: "/",
	},
	{
		id: "Analysis",
		name: (
			<>
				Analysis
				<RiArrowDownSFill className="dropdown-icon" />
			</>
		),
		link: "#",
		dropdown: [
			{
				id: "businessImpactAnalysis",
				name: "Business Impact Analysis",
				link: "/c/analysis/business-impact-analysis",
			},
			{
				id: "threatsProfiling",
				name: "Threats Profiling",
				link: "/c/analysis/threatsprofiling",
			},

			{
				id: "controlAnalysis",
				name: "Control Analysis",
				link: "/c/analysis/controlanalysis/controlanalysis",
			},
			{
				id: "controlAnalysisSummary",
				name: "Control Analysis Summery",
				link: "/c/analysis/controlanalysis/controlanalysissummary",
			},

			// {
			// 	id: "threatProfiling",
			// 	name: "Threat Profiling",
			// 	link: "/c/analysis/threatprofiling",
			// },

			// {
			// 	id: "scopeAnalysis",
			// 	name: "Investment Analysis",
			// 	link: "/c/analysis/scopeanalysis",
			// },
			{
				id: "InvestmentAnalysis",
				name: "Investment Analysis",
				link: "/c/analysis/investment-analysis",
			},

			// {
			//   id: ' riskAnalysis',
			//   name: 'Risk Analysis',
			//   link: '/c/analysis/riskanalysis',
			// },
		],
	},
	{
		id: "Configuration",
		name: (
			<>
				Configuration
				<RiArrowDownSFill className="dropdown-icon" />
			</>
		),
		link: "#",
		dropdown: [
			{
				id: "businessImpactAnalysis",
				name: "Business Impact Analysis",
				link: "/c/configuration/business_impact_analysis",
			},
			{
				id: "businessProcess",
				name: "Business Process",
				link: "/c/configuration/business_process",
			},
		],
	},

	{
		id: "UserNameIconC",
		name: <UserNameIcon />,

		link: "#",
		dropdown: [
			{
				id: "switchToAdmin",
				name: (
					<>
						<MdOutlineAdminPanelSettings className="inline-block" /> Switch to
						Admin
					</>
				),

				link: "/ca/threat_vectors",
			},
			{
				name: (
					<>
						<ThemeController />
					</>
				),
				link: "#",
			},
			{
				name: (
					<>
						<CgProfile className="inline-block" /> Profile
					</>
				),
				link: "#",
			},
			{
				name: (
					<>
						<ContactSupportIcon className="inline-block" /> Support
					</>
				),
				link: "/client_support",
			},
			{
				name: (
					<>
						<MdOutlineLockReset className="inline-block" /> Reset Password
					</>
				),
				link: "/change_my_password",
			},
			{
				name: (
					<>
						<BiLogOutCircle className="inline-block" /> Logout
					</>
				),

				link: "/signout",
			},
		],
	},
];

export default ClientNavbarItems;
