import React from "react";
import ThemeController from "../../../context/ThemeController";
import { MdOutlineLockReset } from "react-icons/md";
import { BiLogOutCircle } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import UserNameIcon from "../../../utils/UserNameIcon";

export const SuperAdminNavItems = [
	{
		id: "threatRepository",
		name: "Threat Repository",
		link: "/super_threat_vectors",
	},
	{
		id: "configurations",
		name: "Configurations",
		link: "#",

		dropdown: [
			{ id: "asset", name: "Asset", link: "/configurations/manage_asset" },
			{
				id: "businessProcess",
				name: "Business Process",
				link: "#",
				secondaryDropdown: [
					{
						id: "applicationAccessibility",
						name: "Application Accessibility",
						link: "/configurations/business_process/application_accessibility",
					},
					{
						id: "broadestPopulationOfUser",
						name: "Broadest population of User",
						link: "/configurations/business_process/broadcast_population_of_user",
					},
					{
						id: "businessCriticality",
						name: "Business Criticality (RPO)",
						link: "/configurations/business_process/criticality",
					},
					{
						id: "businessImpacts",
						name: "Business Impact",
						link: "/configurations/business_process/business_impacts",
					},
					{
						id: "classificationLevel",
						name: "Data Classification",
						link: "/configurations/business_process/classification_level_of_data",
					},
					{
						id: "hostingModal",
						name: "Hosting Model",
						link: "/configurations/business_process/hosting_model",
					},
					{
						id: "OriginOfData",
						name: "Origin of Data",
						link: "/configurations/business_process/origin_of_data",
					},
					{
						id: "userInterfaceAccessibility",
						name: "User Interface Accessibility",
						link: "/configurations/business_process/user_interface_accessibility",
					},
					{
						id: "userPopulation",
						name: "User Population",
						link: "/configurations/business_process/user_population",
					},
				],
			},

			{
				id: "controls",
				name: "Controls",
				link: "#",
				secondaryDropdown: [
					{
						id: "control-applicability",
						name: "Control Applicability",
						link: "/configurations/controls/availability",
					},

					{
						id: "control-category",
						name: "Control Category",
						link: "/configurations/controls/category",
					},

					{
						id: "control-impact",
						name: "Control Impact",
						link: "/configurations/controls/impact",
					},
					{
						id: "control-domain",
						name: "Control Domain",
						link: "/configurations/controls/domain",
					},
					{
						id: "control-scope",
						name: "Control Scope",
						link: "/configurations/controls/scope",
					},

					{
						id: "industry-standard",
						name: "Industry Standard",
						link: "/configurations/controls/industry_standard",
					},
					{
						id: "contro-type",
						name: "Control Type",
						link: "/configurations/controls/type",
					},
					{
						id: "industry-use-cases",
						name: "Industry Use Case",
						link: "/configurations/controls/industry_usecase",
					},
				],
			},
			{
				id: "currency",
				name: "Currency",
				link: "/configurations/manage_currency",
			},
			{
				id: "costCategory",
				name: "Cost Category",
				link: "/configurations/cost_category",
			},
			{
				id: "exploitablePaths",
				name: "Exploitable Paths",
				link: "/configurations/super-admin-manage-exploitable-paths",
			},
			{
				id: "industry",
				name: "Industry",
				link: "/configurations/manage_sector",
			},
			{
				id: "investmentRequirements",
				name: "Investment Requirements",
				link: "/configurations/manage_investment_requirements",
			},
			{
				id: "riskProbability",
				name: "Risk Probability",
				link: "/configurations/manage_risk_probability",
			},
			{
				id: "riskSensitivity",
				name: "Risk Sensitivity",
				link: "/configurations/risk_sensitivity",
			},
			{
				id: "threatModelingMatrix",
				name: "Threat Modeling Matrix",
				link: "/configurations/threat_modeling_matrix_configuration",
			},
			{
				id: "threatRepository",
				name: "Threat Repository",
				link: "#",
				secondaryDropdown: [
					{
						id: "availability",
						name: "Availability",
						link: "/configurations/threat_repository/availability",
					},
					{
						id: "confidentiality",
						name: "Confidentiality",
						link: "/configurations/threat_repository/confidentiality",
					},
					{
						id: "discoverability",
						name: "Discoverability",
						link: "/configurations/threat_repository/discoverability",
					},
					{
						id: "exploitability",
						name: "Exploitability",
						link: "/configurations/threat_repository/exploitability",
					},
					{
						id: "integrity",
						name: "Integrity",
						link: "/configurations/threat_repository/integrity",
					},
					{
						id: "reproducibility",
						name: "Reproducibility",
						link: "/configurations/threat_repository/reproducibility",
					},
					{
						id: "threatCategory",
						name: "Threat Category",
						link: "/configurations/threat_repository/threatcategory",
					},
				],
			},
		],
	},
	{
		id: "manageFile",
		name: "Manage File",
		link: "#",
		dropdown: [
			{
				id: "assetThreatMapping",
				name: "Assets Threat Mapping",
				link: "/manage_file/AT_mapping",
			},
			{
				id: "businessImpactAnalysis",
				name: "Business Impact Analysis",
				link: "/manage_file/business_impact_analysis",
			},
			{
				id: "controlCatalog",
				name: "Control Catalog",
				link: "/manage_file/control_catalog",
			},
			{
				id: "controlEffectivenessMatrix",
				name: "Control Effectiveness Matrix",
				link: "/manage_file/control_matrix",
			},
			{ id: "cost", name: "Costs", link: "/manage_file/upload_costs" },
			{ id: "csa", name: "CSA", link: "/manage_file/consensus" },
			{
				id: "industryData",
				name: "Industry Data",
				link: "/manage_file/industry_data",
			},
			{ id: "nist", name: "NIST", link: "/manage_file/NIST" },
			{
				id: "responseDistributionData",
				name: "Response Distribution Data",
				link: "/manage_file/response_distribution_data",
			},
			{
				id: "threatRiskMapping",
				name: "Threat Risk Mapping",
				link: "/manage_file/risk_tc_mapping",
			},
		],
	},
	{
		id: "accounts",
		name: "Accounts",
		link: "#",
		dropdown: [
			{
				id: "manageUsers",
				name: "Manage Users ",
				link: "/manage_users",
			},
			{
				id: "manageClients",
				name: "Manage Clients ",
				link: "/manage_clients",
			},
		],
	},
	{
		id: "UserNameIconSA",
		name: <UserNameIcon />,

		link: "#",
		dropdown: [
			{
				name: (
					<>
						<ThemeController />
					</>
				),
				link: "#",
			},
			{
				name: (
					<>
						<CgProfile className="inline-block" /> Profile
					</>
				),
				link: "#",
			},
			{
				name: (
					<>
						<MdOutlineLockReset className="inline-block" /> Reset Password
					</>
				),
				link: "/change_my_password",
			},
			{
				name: (
					<>
						<BiLogOutCircle className="inline-block" /> Logout
					</>
				),

				link: "/signout",
			},
		],
	},
];
