import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../../shared/CustomTable";
import SearchHandler from "../../../shared/SearchHandler";
import http from "../../../resources/http";
import LoadingComponent from "../../../shared/LoadingDropDownSelection/LoadingComponent";
import AddIcon from "@mui/icons-material/Add";
import SearchInput from "../../../shared/SearchInput/SearchInput";
import ControlsModalHandler from "./ControlModalsUntilBEupdate/ControlsModalHandler";
import { CONTROL_TYPE } from "../../../api/excelUpload";
import {
  setTypes,
  addType,
  updateType,
  deleteType,
} from '../../../authentication/store/reducers/typesSlice';
import swal from 'sweetalert';
const ControlType = () => {
  const url = CONTROL_TYPE;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mode, setMode] = useState('add'); // 'add' or 'edit'
  const [selectedItem, setSelectedItem] = useState(null);

  const types = useSelector((state) => state.controls.types);
  const dispatch = useDispatch();

  const [id, setId] = useState();
  const [data, setData] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const fieldsToFilterBy = ['control_type', 'description'];

  //data
  async function fetchData() {
    setFetchLoading(true);
    try {
      const response = await http.get(url);
      const typeNameList = response.data.map((item) => item.control_type);
      console.log(typeNameList);
      dispatch(setTypes(typeNameList));
      setData(response.data);
      setFetchLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setFetchLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const filteredData = SearchHandler(data, searchValue, fieldsToFilterBy);

  const handleInputChange = (newValue) => {
    setSearchValue(newValue);
  };
  // Handle the edit click from the table
  const handleEdit = (id, item) => {
    setSelectedItem(item);
    setId(id);
    setMode('edit');
    setIsModalOpen(true);
  };

	const handleDelete = async (itemId) => {
		setFetchLoading(true);
		try {
			await http.delete(`${url}/${itemId}`);
			fetchData();
			setFetchLoading(false);
			swal("Success", "It has been deleted successfully", "success");
		} catch (error) {
			swal("Failed", "Failed to delete item", "error");
			setFetchLoading(false);
			console.error("Failed to delete item:", error);
		}
	};
	return (
		<main>
			<div className="main-container">
				<div className="table-title ">
					<span className="">Control Type</span>
					<div className="flex justify-between items-center space-x-4">
						<SearchInput
							searchValue={searchValue}
							setSearchValue={handleInputChange}
						/>
						<button
							className="btn-add-new"
							onClick={() => {
								setSelectedItem(null);
								setMode("add");
								setIsModalOpen(true);
							}}
						>
							<AddIcon />
						</button>
					</div>
				</div>
				{/* table */}
				{fetchLoading ? (
					<LoadingComponent />
				) : searchValue.length > 0 && filteredData.length === 0 ? (
					<div className="alert-no-data">
						No data available for
						<span className="search-value">{searchValue}</span>
						at the moment.
					</div>
				) : (
					<CustomTable
						filteredData={filteredData}
						onEditClick={handleEdit}
						onDeleteClick={handleDelete}
						passedExcludeColumns={["value"]}
					/>
				)}
				{/* add and edit modal */}
				<ControlsModalHandler
					isOpen={isModalOpen}
					onClose={() => setIsModalOpen(false)}
					mode={mode}
					size="xl"
					selectedItemId={id}
					selectedItem={selectedItem}
					header="Control Type"
					url={url}
					fetchData={fetchData}
				/>
			</div>
		</main>
	);
};
export default ControlType;
