import React, { useState, useEffect } from "react";
import http from "../../../resources/http";
import Modal from "../../../shared/Modal";
import Form from "../../../shared/Form";
import {
	CONTROL_DOMAIN,
	CONTROL_CATEGORY,
	CONTROL_TYPE,
} from "../../../api/configurations";
import LoadingComponent from "../../../shared/LoadingDropDownSelection/LoadingComponent";
import swal from "sweetalert";

const ModalHandler = ({
	isOpen,
	mode,
	onClose,
	header,
	selectedItem,
	selectedItemId,
	size,
	url_create,
	url_by_pk,
	fetchData,
}) => {
	const [fetchLoading, setFetchLoading] = useState(false);
	const [domainsDropdownOptions, setDomainsDropdownOptions] = useState([]);
	const [categoryDropdownOptions, setCategoryDropdownOptions] = useState([]);
	const [typeDropdownOptions, setTypeDropdownOptions] = useState([]);

	async function fetchFormOptionData() {
		try {
			const listOfDomains = await http.get(CONTROL_DOMAIN);
			const listOfCategory = await http.get(CONTROL_CATEGORY);
			const listOfType = await http.get(CONTROL_TYPE);

			const domainsOptionsData = listOfDomains.data?.map((item) => item.name);
			setDomainsDropdownOptions(domainsOptionsData);
			const categoryOptions = listOfCategory.data?.map((item) => item.title);
			setCategoryDropdownOptions(categoryOptions);
			const TypeOptions = listOfType.data?.map((item) => item.control_type);
			setTypeDropdownOptions(TypeOptions);
		} catch (error) {
			console.log("error occurred while fetching form dropdown options.");
		}
	}
	useEffect(() => {
		if (isOpen) {
			fetchFormOptionData();
		}
	}, [isOpen]);

	const initialState = {
		control_type: "",
		control_domain: "",
		control_category: "",
		effectiveness_in_percent: "",
	};
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState({ initialState });
	const [errors, setErrors] = useState({});

	const validations = {
		control_type: (value) => (value ? "" : "This field is required."),
		control_domain: (value) => (value ? "" : "This field is required."),
		control_category: (value) => (value ? "" : "This field is required."),
		effectiveness_in_percent: (value) =>
			value ? "" : "This field is required.",
	};

	const handleChange = (key, value) => {
		setFormData((prevState) => ({ ...prevState, [key]: value }));
		if (validations[key]) {
			const error = validations[key](value);
			setErrors((prevState) => ({ ...prevState, [key]: error }));
		}
	};

	const resetForm = () => {
		setFormData(initialState);
		setErrors({});
	};

	useEffect(() => {
		if (mode === "edit" && selectedItem) {
			console.log(selectedItem, mode, "selectedItem");
			setLoading(true);
			setFormData(selectedItem);
			setLoading(false);
		} else {
			resetForm();
		}
	}, [mode, selectedItem]);

	const formFieldsConfig = {
		control_type: {
			label: "Cost Type",
			type: "select",
			placeholder: "Select...",
			options: typeDropdownOptions,
			value: formData.control_type || "",
			onChange: (e) => handleChange("control_type", e.target.value),
			error: errors.control_type,
		},
		control_domain: {
			label: "Control Domain",
			type: "select",
			placeholder: "Select...",
			options: domainsDropdownOptions,
			value: formData.control_domain || "",
			onChange: (e) => handleChange("control_domain", e.target.value),
			error: errors.control_domain,
		},
		control_category: {
			label: "Control Category",
			type: "select",
			placeholder: "Select...",
			options: categoryDropdownOptions,
			value: formData.control_category || "",
			onChange: (e) => handleChange("control_category", e.target.value),
			error: errors.control_category,
		},
		effectiveness_in_percent: {
			label: "Effectiveness in percent",
			type: "number",
			placeholder: "Number...",
			min: 0,
			max: 100,
			value: formData?.effectiveness_in_percent,
			onChange: (e) => handleChange("effectiveness_in_percent", e.target.value),
			error: errors.effectiveness_in_percent,
		},
	};

	const handleSubmit = () => {
		let newErrors = {};
		Object.entries(formFieldsConfig).forEach(([key, config]) => {
			if (validations[key]) newErrors[key] = validations[key](formData[key]);
		});
		setErrors(newErrors);
		if (Object.values(newErrors).every((error) => !error)) {
			if (mode === "edit") {
				http
					.patch(`${url_by_pk}${selectedItemId}`, {
						control_type: formData.control_type,
						control_domain: formData.control_domain,
						control_category: formData.control_category,
						effectiveness_in_percent: formData.effectiveness_in_percent,
					})
					.then((res) => {
						fetchData();
						onClose();
						swal("Success!", "It has been updated successfully", "success");
						resetForm();
					})
					.catch((error) => {
						setFetchLoading(false);
						if (error && error.response && error.response.data) {
							for (const [key, errorMessage] of Object.entries(
								error.response.data
							)) {
								if (formFieldsConfig[key]) {
									newErrors[key] = errorMessage[0];
								}
								setErrors(newErrors);
							}
						} else {
							swal("Error", "Something went wrong, please try again", "error");
						}
					});
			} else {
				http
					.post(`${url_create}`, {
						control_type: formData.control_type,
						control_domain: formData.control_domain,
						control_category: formData.control_category,
						effectiveness_in_percent: formData.effectiveness_in_percent,
					})
					.then((res) => {
						fetchData();
						onClose();
						swal("Success!", "It has been updated successfully", "success");
						resetForm();
					})
					.catch((error) => {
						setFetchLoading(false);
						if (error && error.response && error.response.data) {
							for (const [key, errorMessage] of Object.entries(
								error.response.data
							)) {
								if (formFieldsConfig[key]) {
									newErrors[key] = errorMessage[0];
								}
								setErrors(newErrors);
							}
						} else {
							swal("Error", "Something went wrong, please try again", "error");
						}
					});
			}
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} size={size}>
			<Modal.Header>
				<Modal.Title>
					{mode === "edit" ? `Update  ${header}` : `Add New  ${header}`}
				</Modal.Title>
			</Modal.Header>
			{fetchLoading ? (
				<LoadingComponent />
			) : (
				<Modal.Body>
					<div className="modal-two-columns">
						<Form>
							{Object.entries(formFieldsConfig || {}).map(
								([key, fieldConfig]) => (
									<Form.Group key={key}>
										<Form.Label>{fieldConfig.label}</Form.Label>
										<Form.Control {...fieldConfig} />
										{errors[key] && (
											<Form.Control.Feedback type="invalid">
												{errors[key]}
											</Form.Control.Feedback>
										)}
									</Form.Group>
								)
							)}
						</Form>
					</div>
				</Modal.Body>
			)}

			<Modal.Footer>
				<button
					className="btn-cancel"
					onClick={() => {
						onClose();
						resetForm();
					}}
				>
					Cancel
				</button>
				<button className="btn-add-new" onClick={handleSubmit}>
					{mode === "edit" ? "Save Changes" : "Add"}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default ModalHandler;
