import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import http from "../../../resources/http";
import {
	EXPLOITABLE_PATH,
	EXPLOITABLE_PATHS,
	FORMATTED_EXPLOITABLE_PATHS,
} from "../../../api/businessProcess";
import Form from "../../../shared/Form";
import MultiSelect from "../../../shared/MultiSelect";
import CustomTable from "../../../shared/CustomTable";
import SearchHandler from "../../../shared/SearchHandler";
import SortAndFilterByHandler from "../../../shared/SortAndFilterByHandler";
import SearchInput from "../../../shared/SearchInput/SearchInput";
import LoadingComponent from "../../../shared/LoadingDropDownSelection/LoadingComponent";
import FilterListIcon from "@mui/icons-material/FilterList";
import { AiOutlineFileSearch } from "react-icons/ai";
import { TbFilterOff, TbFilter } from "react-icons/tb";

const ExploitablePath = () => {
	const url = EXPLOITABLE_PATHS;
	const [id, setId] = useState();
	const [data, setData] = useState([]);
	const [fetchLoading, setFetchLoading] = useState(false);
	const [dropdownShow, setDropdownShow] = useState(false);

	const [searchValue, setSearchValue] = useState("");
	const [filterCriteria, setFilterCriteria] = useState({
		entry: [],
		middle: [],
		end: [],
		type: [],
	});
	const [sortField, setSortField] = useState("");
	const [sortOrder, setSortOrder] = useState("asc");
	const [searchUsedFirst, setSearchUsedFirst] = useState(false);


	const handleCheckboxChangeItem = (field, value) => {
		setFilterCriteria((prev) => ({
			...prev,
			[field]: prev[field].includes(value)
				? prev[field].filter((v) => v !== value)
				: [...prev[field], value],
		}));
	};

	const finalData = searchUsedFirst
		? SortAndFilterByHandler(
				SearchHandler(data, searchValue, ["type", "entry", "middle", "end"]),
				filterCriteria,
				sortField,
				sortOrder
		  )
		: SearchHandler(
				SortAndFilterByHandler(data, filterCriteria, sortField, sortOrder),
				searchValue,
				["type", "entry", "middle", "end"]
		  );

	const handleInputChange = (newValue) => {
		setSearchValue(newValue);
	};

	async function fetchData() {
		setFetchLoading(true);
		try {
			const response = await http.get(url);
			const pathsArray = response.data.paths;
			setData(pathsArray);
		} catch (error) {
			console.error("Error fetching data: ", error);
		} finally {
			setFetchLoading(false);
		}
	}

	useEffect(() => {
		fetchData();
	}, []);

	const typeData = data.map((item) => item.type);
	const dropdownFilterData = [...new Set(typeData)];

	const handleCheckboxChange = async (id, row) => {
		setFetchLoading(true);
		try {
			const response = await http
				.patch(`${EXPLOITABLE_PATH}/${id}`, {
					enabled: row.enabled ? false : true,
				})
				.then((res) => {
					fetchData();
				});
		} catch (error) {
			console.error("There was a problem with the PATCH request:", error);
		} finally {
			setFetchLoading(false);
		}
	};

	return (
		<main>
			<div className="main-container">
				<div className="table-title ">
					<span>Exploitable Paths</span>{" "}
					<div class="dropdown-content">
						<Form>
							{dropdownFilterData.map((type) => (
								<label key={type}>
									<input
										type="checkbox"
										checked={filterCriteria.type.includes(type)}
										onChange={() => handleCheckboxChangeItem("type", type)}
									/>
									{type}
								</label>
							))}
						</Form>
					</div>
					<div className="flex justify-between items-center space-x-4">
						<div class="dropdown">
							<button
								className="btn-add-new"
								onClick={() => setDropdownShow(true)}
							>
								<FilterListIcon />
							</button>
							{dropdownShow ? "" : null}
						</div>
						<SearchInput
							searchValue={searchValue}
							setSearchValue={handleInputChange}
						/>
					</div>
				</div>
				{fetchLoading ? (
					<LoadingComponent />
				) : searchValue.length > 0 && finalData.length === 0 ? (
					<div className="alert-no-data">
						No data available for
						<span className="search-value">{searchValue}</span>
						at the moment.
					</div>
				) : (
					<CustomTable
						filteredData={finalData}
						searchValue={searchValue}
						onCheckboxClick={handleCheckboxChange}
						addManageColumn={false}
					/>
				)}
			</div>
		</main>
	);
};

export default ExploitablePath;
