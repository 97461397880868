import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import http from "../../../resources/http";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import AccountsSideNav from "../AccountsSideNav";
import {
  CLIENTS,
  CREATE_CLIENT_ACCOUNTS,
  UPDATE_CLIENT_ACCOUNT,
  INDUSTRY_SECTOR,
  CURRENCY_LIST,
  DELETE_CLIENT_USERS,
} from "../../../routes/accounts";
import { RISK_SENSITIVITY_LEVELS } from "../../../api/risk";
import { DeleteModal } from "../../../shared/DeleteModal";

const ManageClients = () => {
  const [id, setId] = useState();
  const [clients, setClients] = useState([]);
  const [level, setLevel] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const handleDeleteConfirmation = () =>
    setDeleteConfirmation(!deleteConfirmation);
  const [showHide, setSetShowHide] = useState(false);
  const handleModalShowHide = () => setSetShowHide(!showHide);

  const [showHideMe, setSetShowHideMe] = useState(false);
  const handleModalShowHideMe = () => setSetShowHideMe(!showHideMe);

  const [deleteModal, setDeleteModal] = useState(false);
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);

  const [showHideDelete, setSetShowHideDelete] = useState(false);
  const handleModalShowHideDelete = () => setSetShowHideDelete(!showHideDelete);

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(clients.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [form, setForm] = useState({}); //form
  const [errors, setErrors] = useState({}); //errors
  const [clientsNameList, setClientsNameList] = useState([]);

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };
  const [action, setAction] = useState("");
  const validateForm = () => {
    const {
      companyName = "",
      industrySector = "",
      currency = "",
      riskSensitivity = "",
    } = form;
    const newErrors = {};
    if (action === "add" && clientsNameList.includes(form.companyName.trim()))
      newErrors.companyName = `Client name ${form.companyName} already exists,`;
    if (!companyName || companyName === "" || companyName === "Select...")
      newErrors.companyName = "Company Name is required.";
    // if (
    //   !industrySector ||
    //   industrySector === '' ||
    //   industrySector === 'Select...'
    // )
    //   newErrors.industrySector = 'Industry sector is required.';
    if (!currency || currency === "" || currency === "Select...")
      newErrors.currency = "currency is required.";
    if (!riskSensitivity || riskSensitivity === "")
      newErrors.riskSensitivity = "Risk sensitivity is required.";
    return newErrors;
  };
  const clearAll = () => {
    form.companyName = "";
    form.industrySector = "";
    form.currency = "";
    form.riskSensitivity = "";
  };
  const fetchData = () => {
    http
      .get(`${CLIENTS}`)
      .then((response) => {
        setClients(response.data);
        const names = response.data.map((names) => names.name);
        setClientsNameList(names);
      })
      .then(
        (response) => {},
        (err) => {
          console.log(err);
        }
      );
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    http
      .get(`${INDUSTRY_SECTOR}`)
      .then((response) => {
        setSectors(response.data);
      })
      .then(
        (response) => {},
        (err) => {
          console.log(err);
        }
      );
  }, []);

  useEffect(() => {
    http
      .get(`${CURRENCY_LIST}`)
      .then((response) => {
        setCurrencies(response.data);
      })
      .then(
        (response) => {},
        (err) => {
          console.log(err);
        }
      );
    http
      .get(`${RISK_SENSITIVITY_LEVELS}`)
      .then((response) => {
        setLevel(response.data);
      })
      .then(
        (response) => {},
        (err) => {
          console.log(err);
        }
      );
  }, []);

  const deleteClient = (e) => {
    http.delete(`${DELETE_CLIENT_USERS}/${id}`).then(
      (response) => {
        toggleDeleteModal();
        swal("Success", "Client has been deleted successfully ", "success");
        fetchData();
      },
      (err) => {
        swal(err);
      }
    );
  };

  // Define a state variable to hold the selected IDs
  const [selectedIds, setSelectedIds] = useState({
    industrySectorId: "",
    riskSensitivityId: "",
    currencyId: "",
  });
  console.log(selectedIds, "selectedIds");

  useEffect(() => {
    const updatedIndustrySectorId = sectors?.find(
      (item) => item.name === form.industrySector
    )?.id;
    const updatedRiskSensitivityId = level?.find(
      (item) => item.name === form.riskSensitivity
    )?.id;
    const updatedCurrencyId = currencies?.find(
      (item) => item.name === form.currency
    )?.id;

    setSelectedIds((prevIds) => ({
      ...prevIds,
      industrySectorId: updatedIndustrySectorId || prevIds.industrySectorId,
      riskSensitivityId: updatedRiskSensitivityId || prevIds.riskSensitivityId,
      currencyId: updatedCurrencyId || prevIds.currencyId,
    }));
  }, [
    form.industrySector,
    form.riskSensitivity,
    form.currency,
    sectors,
    level,
    currencies,
  ]);
  const updateClient = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      const industrySectorId = selectedIds.industrySectorId;
      const riskSensitivityId = selectedIds.riskSensitivityId;
      const currencyId = selectedIds.currencyId;
      http
        .patch(`${UPDATE_CLIENT_ACCOUNT}${id}`, {
          name: form.companyName,
          industry_sector: industrySectorId,
          risk_sensitivity_level: riskSensitivityId,
          currency: currencyId,
        })
        .then((response) => {
          handleModalShowHide();
          swal("Success", "It has been updated successfully", "success");
          fetchData();
        })
        .catch((err) => {
          swal("Error ", err, "error");
        });
    }
  };

  const submit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      http
        .post(`${CREATE_CLIENT_ACCOUNTS}`, {
          name: form.companyName,
          currency: form.currency,
          risk_sensitivity_level: form.riskSensitivity,
          industry_sector: form.industrySector,
        })
        .then(
          (response) => {
            handleModalShowHide();
            swal("Success", "It has been added successfully.", "success");
            fetchData();
          },
          (err) => {
            swal("Error", "Something went wrong, please try again.", "error");
          }
        );
    }
  };

  const DisplayData = clients
    ?.slice(pagesVisited, pagesVisited + usersPerPage)
    ?.map((client) => {
      return (
        <>
          <tr key={id}>
            <td data-label="Company Name">{client.name} </td>
            <td data-label="Industry Sector">{client.industry_sector}</td>
            <td className="" data-label="No. of Admin">
              {client.currency}
            </td>
            <td data-label="No. of User">{client.risk_sensitivity_level}</td>

            <td className="flex text-center" data-label="Manage">
              <IconButton
                onClick={() => {
                  console.log(client, "client");
                  setId(client.id);
                  setAction("edit");
                  form.companyName = client.name;
                  form.industrySector = client.industry_sector;
                  form.currency = client.currency;
                  form.riskSensitivity = client.risk_sensitivity_level;
                  handleModalShowHide();
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  setId(client.id);
                  toggleDeleteModal();
                }}
              >
                <DeleteIcon />
              </IconButton>
            </td>
          </tr>
        </>
      );
    });
  return (
    <div className="p-10">
      {deleteModal ? (
        <DeleteModal
          handleDelete={deleteClient}
          handleModal={toggleDeleteModal}
        />
      ) : null}
      <div className="">
        <div className="table-title ">
          <span className="font-bold ">Clients</span>
          <div className="flex justify-center space-x-4 items-center">
            <button className="btn_file_download">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height={28}
                width={28}
                fill-rule="evenodd"
                fill="white"
                clip-rule="evenodd"
                image-rendering="optimizeQuality"
                shape-rendering="geometricPrecision"
                text-rendering="geometricPrecision"
                viewBox="0 0 17639 17639"
              >
                <path
                  fill="#1e7145"
                  d="M9596 3634v10265l-6103-1058V4692l6103-1058zm290 1185h4053c224 0 267 44 267 267v7360c0 223-43 267-267 267H9886v-694h1547v-1066H9886v-320h1547V9566H9886v-320h1547V8179H9886v-320h1547V6793H9886v-320h1547V5406H9886v-587zM2083 15593h13474l2-13476H2081l2 13476z"
                />
                <path
                  fill="#1e7145"
                  d="m7129 6861-556 1272-432-1195-695 31 690 1741-777 1703 683 51 548-1225 510 1296 786 43-852-1875 815-1894zM11753 12019h1760v-1066h-1760zM11753 10633h1760V9566h-1760zM11753 9246h1760V8179h-1760zM11753 6473h1760V5406h-1760zM11753 7859h1760V6793h-1760z"
                />
              </svg>
            </button>
            <div className="btn-add-new">
              <button
                onClick={(e) => {
                  clearAll();
                  setAction("add");
                  handleModalShowHide();
                }}
              >
                <AddIcon className="" />
              </button>
            </div>
          </div>
        </div>

        <table class="tables ">
          <thead className="sticky-header">
            <tr>
              <th scope="col">Company Name</th>
              <th scope="col">Industry Sector</th>
              <th scope="col">Currency</th>
              <th scope="col">Risk Sensitivity</th>
              <th className="w-28 text-center" scope="col">
                Manage
              </th>
            </tr>
          </thead>
          <tbody>{DisplayData}</tbody>
        </table>
        <div className="">
          {clients.length > 5 ? (
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              activeClassName={"paginationActive"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
            />
          ) : null}
        </div>
      </div>

      <Modal show={showHide} className="">
        <Modal.Header
          onClick={() => {
            handleModalShowHide();
          }}
        >
          <Modal.Title>
            {action === "add" ? "Add New" : "Edit"} Client
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-medium">
          <Form.Group class="mb-6">
            <Form.Label
              for="email"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Client Name
            </Form.Label>
            <Form.Control
              type="text"
              id="companyName"
              value={form.companyName}
              onChange={(e) => {
                setField("companyName", e.target.value);
              }}
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              isInvalid={!!errors.companyName}
              required
            ></Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.companyName}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group class="mb-6">
            <Form.Label
              for="password"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Industry Sector
            </Form.Label>
            <Form.Control
              as="select"
              type="text"
              id="password"
              value={form.industrySector}
              onChange={(e) => {
                const selectedId = e.target.value;
                const selectedName = sectors.find(
                  (sector) => sector.id === selectedId
                )?.name;
                setField("industrySector", selectedId);
                setSelectedIds({
                  ...selectedIds,
                  riskSensitivityId: e.target.value,
                });
              }}
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              isInvalid={!!errors.industrySector}
              required
            >
              <option>
                {action === "add"
                  ? sectors.find((sector) => sector.id === form.industrySector)
                      ?.name
                  : form.industrySector}
              </option>
              {sectors
                ?.filter((item) => item.name !== form.industrySector)
                ?.map((x, y) => (
                  <option value={x.id}>{x.name}</option>
                ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.industrySector}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group class="mb-6">
            <Form.Label
              for="password"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Currency
            </Form.Label>
            <Form.Control
              as="select"
              type="text"
              id="currency"
              value={form.currency}
              onChange={(e) => {
                setField("currency", e.target.value);
                setSelectedIds({
                  ...selectedIds,
                  currencyId: e.target.value,
                });
              }}
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              isInvalid={!!errors.currency}
              required
            >
              <option>
                {action === "add"
                  ? currencies.find((sector) => sector.id === form.currency)
                      ?.name
                  : form.currency}
              </option>
              {currencies
                ?.filter((item) => item.name !== form.currency)
                ?.map((x, y) => (
                  <option value={x.id}>{x.name}</option>
                ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.currency}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group class="mb-6">
            <Form.Label
              for="email"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Risk Sensitivity
            </Form.Label>
            <Form.Control
              as="select"
              type="text"
              id="riskSensitivity"
              value={form.riskSensitivity}
              onChange={(e) => {
                setField("riskSensitivity", e.target.value);
                setSelectedIds({
                  ...selectedIds,
                  riskSensitivityId: e.target.value,
                });
              }}
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Risk Sensitivity"
              isInvalid={!!errors.riskSensitivity}
              required
            >
              <option value="">{form.riskSensitivity}</option>
              {level
                ?.filter((item) => item.name !== form.riskSensitivity)
                ?.map((x, y) => (
                  <option value={x.id}>{x.name}</option>
                ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.riskSensitivity}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-cancel"
            onClick={() => {
              setErrors("");
              handleModalShowHide();
            }}
          >
            Cancel
          </Button>
          {action === "add" ? (
            <Button
              className="btn-add-new"
              onClick={(e) => {
                submit(e);
              }}
            >
              Add {form.industrySector}
            </Button>
          ) : (
            <Button
              className="btn-add-new"
              onClick={(e) => {
                updateClient(e);
              }}
            >
              Save Changes
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ManageClients;
