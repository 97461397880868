import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../store/actions';

export function useClearLocalStorageIfSessionExpired() {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleLogout = () => {
      // Dispatch the logout action
      dispatch(logout());
    };

    const checkSessionExpiration = () => {
      const isActive = sessionStorage.getItem('active');

      // Get the user's last login time from localStorage
      const lastLoginTime = localStorage.getItem('lastVisitDateTime');

      // Check if lastVisitDateTime doesn't exist in localStorage
      if (!lastLoginTime) {
        // Clear the "cyber-minds" key in localStorage and handle logout
        handleLogout();
        localStorage.removeItem('cyber-minds');
        localStorage.removeItem('theme');
        localStorage.removeItem('lastVisitDateTime');
        return; // Exit early to prevent further execution
      }
      if (lastLoginTime) {
        // Convert the last login time to a Date object
        const lastLoginDate = new Date(lastLoginTime);

        // Get the current time
        const currentTime = new Date();

        // Calculate the time difference in milliseconds
        const timeDifference = currentTime - lastLoginDate;

        // Define the duration for 31 minutes in milliseconds
        const tenSecondsInMilliseconds = 2860000;

        // Check if the time difference is greater than 31 minutes
        if (timeDifference > tenSecondsInMilliseconds) {
          // Clear the "cyber-minds" key in localStorage
          handleLogout();
          localStorage.removeItem('cyber-minds');
          localStorage.removeItem('theme');
          localStorage.removeItem('lastVisitDateTime');
        }
      }
    };

    // Initially check for session expiration
    checkSessionExpiration();

    // Set up an interval to check every 10 seconds
    const intervalId = setInterval(checkSessionExpiration, 1000);

    // Clean up the interval on unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // You can return additional data or functions if needed
  return {};
}
