import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import ReactPaginate from "react-paginate";
import http from "../../../resources/http";
import { baseURL } from "../../../resources/apiClient";
import swal from "sweetalert";
import ControlsSideNav from "./ControlsSideNav";
import MoreButton from "../../../layout/components/MoreButton";
import { uniqueArray } from "../../../utils/uniqueArrayOfObjects";
import Form from "../../../shared/Form";
import { Modal } from "react-bootstrap";
import { CONTROL_DOMAIN } from "../../../api/configurations";

const ControlCategory = () => {
	const [isAddModalOpen, setAddModalOpen] = useState(false);
	const [isEditModalOpen, setEditModalOpen] = useState(false);
	// data hook
	const [id, setId] = useState();
	const [controlCategory, setControlCategory] = useState([]);
	const [controlDomain, setControlDomain] = useState("");

	//adding model handler
	const [showHideAdd, setShowHideAdd] = useState(false);
	const handleModalShowHideAdd = () => setShowHideAdd(!showHideAdd);
	//editing model handler
	const [showHideEdit, setShowHideEdit] = useState(false);
	const handleModalShowHideEdit = () => setShowHideEdit(!showHideEdit);
	const [controlDomains, setControlDomains] = useState([]);
	const distinctDomains = uniqueArray(controlDomains);

	//page pagination handler
	const usersPerPage = 7;
	const [selected, setSelected] = useState([]);
	const [pageNumber, setPageNumber] = useState(0);
	const pagesVisited = pageNumber * usersPerPage;
	const pageCount = Math.ceil(controlCategory.length / usersPerPage);
	const changePage = ({ selected }) => {
		setPageNumber(selected);
	};

	//for longer descriptions
	const [descriptionText, setDescriptionText] = useState("");
	const [showHideDescModal, setShowHideDescModal] = useState(false);
	const handleDescModalShowHide = () =>
		setShowHideDescModal(!showHideDescModal);

	//form data and error handler
	const [form, setForm] = useState({});
	const [errors, setErrors] = useState({});
	const [categoryNameList, setCategoryNameList] = useState([]);
	const setField = (field, value) => {
		setForm({
			...form,
			[field]: value,
		});
		if (!!errors[field])
			setErrors({
				...errors,
				[field]: null,
			});
	};
	//input validator
	const validateForm = () => {
		const {
			controlCategory = "",
			controlDomain = "",
			controlCategoryDescription = "",
		} = form;
		const newErrors = {};
		if (
			categoryNameList
				.map((name) => name.toLowerCase())
				.includes(form.controlCategory.trim().toLowerCase())
		)
			newErrors.controlCategory = `Control category ${form.controlCategory} already exists,`;
		if (!controlCategory || controlCategory === "")
			newErrors.controlCategory = "Control category is required.";
		return newErrors;
	};

	//input validator
	const validateEditForm = () => {
		const {
			controlCategory = "",
			controlDomain = "",
			controlCategoryDescription = "",
		} = form;
		const newErrors = {};
		if (!controlCategory || controlCategory === "")
			newErrors.controlCategory = "Control category is required.";
		return newErrors;
	};
	//reset form
	const clearAll = () => {
		form.controlCategory = "";
		form.controlCategoryDescription = "";
	};
	const fetchData = () => {
		http
			.get(`${baseURL}/configurations/control-domain`)
			.then((response) => {
				setControlDomains(response.data);
			})
			.then(
				(response) => {},
				(err) => {
					console.log(err);
				}
			);
		http
			.get(`${baseURL}/configurations/control-category`)
			.then((response) => {
				setControlCategory(response.data);
				const categoryName = response.data.map((category) => category.title);
				setCategoryNameList(categoryName);
			})
			.then(
				(response) => {},
				(err) => {
					alert(err);
				}
			);
	};
	useEffect(() => {
		fetchData();
	}, []);
	console.log(distinctDomains, "domains");
	const submit = (e) => {
		e.preventDefault();
		const formErrors = validateForm();
		if (Object.keys(formErrors).length > 0) {
			setErrors(formErrors);
		} else {
			http
				.post(`${baseURL}/configurations/control-category`, {
					title: form.controlCategory,
					control_domain: form.controlDomain,
					description: form.controlCategoryDescription,
				})
				.then(
					(response) => {
						fetchData();
						setAddModalOpen(false);
						swal("Success", "It has been added successfully.", "success");
					},
					(err) => {
						swal("Error", "Something went wrong, please try again.", "error");
					}
				);
		}
	};
	//updating existed db data
	const updateControlCategory = (e) => {
		e.preventDefault();
		const formErrors = validateEditForm();
		if (Object.keys(formErrors).length > 0) {
			setErrors(formErrors);
		} else {
			http
				.patch(`${baseURL}/configurations/control-category/${id}`, {
					title: form.controlCategory,
					control_domain: form.controlDomain,
					description: form.controlCategoryDescription,
				})
				.then(
					(response) => {
						fetchData();
						setEditModalOpen(false);
						swal("Success", "It has been updated successfully.", "success");
					},
					(err) => {
						swal("Error", "Something went wrong, please try again.", "error");
					}
				);
		}
	};
	const deleteControl = (controlCategoryId) => {
		http
			.delete(`${baseURL}/configurations/control-category/${controlCategoryId}`)
			.then(
				(response) => {
					swal("Success", "It has been deleted successfully ", "success");
					fetchData();
				},
				(err) => {
					swal("Error", "Something went wrong, please try again.", "error");
				}
			);
	};
	console.log(controlCategory);
	const DisplayData = controlCategory
		?.slice(pagesVisited, pagesVisited + usersPerPage)
		?.map((controlCategory) => {
			return (
				<>
					<tr key={id}>
						<td data-label="Control Category ">{controlCategory.title} </td>
						<td data-label="Control Category ">
							{controlCategory.control_domain
								? controlCategory.control_domain
								: "N/A"}
						</td>
						<td data-label="description">
							{controlCategory.description ? (
								<div className="w-96">
									<MoreButton
										description={controlCategory.description}
										stringLength={50}
										setText={setDescriptionText}
										modalFunc={handleDescModalShowHide}
									/>
								</div>
							) : (
								"N/A"
							)}
						</td>
						<td className="flex text-center" data-label="Manage">
							<IconButton
								onClick={() => {
									setId(controlCategory.id);
									form.controlCategory = controlCategory.title;
									form.controlCategoryDescription = controlCategory.description;
									form.controlDomain = controlCategory.control_domain;
									setEditModalOpen(true);
								}}
							>
								<EditIcon />
							</IconButton>
							<IconButton
								onClick={() => {
									deleteControl(controlCategory.id);
								}}
							>
								<DeleteIcon />
							</IconButton>
						</td>
					</tr>
				</>
			);
		});
	return (
		<div className="flex">
			{/* <ControlsSideNav />  */}
			<div className="p-16 w-2/3 flex-1">
				<div className="main table-title ">
					<div>
						<span className="font-bold ">Control Category </span>
					</div>
					<div className="flex justify-between items-center space-x-4">
						<button
							onClick={(e) => {
								clearAll();
								setAddModalOpen(true);
							}}
							className="btn-add-new"
						>
							<AddIcon />
						</button>
					</div>
				</div>
				<table className="tables">
					<thead className="">
						<tr>
							<th scope="col">Control Category</th>
							<th scope="col">Control Domain</th>
							<th scope="col">Control Category Description</th>
							<th scope="col">Manage</th>
						</tr>
					</thead>
					<tbody>{DisplayData}</tbody>
				</table>
				{controlCategory.length > 7 ? (
					<ReactPaginate
						previousLabel={"<"}
						nextLabel={">"}
						activeClassName={"paginationActive"}
						pageCount={pageCount}
						onPageChange={changePage}
						containerClassName={"paginationBttns"}
						previousLinkClassName={"previousBttn"}
						nextLinkClassName={"nextBttn"}
						disabledClassName={"paginationDisabled"}
					/>
				) : null}
			</div>

			{/* adding model */}
			<Modal show={isAddModalOpen}>
				<Modal.Header>
					<Modal.Title>Add New Control Category </Modal.Title>
				</Modal.Header>
				<Modal.Body className="modal-body-small">
					<Form.Group>
						<Form.Label for="email">Control Category</Form.Label>
						<Form.Control
							type="text"
							id="email"
							value={form.controlCategory}
							onChange={(e) => setField("controlCategory", e.target.value)}
							placeholder="text..."
							isInvalid={!!errors.controlCategory}
							required
						/>
						<Form.Control.Feedback type="invalid">
							{errors.controlCategory}
						</Form.Control.Feedback>
					</Form.Group>
					<div className="w-full ">
						<select
							class="w-full text-gray-600 bg-gray-50 border  border-gray-300  rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
							value={form.controlDomain}
							onChange={(e) => setField("controlDomain", e.target.value)}
						>
							<option value="">Select Control Domain</option>
							{distinctDomains?.map((x, y) => (
								<option value={x.id}>{x.name}</option>
							))}
						</select>
					</div>
					<Form.Group>
						<Form.Label for="email">Control Category Description</Form.Label>
						<Form.Control
							type="text"
							id="email"
							value={form.controlCategoryDescription}
							onChange={(e) =>
								setField("controlCategoryDescription", e.target.value)
							}
							placeholder="text..."
							isInvalid={!!errors.controlCategoryDescription}
							required
						/>
						<Form.Control.Feedback type="invalid">
							{errors.controlCategoryDescription}
						</Form.Control.Feedback>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<button
						className="btn-cancel"
						onClick={() => {
							setErrors("");
							setAddModalOpen(false);
						}}
					>
						Cancel
					</button>
					<button
						className="btn-add-new"
						onClick={(e) => {
							submit(e);
						}}
					>
						Add
					</button>
				</Modal.Footer>
			</Modal>

			{/* editing model */}
			<Modal show={isEditModalOpen} size="sm">
				<Modal.Header onClick={() => handleModalShowHideEdit()}>
					<Modal.Title>Update Control</Modal.Title>
				</Modal.Header>
				<Modal.Body className="modal-body-small">
					<Form.Group>
						<Form.Label for="email">Control Category</Form.Label>
						<Form.Control
							type="text"
							id="email"
							value={form.controlCategory}
							onChange={(e) => setField("controlCategory", e.target.value)}
							placeholder="text..."
							isInvalid={!!errors.controlCategory}
							required
						></Form.Control>
						<Form.Control.Feedback type="invalid">
							{errors.controlCategory}
						</Form.Control.Feedback>
					</Form.Group>
					<div className="w-full ">
						<select
							class="w-full text-gray-600 bg-gray-50 border  border-gray-300  rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
							value={form.controlDomain}
							onChange={(e) => setField("controlDomain", e.target.value)}
						>
							<option value="">Select Control Domain</option>
							{distinctDomains?.map((x, y) => (
								<option value={x.id}>{x.name}</option>
							))}
						</select>
					</div>
					<Form.Group>
						<Form.Label for="email">Control Description</Form.Label>
						<Form.Control
							type="text"
							id="email"
							value={form.controlCategoryDescription}
							onChange={(e) =>
								setField("controlCategoryDescription", e.target.value)
							}
							placeholder="text..."
							isInvalid={!!errors.controlCategoryDescription}
							required
						></Form.Control>
						<Form.Control.Feedback type="invalid">
							{errors.controlCategoryDescription}
						</Form.Control.Feedback>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<button
						className="btn-cancel"
						onClick={() => {
							setErrors("");
							setEditModalOpen(false);
						}}
					>
						Cancel
					</button>
					<button
						className="btn-add-new"
						onClick={(e) => updateControlCategory(e)}
					>
						Save Changes
					</button>
				</Modal.Footer>
			</Modal>

			{/* for showing description modal */}
			<Modal
				isOpen={showHideDescModal}
				onClose={() => showHideDescModal(false)}
				size="sm"
			>
				<Modal.Header onClick={() => handleDescModalShowHide()}>
					<Modal.Title>Control Category Description</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<span>{descriptionText}</span>
				</Modal.Body>
				<Modal.Footer>
					<button
						className="btn-add-new"
						onClick={() => handleDescModalShowHide()}
					>
						Close
					</button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default ControlCategory;
