import React, { useEffect, useState } from "react";
import http from "../../../resources/http";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import { Button, Form, Modal } from "react-bootstrap";
import { baseURL } from "../../../resources/apiClient";
import swal from "sweetalert";
const RiskSensetivity = () => {
	const [risk, setRisk] = useState();
	const [deadline, setDeadline] = useState(0);
	const [show, setShow] = useState(false);
	const toggleSeverityDiv = () => setShow(!show);
	const [level, setLevel] = useState();
	const [min_value, setMinValue] = useState();
	const [max_value, setMaxValue] = useState();
	const [severity, setSeverity] = useState();
	const [name, setName] = useState();
	const [levels, setLevels] = useState([]);
	const [data, setData] = useState([]);
	const [showHideMe, setSetShowHideMe] = useState(false);
	const [showHideDelete, setSetShowHideDelete] = useState(false);
	const handleModalShowHideMe = () => setSetShowHideMe(!showHideMe);
	const [id, setId] = useState();

	const submit = (e) => {
		e.preventDefault();
		http
			.post(`${baseURL}/risk/risk-sensitivities/`, {
				level: name,
				min_value: min_value,
				max_value: max_value,
				severity: severity,
			})
			.then(
				(response) => {
					swal("Success", "Risk Sensitivity Added Successfully", "success");
				},
				(err) => {
					swal("failed");
				}
			);
	};
	const submitLevels = (e) => {
		e.preventDefault();
		http
			.post(`${baseURL}/risk/risk-sensitivity-levels/`, {
				name: level,
				description: "DESCRIPTION",
			})
			.then(
				(response) => {
					swal(
						"Success",
						"Risk Sensitivity Level Added Successfully",
						"success"
					);
				},
				(err) => {
					swal("failed");
				}
			);
	};
	const fetchData = () => {
		http
			.get(`${baseURL}/risk/risk-sensitivities`)
			.then((response) => {
				setData(response.data);
			})
			.then(
				(response) => {},
				(err) => {
					console.log(err);
				}
			);
		http
			.get(`${baseURL}/risk/risk-sensitivity-levels`)
			.then((response) => {
				setLevels(response.data);
			})
			.then(
				(response) => {},
				(err) => {
					console.log(err);
				}
			);
	};
	useEffect(() => {
		fetchData();
	}, []);
	const DisplayDataLow = data?.Low?.map((items) => {
		return (
			<>
				<tr key={items.id}>
					<td>{items?.min_value}</td>
					<td>{items?.max_value}</td>
					<td>{items?.severity}</td>
					<td>
						<IconButton
							onClick={() => {
								setId(items.id);
								setMinValue(items?.min_value);
								setMaxValue(items?.max_value);
								handleModalShowHideMe();
							}}
						>
							<EditIcon />
						</IconButton>
					</td>
				</tr>
			</>
		);
	});
	const DisplayDataMedium = data?.Medium?.map((items) => {
		return (
			<>
				<tr key={items.id}>
					<td>{items.min_value}</td>
					<td>{items.max_value}</td>
					<td>{items.severity}</td>
					<td>
						<IconButton
							onClick={() => {
								setId(items.id);
								setMinValue(items?.min_value);
								setMaxValue(items?.max_value);
								handleModalShowHideMe();
							}}
						>
							<EditIcon />
						</IconButton>
					</td>
				</tr>
			</>
		);
	});
	const DisplayDataHigh = data?.High?.map((items) => {
		return (
			<>
				<tr key={items.id}>
					<td>{items.min_value}</td>
					<td>{items.max_value}</td>
					<td>{items.severity}</td>
					<td>
						<IconButton
							onClick={() => {
								setId(items.id);
								setMinValue(items?.min_value);
								setMaxValue(items?.max_value);
								handleModalShowHideMe();
							}}
						>
							<EditIcon />
						</IconButton>
					</td>
				</tr>
			</>
		);
	});
	return (
		<>
			<div className=" flex justify-center items-center mt-24 space-x-16">
				{show && (
					<div className=" flex justify-end items-end  mb-8">
						<div className="container-modal flex flex-col items-center justify-center space-y-6 ">
							<div class="relative inline-block text-left">
								<div>
									<label
										for="countries"
										class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
									>
										Select an option
									</label>
									<select
										value={name}
										onChange={(e) => {
											setName(e.target.value);
										}}
										id="countries"
										class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
									>
										<option>Select Sensetivity Level</option>
										{levels?.map((x, y) => (
											<option value={x.id}>{x.name}</option>
										))}
									</select>
								</div>
							</div>
							<div class="mb-6">
								<label
									for="password"
									class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
								>
									Severity
								</label>
								<input
									type="text"
									id="password"
									value={severity}
									onChange={(e) => setSeverity(e.target.value)}
									class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
									placeholder="Severity Type"
									required
								/>
							</div>
							<div class="mb-6">
								<label
									for="password"
									class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
								>
									Minimum
								</label>
								<input
									type="text"
									id="password"
									value={min_value}
									onChange={(e) => setMinValue(e.target.value)}
									class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
									placeholder="Minimum Value"
									required
								/>
							</div>
							<div class="mb-6">
								<label
									for="password"
									class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
								>
									Maximum
								</label>
								<input
									type="text"
									id="password"
									value={max_value}
									onChange={(e) => setMaxValue(e.target.value)}
									class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
									placeholder="Maximum Value"
									required
								/>
							</div>

							<div>
								<button
									onClick={(e) => {
										submit(e);
										fetchData();
									}}
									type="button"
									className="text-white bg-blue-700   font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 "
								>
									Save
								</button>
							</div>
						</div>
					</div>
				)}
			</div>

			<div className="p-10 grid place-items-center  sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3  gap-4">
				<div className="h-full grid grid-flow-rows auto-rows-min place-items-center border rounded-lg  border-gray-200 shadow overflow-y-auto h-[45px] w-11/12">
					<div className="font-semibold text-lg flex justify-center items-center pl-4  bg-gray-600 text-white p-2 border border-black h-full w-full">
						Low
					</div>
					<div className="w-full p-3">
						<div className="rounded flex justify-center items-center">
							<table className="tables">
								<thead className="text-white">
									<tr>
										<th scope="col">Min Value</th>
										<th scope="col">Max Value</th>
										<th scope="col">Severity</th>
										<th scope="col">Manage</th>
									</tr>
								</thead>
								<tbody>{DisplayDataLow}</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className="grid grid-flow-rows auto-rows-min place-items-center border rounded-lg  border-gray-200 shadow overflow-y-auto h-45vh w-11/12">
					<div className="font-semibold text-lg flex justify-center items-center pl-4  bg-gray-600 text-white p-2 border border-black h-full w-full">
						Medium
					</div>
					<div className="w-full p-3">
						<div className="rounded flex justify-center items-center">
							<table className="tables text-xs">
								<thead className="text-white">
									<tr>
										<th scope="col">Min Value</th>
										<th scope="col">Max Value</th>
										<th scope="col">Severity</th>
										<th scope="col">Manage</th>
									</tr>
								</thead>
								<tbody>{DisplayDataMedium}</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className="grid grid-flow-rows auto-rows-min place-items-center border rounded-lg  border-gray-200 shadow overflow-y-auto h-45vh w-11/12">
					<div className="font-semibold text-lg flex justify-center items-center pl-4  bg-gray-600 text-white p-2 border border-black h-full w-full">
						High
					</div>
					<div className="w-full p-3">
						<div className="rounded flex justify-center items-center">
							<table className="tables text-xs">
								<thead className="text-white">
									<tr>
										<th scope="col">Min Value</th>
										<th scope="col">Max Value</th>
										<th scope="col">Severity</th>
										<th scope="col">Manage</th>
									</tr>
								</thead>
								<tbody>{DisplayDataHigh}</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>

			<Modal show={showHideMe}>
				<Modal.Header onClick={() => handleModalShowHideMe()}>
					<Modal.Title>Update Rick Sensetivity</Modal.Title>
				</Modal.Header>
				<Modal.Body className="modal-body-small">
					<Form.Group class="mb-6">
						<Form.Label
							for="email"
							class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
						>
							Minimum Value
						</Form.Label>
						<Form.Control
							type="text"
							id="email"
							value={min_value}
							onChange={(e) => setMinValue(e.target.value)}
							class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
							placeholder="Enter Minimum Value"
							required
						></Form.Control>
					</Form.Group>
					<Form.Group class="mb-6">
						<Form.Label
							for="password"
							class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
						>
							Maximum Value
						</Form.Label>
						<Form.Control
							type="text"
							id="password"
							value={max_value}
							onChange={(e) => setMaxValue(e.target.value)}
							class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
							placeholder="Enter Maximum Value"
							required
						></Form.Control>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button
						className="btn-cancel"
						onClick={() => handleModalShowHideMe()}
					>
						Close
					</Button>
					<Button
						className="btn-add-new"
						onClick={() => {
							http
								.patch(`${baseURL}/risk/risk-sensitivity/${id}/`, {
									min_value: min_value,
									max_value: max_value,
								})
								.then((res) => {
									swal(
										"success",
										"Risk sensitivity record updated successfully"
									);
									fetchData();
									handleModalShowHideMe();
								})
								.catch((err) => {
									swal("no success");
								});
						}}
					>
						Save Changes
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default RiskSensetivity;
